import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import './index.css'
import axios from "axios"
import {toast} from 'react-toastify'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import convertnumber from '../../helpers/convertnumber'

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        marginTop: '5px'
    }),
}

function Ringkasan() {

    const [dataTransaction, setDataTransaction] = useState([])
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [marketplace, setMarketplace] = useState([]);

    const onChangeDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start && end) {
            setShowDatePicker(false)
            fetchTransactionSummary(start, end)
        } 
    };
    useEffect(()=> {
        fetchTransactionSummary()
        fetchMarketplace()
    },[])

    function fetchTransactionSummary(startDate, endDate) {
        axios.get(`/transaction/summary?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}&marketplaceId=all`)
        .then(({data}) => {
            setDataTransaction(data)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }

    function fetchMarketplace(startDate, endDate) {
        axios.get(`/marketplace`)
        .then(({data}) => {
            let options = [{
                label: 'ALL',
                value: 'all'
            }]
                data.map(el => {
                    options.push({
                        label: el.name,
                        value: el._id
                    })
                })
                setMarketplace(options)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }
    
    function changeMarketplace(value) {
        axios.get(`/transaction/summary?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}&marketplaceId=${value.value}`)
        .then(({data}) => {
            setDataTransaction(data)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }
    
    return (
        <div className="d-flex flex-col w-100pc">
            <div className="header--summary">
                <div className="header--summary__date">
                    <span className="text-grey fw-400">Tanggal</span>
                    <div className='button--date mt-5' onClick={()=> setShowDatePicker(!showDatePicker)}>
                        {`${moment(startDate).format('L')} - ${moment(endDate).format('L')}`}
                    </div>
                    {
                        showDatePicker &&
                            <div className="pos-absolute">
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChangeDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                    }
                </div>
                <div className="header--summary__source">
                    <span className="text-grey fw-400">Source</span>
                    <Select 
                        styles={customStyles}
                        options={marketplace}
                        placeholder="All"
                        onChange={changeMarketplace}
                    />
                </div>
            </div>
            <div className="body--summary mt-20">
                <div className="wrapper-text wrapper-text__white">
                    <span>Penjualan Kotor</span>
                    <span>Rp{convertnumber(dataTransaction.grossSales? dataTransaction.grossSales: 0)}</span>
                </div>
                <div className="wrapper-text wrapper-text__grey">
                    <span>Diskon Bundle</span>
                    <span>Rp{convertnumber(dataTransaction.bundleDiscount? dataTransaction.bundleDiscount: 0)}</span>
                </div>
                <div className="wrapper-text wrapper-text__white">
                    <span>Pembatalan</span>
                    <span>Rp{convertnumber(dataTransaction.cancellation? dataTransaction.cancellation: 0)}</span>
                </div>
                <div className="wrapper-text wrapper-text__grey">
                    <span>Total Penjualan Bersih</span>
                    <span style={{background: '#def3ff'}}>Rp{convertnumber(dataTransaction.totalNetSales? dataTransaction.totalNetSales: 0)}</span>
                </div>
            </div>
        </div>
    )
}

export default Ringkasan;