import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import './index.css'
import axios from "axios"
import {toast} from 'react-toastify'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import convertnumber from '../../helpers/convertnumber'
import Skeleton from "react-loading-skeleton";

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        marginTop: '5px'
    }),
}
function DailySales() {

    const [dataTransaction, setDataTransaction] = useState([])
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [marketplace, setMarketplace] = useState([]);
    const [loadingFetchDataTransaction, setLoadingFetchDataTransaction] = useState(false)

    useEffect(()=> {
        fetchSalesByProduct()
        fetchMarketplace()
    },[])

    const onChangeDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start && end) {
            setShowDatePicker(false)
            fetchSalesByProduct(start, end)
        } 
    };
    
    function fetchMarketplace(startDate, endDate) {
        axios.get(`/marketplace`)
        .then(({data}) => {
            let options = [{
                label: 'ALL',
                value: 'all'
            }]
                data.map(el => {
                    options.push({
                        label: el.name,
                        value: el._id
                    })
                })
                setMarketplace(options)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }

    function fetchSalesByProduct(startDate, endDate) {
        setLoadingFetchDataTransaction(true)
        axios.get(`/transaction/dailySales?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}&marketplaceId=all`)
        .then(({data}) => {
            setLoadingFetchDataTransaction(false)
            setDataTransaction(data)
        })
        .catch(err => {
            setLoadingFetchDataTransaction(false)
            toast.error('Fetch data sales transaction failed')
        })
    }

    function grandTotal(key){
        let total = 0
        if(Object.keys(dataTransaction).length == 0) {
            return total
        }
        if(key == 'average') {
            let totalTransaction = 0
            let totalSales = 0
            Object.keys(dataTransaction).forEach(transaction => {
                totalTransaction += dataTransaction[transaction].transactionTotal
                totalSales += dataTransaction[transaction].sales
            })
            total = totalSales / totalTransaction
            return total.toFixed()
        }else {
            Object.keys(dataTransaction).forEach(transaction => {
                total += dataTransaction[transaction][key]
            })
            return total
        }
    }

    function changeMarketplace(value) {
        axios.get(`/transaction/dailySales?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}&marketplaceId=${value.value}`)
        .then(({data}) => {
            setDataTransaction(data)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }
    
    return (
        <div className="d-flex flex-col w-100pc">
            <div className="header--summary">
                <div className="header--summary__date">
                    <span className="text-grey fw-400">Tanggal</span>
                    <div className='button--date mt-5' onClick={()=> setShowDatePicker(!showDatePicker)}>
                        {`${moment(startDate).format('L')} - ${moment(endDate).format('L')}`}
                    </div>
                    {
                        showDatePicker &&
                            <div className="pos-absolute">
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChangeDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                    }
                </div>
                <div className="header--summary__source">
                    <span className="text-grey fw-400">Source</span>
                    <Select 
                        styles={customStyles}
                        options={marketplace}
                        placeholder="All"
                        onChange={changeMarketplace}
                    />
                </div>
            </div>
            <div className="body--summary mt-20">
                <div className="table-sales-transaction">
                    <div className="table-sales-transaction-thead">
                        <div className="table-sales-transaction-tr wrapper-text__grey">
                            <div className="table-sales-transaction-td flex-1">
                                Tanggal
                            </div>
                            <div className="table-sales-transaction-td flex-1">
                                Jumlah Transaksi
                            </div>
                            <div className="table-sales-transaction-td flex-1">
                                Penjualan
                            </div>
                            <div className="table-sales-transaction-td flex-1">
                                Rata-rata
                            </div>
                        </div>
                    </div>
                    <div className="table-sales-transaction-tbody">
                        {
                            loadingFetchDataTransaction ? 
                                <Skeleton count={5} height={38} />
                            :
                                Object.keys(dataTransaction).map((transaction, index) => {
                                    return (
                                        <div key={transaction._id} 
                                            className={`table-sales-transaction-tr ${index%2 == 1 ? 'wrapper-text__grey': 'wrapper-text__white'}`}
                                        >
                                            <div className="table-sales-transaction-td flex-1">
                                                {transaction}
                                            </div>
                                            <div className="table-sales-transaction-td flex-1">
                                                {convertnumber(dataTransaction[transaction].transactionTotal)}
                                            </div>
                                            <div className="table-sales-transaction-td flex-1">
                                                Rp{convertnumber(dataTransaction[transaction].sales)}
                                            </div>
                                            <div className="table-sales-transaction-td flex-1">
                                                Rp{convertnumber(dataTransaction[transaction].average.toFixed())}
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                    <div className="table-sales-transaction-tfoot">
                            <div className="table-sales-transaction-tr">
                                <div className="table-sales-transaction-td flex-1">
                                    <span>Grand Total</span>
                                </div>
                                <div className="table-sales-transaction-td flex-1">
                                    {
                                        loadingFetchDataTransaction ? 
                                            <span>0</span>
                                        :
                                            <span>{convertnumber(grandTotal('transactionTotal'))}</span>
                                    }
                                </div>
                                <div className="table-sales-transaction-td flex-1">
                                    {
                                        loadingFetchDataTransaction ? 
                                            <span>Rp0</span>
                                        :
                                            <span>Rp{convertnumber(grandTotal('sales'))}</span>
                                    }
                                </div>
                                <div className="table-sales-transaction-td flex-1">
                                    {
                                        loadingFetchDataTransaction ? 
                                            <span>Rp0</span>
                                        :
                                            <span>Rp{convertnumber(grandTotal('average'))}</span>
                                    }
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default DailySales;