import React, {useState, useRef, useEffect}from 'react';
import Select from 'react-select';
import styled from 'styled-components'
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import axios from 'axios'
import convertnumber from '../../helpers/convertnumber'
import getTotalPrice from '../../helpers/getTotalPrice'
import generateSku from '../../helpers/generateSku'
import moment from 'moment-timezone'
import Modal  from 'react-modal';
import Popup from '../../components/Notification/Popup';

const customStyles = {
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: '200px',
    }),
}

const InputDiscount = styled.input`
    background-color: ${({value}) => value > 0 ? '#4cff12' : 'white'}
`

function SideBar({cart, setCart, bundle, setBundle, isLoading, items, setItems, optionsMarketplace, fetchProductList}) {
    
    const userId = useSelector(state => state.userId);
    const userRole = useSelector(state => state.role);
    const username = useSelector(state => state.name);
    const [loadingSubmitTransaction, setLoadingSubmitTransaction] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [verificationCloseSidebar, setVerificationCloseSidebar] = useState(false)
    const [showDiscountInput, setShowDiscountInput] = useState([])
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        
        setTransaction({
            cart: [],
            user: {
                _id: userId,
                username,
                userRole
            },
            note: '',
            status: 'online',
            totalPrice: 0,
        })
    },[])

    function handleSubmit() {
        setLoadingSubmitTransaction(true)
        let today = new Date()
        let getIndoDate = moment.tz(today, "Asia/Jakarta");
        let dateIndo = getIndoDate.format()
        let validation = true
        transaction.cart = cart.map(({ quantity, discount, ...product}) => { 
            if(quantity <= 0 ) {
                validation = false
            }
            return {
                product,
                discount,
                quantity
            }
        })
        
        if(bundle) {
            transaction.bundle = bundle.map(({ quantity, discount, ...product}) => { 
                if(quantity <= 0 ) {
                    validation = false
                }
                return {
                    product,
                    discount,
                    quantity
                }
            })
        }
        if(!validation) {
            toast.error('Minimal quantity is 1')
            setLoadingSubmitTransaction(false)
            return;
        } 
        if(!transaction.soldFrom) {
            toast.error('Please select sold from before submit')
            setLoadingSubmitTransaction(false)
            return;
        } 

        transaction.totalPrice = getTotalPrice(cart)
        transaction.created_at = dateIndo
        axios.post('/transaction', transaction)
        .then(({data}) => {
            fetchProductList()
            toast.success('Success')
            setLoadingSubmitTransaction(false)
            setCart(null)
            setBundle(null)
        })
        .catch(err => {
            setLoadingSubmitTransaction(false)
            setCart(null)
            setBundle(null)
            toast.error('Product is out of stock. Please refresh the data')})
    }


    function handleRemoveProduct(productId, isBundle = false) {
        if(cart.length === 1 && !isBundle) {
            setCart(null)
            setBundle(null)
        }else {
            if(isBundle) {
                setBundle(bundle.filter(product => product._id != productId))
            }else {
                setCart(cart.filter(product => product._id != productId))
            }
        }
    }

    function handleChangeQuantity(value, productId, isBundle = false) {
        if(isBundle) {
            setBundle(bundle.map(product => {
                if(product._id == productId){
                    return {
                        ...product,
                        quantity: value,
                    }
                }else {
                    return product
                }
            }))
        }else {
            setCart(cart.map(product => {
                if(product._id == productId){
                    return {
                        ...product,
                        quantity: value,
                        subTotalPrice: value > 0 ? value * product.price : 0,
                        subTotalPriceAfterDiscount: value > 0 ? product.discount ? (value * product.price) - (product.discount/100 * (value * product.price))  : null : null
                    }
                }else {
                    return product
                }
            }))
        }
    }

    function handleChangeDiscount(value, productId) {
        setCart(cart.map(product => {
            if(product._id == productId){
                return {
                    ...product,
                    discount: value,
                    subTotalPriceAfterDiscount: value > 0 ? product.quantity > 0 ? product.subTotalPrice - (value/100 * product.subTotalPrice) : null : null
                }
            }else {
                return product
            }
        }))
    }

    function handleClickCloseSideBar() {
        setVerificationCloseSidebar(true)
    }

    function handleCloseSideBarAfterVerification() {
        setVerificationCloseSidebar(false)
        setCart(null)
        setBundle(null)
    }

    function handleChangeSoldFrom(value) {
        setTransaction({
            ...transaction,
            soldFrom: {
                _id: value.value,
                name: value.label 
                }
        })
    }

    function handleRemoveDiscountInput(productId) {
        setShowDiscountInput(showDiscountInput.filter(el => el != productId))
        setCart(cart.map(({ discount, subTotalPriceAfterDiscount, ...product}) => {
            if(product._id == productId){
                return product
            }else {
                return {
                    ...product,
                    discount,
                    subTotalPriceAfterDiscount
                }
            }
        }))
    }
    return ( 
        <div className="side-bar">
            {loadingSubmitTransaction && <Popup text={'Submit Transaction'}/>}
            <i onClick={handleClickCloseSideBar} className="fa fa-times-circle"></i>
            <span className="item-detail m-0">Sold From:</span>
            <Select
                onChange={handleChangeSoldFrom}
                styles={customStyles}
                options={optionsMarketplace}
            />
            <div className="wrapper-list-product">
                {
                    cart.map((product) => {
                        return (
                            <div key={product._id} className="wrapper-item-detail">
                                <i onClick={()=>handleRemoveProduct(product._id)} className="fa fa-minus-square"></i>
                                <span className="item-detail__size"> {generateSku(product)} </span>
                                <b className="mt-5">{product.name}</b>
                                <span className="item-detail item-price">Price: Rp. {convertnumber(product.price)}</span>
                                <span className="item-detail ">
                                    Quantity: 
                                    <input 
                                        autoFocus 
                                        className="input-quantity" 
                                        type="number" 
                                        min="1" 
                                        value={product.quantity} 
                                        onChange={(e) => {
                                            if(e.target.value > product.stock) {
                                                toast.warn(`Stock product only ${product.stock}`)
                                                return;
                                            }
                                            handleChangeQuantity(e.target.value, product._id)
                                        }}
                                    />
                                    {
                                        showDiscountInput.find(el => el === product._id) ? 
                                            <button className="button-discount" onClick={() => handleRemoveDiscountInput(product._id)}>remove discount</button>
                                        :
                                            <button className="button-discount" onClick={() => setShowDiscountInput([...showDiscountInput, product._id])}>+ discount</button>
                                    }
                                </span>
                                {
                                    showDiscountInput.find(el => el === product._id) && 
                                    <span className="item-detail ">
                                        Discount: 
                                        <InputDiscount value={product.discount} className="input-discount" type="number" min="0" placeholder="0" onChange={(e) => handleChangeDiscount(e.target.value, product._id)}/> %
                                    </span>
                                }
                                <span className="item-detail ">
                                    Subtotal Price:
                                    {
                                        product.subTotalPriceAfterDiscount ? 
                                            <s>
                                                <b> Rp. {convertnumber(product.subTotalPrice)}</b>
                                            </s>
                                        : 
                                            <b> Rp. {convertnumber(product.subTotalPrice)}</b>
                                    }
                                    {
                                        product.subTotalPriceAfterDiscount && 
                                            <b> Rp. {convertnumber(product.subTotalPriceAfterDiscount)}</b>
                                    }

                                </span>
                            </div>
                        )
                    })
                }
                {
                    bundle && bundle.map(product => {
                        return (
                            <div key={product._id} className="wrapper-item-detail__bundle">
                                <i onClick={()=>handleRemoveProduct(product._id, true)} className="fa fa-minus-square"></i>
                                <span className="item-detail__size"> {product.size.label} </span>
                                <b className="mt-5">{product.name}</b>
                                <span className="item-detail ">
                                    Quantity: 
                                    <input autoFocus className="input-quantity" type="number" min="1" value={product.quantity} onChange={(e) => handleChangeQuantity(e.target.value, product._id, true)}/>
                                </span>
                            </div>
                        )
                    })
                }
                {
                    isLoading && 
                        <div class="loadingio-spinner-spinner-nmqu9jrtsd"><div class="ldio-j6ozdin8wa9">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </div></div>
                }
            </div>
            <b className="item-detail mt-10">Total Price: Rp. {convertnumber(getTotalPrice(cart))} </b>
            <textarea rows='2' placeholder="write a note" onChange={(e)=> setTransaction({...transaction, note: e.target.value})}/>
            <div className="wrapper-button-submit">
                <button
                    disabled={loadingSubmitTransaction}
                    className={`button button-primary ${loadingSubmitTransaction && 'button-primary-disabled'}`}
                    style={{flex: 5}}
                    onClick={handleSubmit}
                >SUBMIT</button>
            </div>

            {/* verification close side bar */}
            {
                verificationCloseSidebar && 
                    <div className="wrapper-verification">
                        <div className="wrapper-verification__content">
                            <span>All cart data will be deleted, are you sure? </span>
                            <div className="wrapper-verification__text">
                                <b className="button button-no" onClick={() => setVerificationCloseSidebar(false)}>No</b>
                                <b className="button button-yes" onClick={handleCloseSideBarAfterVerification}>Yes</b>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default SideBar;