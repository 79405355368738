import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import './index.css'
import axios from "axios"
import {toast} from 'react-toastify'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import convertnumber from '../../helpers/convertnumber'
import Skeleton from "react-loading-skeleton";
import Modal  from 'react-modal';
import Popup from '../../components/Notification/Popup';

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        marginTop: '5px'
    }),
}

function SalesTransaction({dataSetExcel, setDataSetExcel}) {

    const [dataTransaction, setDataTransaction] = useState([])
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [marketplace, setMarketplace] = useState([]);
    const [loadingFetchSalesTransaction, setLoadingFetchSalesTransaction] = useState(false)
    const [modalDetailTransaction, setModalDetailTransaction] = useState({
        isOpenModal: false, 
        data: null
    })
    const [showVerifDelete, setShowVerifDelete] = useState(false)
    const [loadingDeleteDetailTransaction, setLoadingDeleteDetailTransaction] = useState(false)

    useEffect(()=> {
        fetchSalesTransaction()
        fetchMarketplace()
    },[])

    const onChangeDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start && end) {
            setShowDatePicker(false)
            fetchSalesTransaction(start, end)
        } 
    };
    
    function fetchMarketplace(startDate, endDate) {
        axios.get(`/marketplace`)
        .then(({data}) => {
            let options = [{
                label: 'ALL',
                value: 'all'
            }]
                data.map(el => {
                    options.push({
                        label: el.name,
                        value: el._id
                    })
                })
                setMarketplace(options)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }

    function fetchSalesTransaction(startDate, endDate) {
        setDataSetExcel(null)
        setLoadingFetchSalesTransaction(true)
        axios.get(`/transaction/salesTransaction?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}&marketplaceId=all`)
        .then(({data}) => {
            setLoadingFetchSalesTransaction(false)
            setDataTransaction(data)
            let promise = new Promise((res, rej) => {
                generateExcelData(data)
            })
        })
        .catch(err => {
            setLoadingFetchSalesTransaction(false)
            toast.error('Fetch data sales transaction failed')
        })
    }

    async function generateExcelData(data) {
        let listData = []
        data.map(transaction => {
            transaction.cart.map(cart =>{
                listData.push({
                    date: moment(transaction.created_at).format('D-MM-YYYY, h:mm:ss a'),
                    productName: cart.product.name,
                    productCategory: cart.product.category.label,
                    productColor: cart.product.color.label,
                    productSize: cart.product.size.label,
                    productPrice: cart.product.price,
                    soldQuantity: cart.quantity,
                    subTotalPrice: cart.product.subTotalPrice,
                    discount: cart.discount || 0,
                    afterDiscount: cart.product.subTotalPriceAfterDiscount || 0,
                    soldFrom: transaction.soldFrom.name
                })
            })
            transaction.bundle.map(bundle =>{
                listData.push({
                    date: moment(transaction.created_at).format('D-MM-YYYY, h:mm:ss a'),
                    productName: bundle.product.name,
                    productCategory: bundle.product.category.label,
                    productColor: bundle.product.color.label,
                    productSize: bundle.product.size.label,
                    productPrice: bundle.product.price,
                    soldQuantity: bundle.quantity,
                    subTotalPrice: bundle.product.subTotalPrice,
                    discount: 'bundling',
                    afterDiscount: 0,
                    soldFrom: transaction.soldFrom.name
                })
            })
        })
        setDataSetExcel(listData)
    }

    function grandTotal(){
        let total = 0
        dataTransaction.forEach(transaction => {
            total += transaction.totalPrice
        })
        return total
    }

    function changeMarketplace(value) {
        axios.get(`/transaction/salesTransaction?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}&marketplaceId=${value.value}`)
        .then(({data}) => {
            setDataTransaction(data)
        })
        .catch(err => toast.error('Fetch data transaction summary failed'))
    }
    function handleClickOpenDetail(transaction) {
        setModalDetailTransaction({
            isOpenModal: true,
            data: transaction
        })
    }
    function handleCloseModal(){
        setModalDetailTransaction({
            isOpenModal: false,
            data: null
        })

    }
    function handleClickDelete() {
        setShowVerifDelete(true)
    }
    function handleSubmitDeleteTransaction() {
        setLoadingDeleteDetailTransaction(true)
        axios.delete(`/transaction/deleteOne/${modalDetailTransaction.data._id}`)
        .then(() => {
            toast.success('Delete Transaction Success')
            setDataTransaction(dataTransaction.filter(transaction => transaction._id !== modalDetailTransaction.data._id ))
            setModalDetailTransaction({
                isOpenModal: false,
                data: null
            })
            setLoadingDeleteDetailTransaction(false)
            setShowVerifDelete(false)
        })
        .catch(err => {
            toast.error('Delete Transaction Failed')
            setShowVerifDelete(false)
            setLoadingDeleteDetailTransaction(false)
        })
    }
    
    return (
        <div className="d-flex flex-col w-100pc">
            
            <div className="header--summary">
                <div className="header--summary__date">
                    <span className="text-grey fw-400">Tanggal</span>
                    <div className='button--date mt-5' onClick={()=> setShowDatePicker(!showDatePicker)}>
                        {`${moment(startDate).format('L')} - ${moment(endDate).format('L')}`}
                    </div>
                    {
                        showDatePicker &&
                            <div className="pos-absolute">
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChangeDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                    }
                </div>
                <div className="header--summary__source">
                    <span className="text-grey fw-400">Source</span>
                    <Select 
                        styles={customStyles}
                        options={marketplace}
                        placeholder="All"
                        onChange={changeMarketplace}
                    />
                </div>
            </div>
            <div className="body--summary mt-20">
                <div className="table-sales-transaction">
                    <div className="table-sales-transaction-thead">
                        <div className="table-sales-transaction-tr wrapper-text__grey">
                            <div className="table-sales-transaction-td flex-2">
                                Waktu
                            </div>
                            <div className="table-sales-transaction-td flex-4">
                                Produk
                            </div>
                            <div className="table-sales-transaction-td flex-1">
                                Total
                            </div>
                        </div>
                    </div>
                    <div className="table-sales-transaction-tbody">
                        {
                            loadingFetchSalesTransaction ? 
                                <>
                                    <Skeleton count={5} height={50} />
                                </>
                            :
                            dataTransaction.map((transaction, index) => {
                                return (
                                    <div key={transaction._id}
                                        onClick={()=>handleClickOpenDetail(transaction)} 
                                        className={`table-sales-transaction-tr ${index%2 == 1 ? 'wrapper-text__grey': 'wrapper-text__white'}`}
                                    >
                                        <div className="table-sales-transaction-td flex-2">
                                            {moment(transaction.created_at).format('llll')}
                                        </div>
                                        <div className="table-sales-transaction-td flex-4">
                                            {
                                                transaction.cart.map((cart, iCart) => {
                                                    return (
                                                    <>
                                                        {
                                                            iCart <= 2 ? 
                                                                <span key={cart._id} className="table-sales-transaction--product-name">
                                                                    {cart.product.name ? cart.product.name: 'Deleted Product'} x {cart.quantity}
                                                                </span>
                                                            :
                                                                <span key={cart._id} className="table-sales-transaction--product-name">
                                                                    ...
                                                                </span>
                                                        }
                                                    </>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="table-sales-transaction-td flex-1">
                                            Rp{convertnumber(transaction.totalPrice)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="table-sales-transaction-tfoot">
                            <div className="table-sales-transaction-tr">
                                <div className="table-sales-transaction-td flex-6">
                                    <span>Grand Total</span>
                                </div>
                                    {
                                        loadingFetchSalesTransaction ? 
                                            <div className="table-sales-transaction-td__skeleton flex-1">
                                                <Skeleton height={20}/>
                                            </div>
                                        :
                                            <div className="table-sales-transaction-td flex-1">
                                                <span>Rp{convertnumber(grandTotal())}</span>
                                            </div>
                                    }
                            </div>
                        </div>
                </div>
            </div>
            {
                modalDetailTransaction.isOpenModal && modalDetailTransaction.data && (
                    <Modal
                        isOpen={modalDetailTransaction.isOpenModal}
                        onRequestClose={handleCloseModal}
                        className={"modal-detail-transaction"}
                    >
                        {loadingDeleteDetailTransaction && <Popup text={'Delete Transaction'}/>}
                        <div className="wrapper-title-header">
                            <h4 className="title-header">DATA TRANSAKSI PENJUALAN</h4>
                            <button className="button button-cancel button-delete-transaction" onClick={handleClickDelete}>DELETE</button>
                        </div>
                        <i class="fas fa-times" onClick={handleCloseModal}></i>
                        {
                            showVerifDelete && (
                                <div className="wrapper-verif-delete-transaction">
                                    <div className="wrapper-text-delete-transaction">
                                        <b>Are You Sure ?</b> <br/><br/>
                                        <button disabled={loadingDeleteDetailTransaction} className={`button ${loadingDeleteDetailTransaction ? 'button-primary-disabled' : 'button-primary'} mr-5`} onClick={handleSubmitDeleteTransaction}>YES</button>
                                        <button disabled={loadingDeleteDetailTransaction} className={`button ${loadingDeleteDetailTransaction ? 'button-cancel-dissabled' : 'button-cancel'}`} onClick={()=> setShowVerifDelete(false)}>NO</button>
                                    </div>
                                </div>
                            )
                        }
                        <div className="modal-scroll-panel-full">
                            <span className="img-triangle-top"></span>
                            <div className="bill-body">
                                <h4 className="bill-title">HEYLOOK</h4>
                                <div className="d-flex mb-10">
                                    <span className="flex-1">Waktu</span> <span className="flex-3">: &nbsp; {moment(modalDetailTransaction.data.created_at).format('D-MM-YYYY h:mm:ss a')}</span> <br/>
                                </div>
                                <div className="d-flex">
                                    <span className="flex-1">Kasir</span> <span className="flex-3">: &nbsp; {modalDetailTransaction.data.user.username}</span>
                                </div>
                                <div className="dashed-line"/>
                                {
                                    modalDetailTransaction.data.cart.map(cart => {
                                        return(
                                            <div className="d-flex mb-10">
                                                <span className="flex-3 mr-5">{cart.product.name}-{cart.product.size.skuVariant}-{cart.product.color.label}</span>
                                                <span className="flex-1 mr-5">x{cart.quantity}</span>
                                                <span className="flex-2 text-right">
                                                    {
                                                        cart.product.subTotalPriceAfterDiscount ? 
                                                          <>
                                                            <s>Rp{convertnumber(cart.product.price)}</s> <br/>
                                                            Rp{convertnumber(cart.product.subTotalPriceAfterDiscount)}
                                                          </>  
                                                        :
                                                        <>
                                                            Rp{convertnumber(cart.product.price)}
                                                        </>
                                                    }
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                                <div className="dashed-line"/>
                                { 
                                    modalDetailTransaction.data.bundle.length > 0 && (
                                        <>
                                            <p className="text-center mt-0 fs-12">#BUNDLE</p> 
                                            {
                                                modalDetailTransaction.data.bundle.map(bundle => {
                                                    return(
                                                        <div className="d-flex mb-10">
                                                            <span className="flex-3 mr-5">{bundle.product.name}</span>
                                                            <span className="flex-1 mr-5">x{bundle.quantity}</span>
                                                            <span className="flex-2 text-right">
                                                                Rp0
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="dashed-line"/>
                                        </>

                                ) 
                                }
                                <div className="d-flex mb-10">
                                    <span className="flex-4 fw-500">Total</span>
                                    <span className="flex-2 text-right">Rp{convertnumber(modalDetailTransaction.data.totalPrice)}</span>
                                </div>
                                <div className="dashed-line"/>
                                <div className="d-flex mb-10">
                                    <span className="flex-4">Sumber Terjual</span> 
                                    <span className="flex-2">: &nbsp; {modalDetailTransaction.data.soldFrom.name}</span>
                                </div>
                                {
                                    modalDetailTransaction.data.soldFrom.name === 'offline store' && (
                                        <>
                                            <div className="d-flex mb-10">
                                                <span className="flex-4">Metode Pembayaran</span> 
                                                <span className="flex-2">: &nbsp; {modalDetailTransaction.data.paymentMethod}</span>
                                            </div>
                                            {
                                                modalDetailTransaction.data.paymentMethod === 'cash' && (
                                                    <>
                                                        <div className="d-flex mb-10">
                                                            <span className="flex-4">Tunai</span> 
                                                            <span className="flex-2">: &nbsp; Rp{convertnumber(modalDetailTransaction.data.nominalPayment)}</span>
                                                        </div>
                                                        <div className="d-flex mb-10">
                                                            <span className="flex-4">Kembali</span> 
                                                            <span className="flex-2">: &nbsp; Rp{convertnumber(modalDetailTransaction.data.changes)}</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <div className="dashed-line"/>
                            </div>
                            <span className="img-triangle-bottom"></span>
                        </div>
                    </Modal>
                )
            }
            
        </div>
    )
}

export default SalesTransaction;