import React, {useState, useEffect} from 'react';
import {
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";

function CardList() {

    const history = useHistory()
    let { path, url } = useRouteMatch();

    return (
        <div className="wrapper-card">
                <div className="card card--report" onClick={() => history.push(`${url}/ringkasan`)}>
                    <i class="fa fa-bookmark"></i>
                    Ringkasan
                </div>
                <div className="card card--report" onClick={() => history.push(`${url}/transaksi-penjualan`)}>
                    <i class="fa fa-signal"></i>
                    Data Transaksi Penjualan
                </div>
                <div className="card card--report" onClick={() => history.push(`${url}/penjualan-produk`)}>
                    <i class="fa fa-suitcase"></i>
                    Penjualan Produk
                </div>
                <div className="card card--report" onClick={() => history.push(`${url}/omset-harian`)}>
                    <i class="fa fa-calendar"></i>
                    Omset Harian
                </div>
                <div className="card card--report" onClick={() => history.push(`${url}/omset-produk`)}>
                    <i class="fa fa-money"></i>
                    Omset Produk
                </div>
                <div className="card card--report" onClick={() => history.push(`${url}/penjualan-harian`)}>
                    <i class="fa fa-dollar"></i>
                    Penjualan Harian
                </div>
                <div className="card--s card--report--s" onClick={() => history.push(`${url}/penjualan-perkategori`)}>
                    <i class="fa fa-tag"></i> &nbsp;
                    Penjualan Per Kategory
                </div>
                <div className="card--s card--report--s" onClick={() => history.push(`${url}/penjualan-permarketplace`)}>
                    <i class="fa fa-shopping-cart"></i> &nbsp;
                    Penjualan Per Marketplace
                </div>
        </div>
    )
}
export default CardList