function getTotalPrice(transactions){
    let result = 0;
    transactions.map(transaction => {
        if(transaction.subTotalPriceAfterDiscount) {
            result += transaction.subTotalPriceAfterDiscount
        }else if(transaction.subTotalPrice) {
            result += transaction.subTotalPrice
        }else if(transaction.product && transaction.product.subTotalPriceAfterDiscount){
            result += transaction.product.subTotalPriceAfterDiscount
        }else if(transaction.product && transaction.product.subTotalPrice){
            result += transaction.product.subTotalPrice
        }
    })
    return result
}
export default getTotalPrice;