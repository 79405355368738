import React, {useState, useEffect}from 'react';
import './responsive.css'
import './App.css'
import './Custom.css'
import Navbar from './components/Navbar'
import Home from './views/Home'
import Product from './views/Product'
import User from './views/User'
import Report from './views/Report'
import Notification from './views/Notification'
import Login from './views/Login'
import Sales from './views/Sales'
import Information from './views/Information'
import UpdateStockScanner from './views/UpdateStockScanner'
import Ecommerce from './views/Ecommerce'
import Logout from './views/Login/Logout'
import { ToastContainer, toast } from 'react-toastify';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import axios from "axios"
import {
  setAuth, 
  setDataUser
} from './store/actions'
import PrivateRoute from './PrivateRoute'


const Container = styled.div`
  ${({isAuth}) => !isAuth && `
    padding: 0;
    `
  }
`

function App() {

  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.isAuth);
  const [isLoading, setIsLoading] = useState(true);
  
  return (
    <div className={`App`}>
      <Router>
        {
          isAuth && <Navbar /> 
        }
        {
          isAuth && <Logout/> 
        }
        <Container isLoading={isLoading} isAuth={isAuth} className="container">
          <Switch>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/report">
              <Report />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/product">
              <Product />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/user">
              <User />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/notification">
              <Notification />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/sales">
              <Sales />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/information">
              <Information />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/updateStockScanner">
              <UpdateStockScanner />
            </PrivateRoute>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/ecommerce">
              <Ecommerce />
            </PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path="/">
              <Home />
            </PrivateRoute>
          </Switch>
        </Container>
      </Router>
      <ToastContainer autoClose={2000} className={'notif-toast'}/>
    </div>
  );
}

export default App;
