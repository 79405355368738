import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Barcode from 'react-barcode';
import Modal  from 'react-modal';
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs';
import convertnumber from '../../helpers/convertnumber'
import generateSku from '../../helpers/generateSku'
import styled from 'styled-components'
import { useSelector } from "react-redux";
import Popup from '../../components/Notification/Popup';
import moment from 'moment-timezone'
import { Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode'

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    section: {
      margin: 10,
      padding: 10,
      alignItems: 'center',
      marginVertical: 50,
    },
    image: {
        width: 150,
    },
    text: {
        textAlign: 'center',
        fontSize: 14
    }
  });

const customStylesModalUserDetail = {
    content : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight : '-50%',
        transform : 'translate(-50%, -50%)',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(246 246 246)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        zIndex: 2,
    }
};
const Card = styled.div`
    ${({active}) => active ? `
        box-shadow: 0px 0px 10px 0px #ff9c00;
        top: -3px;
        background: #fafbfd;
        ` : `
        box-shadow: 0px 0px 3px 2px #384f59;
        background: #fafbfd;
        top: -3px;`
    }
`
function ProductList({ product, setProductList, productList, handleEditProduct, role }) {

    {/**
        ----------STATE----------
    */}
    const [isHideVariant, setIsHideVariant] = useState(true)
    const [isShowOpenCloseVariantButton, setIsShowOpenCloseVariantButton] = useState(false)
    const [productFilter, setProductFilter] = useState(null)
    const [loadingDeleteProduct, setLoadingDeleteProduct] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isAddStock, setIsAddStock] = useState(false)
    const [isAddReject, setIsAddReject] = useState(false)
    const [inputStock, setInputStock] = useState(0)
    const [inputReject, setInputReject] = useState(0)
    const [isShowBarcode, setIsShowBarcode] = useState(false)
    const [isShowVerifDeleteProduct, setIsShowVerifDeleteProduct] = useState(false)
    const name = useSelector(state => state.name);
    const refBarcode = useRef()
    const [barcode, setBarcode] = useState(null)
    const [countBarcode, setCountBarcode] = useState(1)

    {/**
        ----------USEEFFECT----------
    */}

    useEffect(()=> {
        setProductFilter(product)
        let splitName = product.name.split(' ')
        let skuName = ''
        if(splitName[1] != undefined && splitName[1] != '') {
            skuName = product.name.split(' ')[1].slice(0,3)
        }else {
            skuName = product.name.slice(0,3)
        }
        // setGenerateSku((`${product.category.skuVariant}-${skuName}${product.size.skuVariant}${product.color.skuVariant}`).toLocaleUpperCase())
    },[product])
    
    useEffect(() => {
        if(productFilter){
            generateBarcode()
        }
    },[productFilter])
    {/**
        ----------FUNCTIONALITY----------
    */}

    const MyDocument = (count) => (
        <Document>
            <Page size="A4" style={styles.page}>
                {generateMultipleBarcodeToPDF()}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>{`${productFilter.name}`}</Text>
                    <Image
                        style={styles.image}
                        src={barcode}
                    />
                    <Text style={styles.text}>
                        {
                            (role == 'admin' || role == 'super admin') && (
                                <p style={{textAlign: 'center', margin: 0}}>
                                    {`Rp${convertnumber(productFilter.price)}`}
                                </p>
                            ) 
                        }
                    </Text >
                </View> */}
            </Page>
        </Document>
      );
    
    const generateMultipleBarcodeToPDF = () => {
        let result = []
        for(let i = 1; i <= countBarcode; i++) {
            result.push(
                <View style={styles.section}>
                    <Text style={styles.text}>{`${productFilter.name}`}</Text>
                    <Image
                        style={styles.image}
                        src={barcode}
                    />
                    {
                        (role == 'admin' || role == 'super admin') && (
                            <Text style={styles.text}>
                                <p style={{textAlign: 'center', margin: 0}}>
                                    {`Rp${convertnumber(productFilter.price)}`}
                                </p>
                            </Text >
                        ) 
                    }
                </View>
            )
        }
        return result
    }


    const generateBarcode = () => {
          let canvas = ''
          canvas = document.createElement('canvas')
          JsBarcode(canvas, generateSku(productFilter && productFilter))
          setBarcode(canvas.toDataURL())
      }

    const handleDeleteProduct = function() {
        setLoadingDeleteProduct(true)
        axios.delete(`/product/${productFilter._id}`)
        .then(()=>{
            setLoadingDeleteProduct(false)
            toast.success('delete product success')
        })
        .catch((err)=>{
            setLoadingDeleteProduct(false)
            toast.error('delete product failed')})
        axios.post('/deleteImage', {
            image: productFilter.image
        })
        .then(()=>{})
        .catch((err)=>{})
        setIsShowVerifDeleteProduct(false)
        setProductList(productList.filter(elProduct => product._id !== elProduct._id ))
    }
    
    const handleCloseUpdateStock = function() {
        setIsAddStock(false)
        setInputStock(0)
    }
    const handleCloseUpdateReject = function() {
        setIsAddReject(false)
        setInputReject(0)
    }
    const handleAddStock = function(){
        if(loading) return;
        setLoading(true)
        let today = new Date()
        let getIndoDate = moment.tz(today, "Asia/Jakarta");
        let dateIndo = getIndoDate.format()
        axios.put(`/product/stock/${productFilter._id}`, {
            stock: inputStock,
            created_at: dateIndo,
            product: productFilter
        })
        .then(({data})=>{
            setLoading(false)
            setProductFilter(data)
            handleCloseUpdateStock()
        })
        .catch(err => {
            setLoading(false)
            err.response && err.response.data && toast.error(err.response.data.message)})
        }
    const handleAddReject = function(){
        if(loading) return;
        setLoading(true)
        let today = new Date()
        let getIndoDate = moment.tz(today, "Asia/Jakarta");
        let dateIndo = getIndoDate.format()
        axios.put(`/product/reject/${productFilter._id}`, {
            reject: inputReject,
            created_at: dateIndo,
            product: productFilter
        })
        .then(({data})=>{
            setLoading(false)
            setProductFilter(data)
            handleCloseUpdateReject()
        })
        .catch(err => {
            setLoading(false)
            err.response && err.response.data && toast.error(err.response.data.message)})
    }
    const handleDownloadBarcode = function() {
        setLoading(true)
        htmlToImage.toPng(document.getElementById(`barcode${productFilter._id}`))
        .then(function (dataUrl) {
                setLoading(false)
                download(dataUrl, `${productFilter.name}.png`);
            })
        .catch(function (error) {
            setLoading(false)
            toast.error('oops, something went wrong!');
        })
    }
    const handleCloseModal = function() {
        setIsShowBarcode(false)
    }

    {/**
        ----------RENDER COMPONENT----------
    */}
    return (
        <>
            {loadingDeleteProduct && <Popup text={'Delete Product'}/>}
            {loading && <Popup />}
            <div className={`tr-product ${ productFilter != null && (productFilter.stock < 30 ? 'background-danger' : (productFilter.stock >= 30 && productFilter.stock < 100) ? 'background-warning' : '') }`}>
                <div className="td-l td-name padding-r-10">
                    <div style={{height: '50px', width: '50px', marginRight: '10px'}}>
                        {/* <canvas id="myCanvas" width="50" height="50"></canvas> */}
                        <img className="image-product-s" src={`https://apiv1.heylook-dashboard.xyz/images/${productFilter && productFilter.image}`} alt={productFilter && productFilter.name.slice(0,15)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span className="text-ellipsis product-name">{productFilter && productFilter.name}</span>
                        <span className="text-grey">{generateSku(productFilter && productFilter)}</span>
                    </div>
                </div>
                <div style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', marginBottom: '15px'}}>
                            <span className="td-sm p-0-5 fw-500">
                                { productFilter && `${productFilter.color != undefined ? productFilter.color.label : ''} 
                                `}
                                <span className="d-block bt-grey size">
                                    {productFilter && productFilter.size != undefined ? productFilter.size.label : ''}
                                </span>
                            </span>
                            <span className="td-sm">Rp. {productFilter && convertnumber(productFilter.price)}</span>
                            <span className="td-sm">{productFilter && convertnumber(productFilter.stock)}</span>
                            <span className="td-sm">{productFilter && convertnumber(productFilter.reject)}</span>
                            <span className="td-sm">{productFilter && convertnumber(productFilter.sold)}</span>
                        </div>
                </div>
                <div className="td-m padding-r-10">
                    <span className="text-ellipsis">{productFilter && productFilter.category && productFilter.category.value}</span>
                </div>
                <div className="td-s">
                    {
                        role == 'admin' || role == 'super admin' ? (
                            <>
                                <span 
                                    className="text-blue d-block mb-10"
                                    onClick={()=> setIsShowBarcode(true)}>
                                        Barcode
                                </span>
                                <span 
                                    className="text-blue d-block mb-10"
                                    onClick={() => handleEditProduct(productFilter)}>
                                        Ubah
                                </span>
                                {
                                    isShowVerifDeleteProduct ? (
                                        <>
                                            <b>Are you sure?</b>
                                            <div className="d-flex mt-5">
                                                <span className="text-red" onClick={()=>setIsShowVerifDeleteProduct(false)}>No</span>
                                                <span className="text-blue ml-10" onClick={handleDeleteProduct}>Yes</span>
                                            </div>
                                        </>
                                    ) : (
                                        <span 
                                            className="text-blue d-block"
                                            onClick={()=>setIsShowVerifDeleteProduct(true)}>
                                                Delete
                                        </span>
                                    )
                                }
                            </>
                        ) : (
                            <div className="d-flex dir-col wrapper-status__action">
                                {
                                    isAddStock ?
                                        <>
                                            <input
                                                min='0'
                                                type="number"
                                                value={inputStock === 0 ? '' : inputStock }
                                                onChange={(e) => setInputStock(e.target.value)}
                                                onKeyDown={(e) => e.key == 'Enter' && handleAddStock()} 
                                                placeholder="update total stock"/>
                                            <button
                                                onClick={handleAddStock}
                                                className="submit">Submit</button>
                                            <button className="cancel" onClick={handleCloseUpdateStock}>Cancel</button>
                                        </>
                                        : isAddReject ? 
                                            <>
                                                <input
                                                    min='0'
                                                    type="number"
                                                    value={inputReject === 0 ? '' : inputReject } 
                                                    onChange={(e) => setInputReject(e.target.value)} 
                                                    onKeyDown={(e) => e.key == 'Enter' && handleAddReject()} 
                                                    placeholder="update total reject"/>
                                                <button 
                                                    onClick={handleAddReject}
                                                    className="submit">Submit</button>
                                                <button className="cancel" onClick={handleCloseUpdateReject}>Cancel</button>
                                            </>
                                            :
                                                <>
                                                    <span className="btn-add-stock" onClick={() => !loading && setIsAddStock(true)}>ADD STOCK</span>
                                                    <span className="btn-add-reject" onClick={() => !loading && setIsAddReject(true)}>ADD REJECT</span>
                                                    <button className="button button-mobile button-secondary mt-10" onClick={()=> setIsShowBarcode(true)}>Barcode</button>
                                                </>
                                }
                            </div>
                        )
                    }
                </div>
                {/* 
                    Show Notification
                */}
                {/* 
                    Show Modal Barcode
                */}
                {
                    isShowBarcode && 
                    <Modal
                        id={"barcode-modal"}
                        isOpen={isShowBarcode}
                        onRequestClose={handleCloseModal}
                        style={customStylesModalUserDetail}
                        contentLabel="Modal User Detail"
                    >
                        <span 
                            id={`barcode${productFilter._id}`}
                            className='d-block mb-10 w-fit wrapper-barcode'
                        >
                        </span>
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                            <PDFDownloadLink document={<MyDocument count={countBarcode} />} fileName={productFilter.name}>
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : 'Download now!'
                                }
                            </PDFDownloadLink>
                        </div>
                        <div>
                            <label>
                                Total barcode
                            </label>
                            &nbsp;
                            <input 
                                style={{height: '35px', border: '1px solid black', width: '50px'}}
                                value={countBarcode}
                                type="number"
                                onChange={(e) => setCountBarcode(e.target.value)}/>
                        </div>
                        {/* <span className="text-blue d-block w-fit m-auto" onClick={handleDownloadBarcode}>Download Barcode</span> */}
                    </Modal>
                }
            </div>
            {/*
                ====== generate barcode ======
                <p style={{textAlign: 'center', margin: 0}}>
                    {`${productFilter.name}`}
                </p>
                <Barcode
                    width={2} 
                    fontSize={12}
                    marginBottom={10}
                    textMargin={0}
                    textAlign={'center'}
                    value={`${generateSku(productFilter && productFilter)}`} />
                <br/>
                {
                    (role == 'admin' || role == 'super admin') && (
                        <p style={{textAlign: 'center', margin: 0}}>
                            {`Rp${convertnumber(productFilter.price)}`}
                        </p>
                    ) 
            } */}

            {/* ----mobile -version---- */}
            
            <Card 
                className={`card-item card-item-product`}
            >
                <span className="item-size">{productFilter && productFilter.size.label}</span>
                <div className="d-flex mt-15">
                    <img className="image-product-s" src={`https://apiv1.heylook-dashboard.xyz/images/${productFilter && productFilter.image}`} alt={productFilter && productFilter.name.slice(0,8)}/>
                    <b className="item-product-name">{productFilter && productFilter.name}</b>
                </div>
                <span className="text-grey">{generateSku(productFilter)}</span>
                <div className="d-flex">
                    <span className="item-category"><i className="fa fa-tag"></i>{productFilter && productFilter.category.label}</span>
                    <span className="item-category ml-10"><i className="fa fa-tint"></i>{productFilter && productFilter.color.label}</span>
                </div>
                <div className="d-flex">
                    <span className="item-category item-stock">stock: {convertnumber(productFilter && productFilter.stock)}</span>
                    <span className="item-category item-stock ml-10">reject: {convertnumber(productFilter && productFilter.reject)}</span>
                    <span className="item-category item-stock ml-10">sold: {convertnumber(productFilter && productFilter.sold)}</span>
                </div>
                {
                    role == 'admin' || role == 'super admin' ? 
                        <>
                            <button className="button button-mobile button-secondary mt-10" onClick={()=> setIsShowBarcode(true)}>Barcode</button>
                            <button className="button button-mobile button-edit mt-10" onClick={() => handleEditProduct(productFilter)}>Ubah</button>
                            {
                                isShowVerifDeleteProduct ? (
                                    <>
                                        <b style={{textAlign: 'center'}}>Are you sure?</b>
                                        <div className="d-flex mt-5 justify-center">
                                            <span className="text-red" onClick={()=>setIsShowVerifDeleteProduct(false)}>No</span>
                                            <span className="text-blue ml-10" onClick={handleDeleteProduct}>Yes</span>
                                        </div>
                                    </>
                                ) : (
                                    <button className="button button-mobile button-delete-user mt-10" onClick={()=>setIsShowVerifDeleteProduct(true)}>Delete</button>
                                )
                            }
                        </>
                    :
                        <div className="d-flex dir-col wrapper-status__action mt-10">
                            {
                                isAddStock ?
                                    <>
                                        <input
                                            min='0'
                                            type="number"
                                            value={inputStock === 0 ? '' : inputStock}
                                            onChange={(e) => setInputStock(e.target.value)}
                                            onKeyDown={(e) => e.key == 'Enter' && handleAddStock()} 
                                            placeholder="update total stock"/>
                                        <button
                                            onClick={handleAddStock}
                                            className="submit">SUBMIT</button>
                                        <button className="cancel" onClick={handleCloseUpdateStock}>CANCEL</button>
                                    </>
                                    : isAddReject ? 
                                        <>
                                            <input
                                                min='0'
                                                type="number"
                                                value={inputReject === 0 ? '' : inputReject} 
                                                onChange={(e) => setInputReject(e.target.value)} 
                                                onKeyDown={(e) => e.key == 'Enter' && handleAddReject()} 
                                                placeholder="update total reject"/>
                                            <button 
                                                onClick={handleAddReject}
                                                className="submit">SUBMIT</button>
                                            <button className="cancel" onClick={handleCloseUpdateReject}>CANCEL</button>
                                        </>
                                        :
                                            <>
                                                <span className="btn-add-stock" style={{height: '30px'}} onClick={() => setIsAddStock(true)}>ADD STOCK</span>
                                                <span className="btn-add-reject" style={{height: '30px'}} onClick={() => setIsAddReject(true)}>ADD REJECT</span>
                                                <button className="button button-mobile button-secondary mt-10" onClick={()=> setIsShowBarcode(true)}>Barcode</button>
                                            </>
                            }
                        </div>
                }
            </Card>
        </>
    )
}
export default ProductList