import React, {useState, useEffect, useRef} from 'react';
import Search from '../../components/Input/search.jsx'
import './index.css'
import ActivityStreamList from './ActivityStreamList'
import axios from 'axios'
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components'
import Skeleton from "react-loading-skeleton";
import moment from 'moment'
import ReactExport from "react-export-excel";
import DatePicker from "react-datepicker";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ButtonFilter = styled.button`
    ${({selected}) => selected && `
        border-radius: 5px;
        background: #1F5CC4;
        color: white;
        font-weight: bold;
        padding: 0 10px;
        border: 1px solid #051E29;
        &:hover {
            background: #2789f8;
        }
    `}
`


function ActivityStream({setIsOpenActivityStream}) {

    {/**
        ----------STATE----------
    */}
    const role = useSelector(state => state.role);
    const [isLoadingFetchActivity, setIsLoadingFetchActivity] = useState(false)
    const [activityStreamList, setActivityStreamList] = useState([])
    const [dataSetExcel, setDataSetExcel] = useState(null)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    {/**
        ----------USEEFFECT----------
    */}
    useEffect(()=> {
        fetchActivityStream()
    },[])
    
    {/**
        ----------FUNCTIONALITY----------
    */}


    function fetchActivityStream() {
        setIsLoadingFetchActivity(true)
        axios.get(`/activityStream?startDate=${moment(startDate).format()}&endDate=${moment(endDate).format()}`)
        .then(({data}) => {
            setIsLoadingFetchActivity(false)
            setActivityStreamList(data)
            generateExcelData(data)
        })
        .catch(err => {
            setIsLoadingFetchActivity(false)
            toast.error('Fetch data color for filter error')})
    }

    function handleClickProductList(){
        setIsOpenActivityStream(false)
    }

    function generateExcelData(data) {
        let listData = []
        data.map(activity => {
            listData.push({
                date: moment(activity.created_at).format('D-MM-YYYY, h:mm:ss a'),
                action: activity.action,
                user: activity.user,
                productName: activity.target.product.name,
                size: activity.target.product.size.label,
                color: activity.target.product.color.label,
                category: activity.target.product.category.label,
                stockUpdated: activity.target.stock
            })
        })
        setDataSetExcel(listData)
    }

    const onChangeDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start && end) {
            setShowDatePicker(false)
            fetchActivityStream(start, end)
        } 
    };

    {/**
        ----------RENDER COMPONENT----------
    */}
    return (
        <div>
            <div className="header header-product">
                <Search activityStream={true} list={activityStreamList} setList={setActivityStreamList} />
                <div className="header-wrapper-button header-wrapper-button-product">
                    <button className="button button-primary btn-nav-product-list" onClick={handleClickProductList}>PRODUCT LIST</button>
                    <div className="header--summary__date ml-10" style={{marginRight: 0}}>
                        <div className='button--date' style={{ height: '35px'}} onClick={()=> setShowDatePicker(!showDatePicker)}>
                            {`${moment(startDate).format('L')} - ${moment(endDate).format('L')}`}
                        </div>
                        {
                            showDatePicker &&
                                <div className="pos-absolute">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onChangeDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        inline
                                    />
                                </div>
                        }
                    </div>
                    <div className="header-wrapper-button header-wrapper-button-product btn-export-excel" style={{display: 'flex', marginLeft: '15px', alignItems: 'center'}}>
                        {
                            dataSetExcel ? (
                                <ExcelFile element={<button style={{ backgroundColor: '#fafbfd', borderRadius: '3px', cursor: 'pointer'}}><i class="far fa-file-excel" style={{color: '#57df5a'}}></i> excel</button>}>
                                    <ExcelSheet data={dataSetExcel} name="data activity stream">
                                        <ExcelColumn label="Tanggal" value="date"/>
                                        <ExcelColumn label="Action" value="action"/>
                                        <ExcelColumn label="User" value="user"/>
                                        <ExcelColumn label="Product Name" value="productName"/>
                                        <ExcelColumn label="Size" value="size"/>
                                        <ExcelColumn label="color" value="color"/>
                                        <ExcelColumn label="Category" value="category"/>
                                        <ExcelColumn label="Stock Updated" value="stockUpdated"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            )  : <button disabled style={{ backgroundColor: '#fafbfd', cursor: 'not-allowed', borderRadius: '3px', color: 'rgb(154 154 154)'}}><i class="far fa-file-excel"></i> excel</button>
                        }
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="table-product">
                    <div className="thead-product">
                        <div className="tr-product">
                            <span className="td-l">
                                Date
                            </span>
                            <span className="td-m">
                                Action
                            </span>
                            <span className="td-m">
                                User
                            </span>
                            <span className="td-m">
                                Product Name
                            </span>
                            <span className="td-s">
                                Variant
                            </span>
                            <span className="td-s">
                                Category
                            </span>
                            <span className="td-s">
                                Stock updated
                            </span>
                        </div>
                    </div>
                    <div className="tbody-product">
                        {
                            isLoadingFetchActivity ? 
                                <Skeleton count={10} height={118} />
                            :
                            activityStreamList.map((activity, indexActivity) =>{
                                    return (
                                        <>
                                            {
                                                activity.show && (
                                                    <ActivityStreamList 
                                                        key={activity._id}
                                                        activity={activity}
                                                        setActivityStreamList={setActivityStreamList}
                                                        role={role}
                                                    />
                                                )
                                            }
                                        </>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivityStream