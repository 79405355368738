import React, {useState, useEffect} from 'react'
import {
    BrowserRouter as Router,
    Link,
    Switch,
    Route,
    useParams,
    useHistory,
    useLocation
  } from "react-router-dom";
  import axios from 'axios'
import { toast } from 'react-toastify';
import moment from 'moment'
import convertnumber from '../../helpers/convertnumber';
import Modal  from 'react-modal';
import Select from 'react-select'

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '350px',
        // marginTop: '10px'
    }),
    menu: (provided, state) => ({
        ...provided,
        // bottom: '100%',
        // top: 'auto'
    }),
}

function TransactionDetail({transactionList, setTransactionList, transactionId, setOpenTransactionDetail}) {

    const [transaction, setTransaction] = useState({})
    const [loadTransaction, setLoadTransaction] = useState(true)
    const [inputShihppingNumber, setInputShihppingNumber] = useState('')
    const orderStatusOption = [
        {
            label: 'Menunggu Konfirmasi Pembayaran',
            value: 'pending',
        },
        {
            label: 'Konfirmasi Pembayaran',
            value: 'confirmed',
        },
        {
            label: 'Dalam Pengiriman',
            value: 'shipping',
        },
        {
            label: 'Pesanan Selesai',
            value: 'complete',
        },
    ]

    useEffect(() => {
        axios.get(`/ecommerceTransaction/${transactionId}`)
        .then(({data}) => {
            setTransaction(data)
            setLoadTransaction(false)
        })
        .catch(err => {
            setLoadTransaction(false)
            toast.error('Server error. Please contact our support')
        })
    },[])

    function closeModal() {
        setOpenTransactionDetail(null)
    }

    function handleChangeOrderStatus(value){
        setTransaction({...transaction, orderStatus: value.value})
        setTransactionList(transactionList.map(transaction => {
            if(transaction._id === transactionId){
                transaction.orderStatus = value.value
            }
            return transaction;
        }))
        axios.put(`/ecommerceTransaction/${transactionId}`, {
            orderStatus: value.value,
            paymentMethod: transaction.paymentMethod, 
            paymentNumber: transaction.paymentNumber, 
            shippingNumber: transaction.shippingNumber

        })
        .then(({data}) => toast.success('Order Status Updated'))
        .catch(err => toast.error('Update Order Status Failed'))
    }
    
    function submitShippingNumber() {
        setTransaction({...transaction, shippingNumber: inputShihppingNumber})
        axios.put(`/ecommerceTransaction/${transactionId}`, {
            orderStatus: transaction.orderStatus,
            paymentMethod: transaction.paymentMethod, 
            paymentNumber: transaction.paymentNumber, 
            orderStatus: transaction.orderStatus, 
            shippingNumber: inputShihppingNumber
            
        })
        .then(({data}) => {
            setInputShihppingNumber('')
            toast.success('Nomor Resi Updated')
        })
        .catch(err => toast.error('Update Nomor Resi Failed'))

    }

    return(
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
        >
            <i class="fas fa-times float-right" onClick={closeModal}></i>
            {
                !loadTransaction && transaction && (
                    <>
                        <div className="detail-order-header">
                            <div className="d-flex align-center">
                                <span>No.PESANAN. {transaction._id}</span>
                                <div className="divider"/>
                                <span>Status: </span> &nbsp;
                                <Select
                                    options={orderStatusOption}
                                    styles={customStyles}
                                    onChange={handleChangeOrderStatus}
                                    placeholder={
                                        transaction.orderStatus === 'pending' ? 'Menunggu Konfirmasi Pembayaran' 
                                        : transaction.orderStatus === 'confirmed' ? 'Pesanan Di konfirmasi' 
                                        : transaction.orderStatus === 'shipping' ? 'Dalam Pengiriman' 
                                        : transaction.orderStatus === 'complete' ? 'Selesai' 
                                        : 'Menunggu Update Status'
                                    }
                                />
                                <div className="divider"/>
                                &nbsp;
                                <div className="wrapper-input-resi">
                                    <input 
                                        className="input-resi" 
                                        placeholder="input nomor resi"
                                        value={inputShihppingNumber}
                                        onChange={(e) => setInputShihppingNumber(e.target.value)}
                                    />
                                    &nbsp;
                                    <button className="button-primary" onClick={submitShippingNumber}>{(transaction.shippingNumber && transaction.shippingNumber[0]) ? 'EDIT' : 'SEND'}</button>
                                </div>
                            </div>
                        </div>
                        {
                            transaction.address && (
                                <div className="detail-order-address">
                                    <div className="d-flex justify-between">
                                        <h4>Alamat Pengiriman</h4>
                                        <div className="text-center">
                                            <h5 className="mb-0">{transaction.shippingName.toUpperCase()} - {transaction.shippingService.toUpperCase()}</h5>
                                            <span className="fs-12">({(transaction.shippingNumber && transaction.shippingNumber[0] ? transaction.shippingNumber : '-')})</span>
                                        </div>
                                    </div>
                                    <p>{transaction.address.firstName} {transaction.address.lastName} (<a href={`https://wa.me/${transaction.address.phoneNumber}`} target="_blank">{transaction.address.phoneNumber} </a>)</p>
                                    <p className="text-dark-grey">{transaction.address.fullAddress},{transaction.address.kecamatan},{transaction.address.city},{transaction.address.province},{transaction.address.postcode}</p>
                                    <p className="text-grey"><b>Note:</b> {transaction.note}</p>
                                </div>
                            )
                        }
                        <div className="custom-divider"/>
                        {
                            transaction.cart.map(cart => {
                                return (
                                    <div className="detail-order-product-list">
                                        <div className="d-flex">
                                            <img src={`https://apiv1.heylook-dashboard.xyz/images/${cart.product.image}`}/>
                                            <div className="margin-left-10">
                                                <p>{cart.product.name}</p>
                                                <p className="text-grey">x{cart.quantity}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-col">
                                            {
                                                cart.product.discount ? 
                                                    <>
                                                        <strike className="text-grey">Rp{convertnumber((cart.product.price) * cart.quantity)}</strike>
                                                        <p className="text-orange-dark">Rp{convertnumber((cart.product.discount) * cart.quantity)}</p>
                                                    </>
                                                :
                                                    <p className="text-orange-dark">Rp{convertnumber((cart.product.price) * cart.quantity)}</p>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div>
                            <p className="d-flex justify-between border-bottom-grey pb-10"> <span className="text-grey">Subtotal Produk</span> Rp{convertnumber(transaction.totalPrice - transaction.shippingPrice)}</p>
                            <p className="d-flex justify-between border-bottom-grey pb-10"> <span className="text-grey"> Pengiriman </span> Rp{convertnumber(transaction.shippingPrice)}</p>
                            <p className="d-flex justify-between border-bottom-grey pb-10"> <span className="text-grey"> Total </span> Rp{convertnumber(transaction.totalPrice)}</p>
                            <p className="d-flex justify-between border-bottom-grey pb-10"> <span className="text-grey"> Metode Pembayaran </span> ({transaction.paymentMethod} - {transaction.paymentNumber})</p>
                            <p className="d-flex justify-between border-bottom-grey pb-10"> <span className="text-grey"> Nomor Resi Pengiriman </span> ({(transaction.shippingNumber && transaction.shippingNumber[0]) ? transaction.shippingNumber : ' - '})</p>
                        </div>
                    </>   
                )
            }
        </Modal>
    )
}

export default TransactionDetail;