import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { toast } from 'react-toastify'
import InfoSku from './infosku'
import InforVariant from './infoVariant'

function Variant({newVariantData, setNewVariantData, setIsOpenModal, updateVariantData, setUpdateVariantData, setEditVariantData}) {

    
    return(
        <div className="d-flex section-variant">
            <InfoSku />
            <InforVariant
                newVariantData={newVariantData} 
                setNewVariantData={setNewVariantData} 
                setIsOpenModal={setIsOpenModal} 
                updateVariantData={updateVariantData}
                setUpdateVariantData={setUpdateVariantData}
                setEditVariantData={setEditVariantData}
            />
        </div>
    )
}
export default Variant