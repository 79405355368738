import React, {useState, useEffect, useRef}from 'react';
import './index.css'
import Search from '../../components/Input/search.jsx'
import Select from 'react-select/creatable';
import SideBar from './sidebar'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import styled from 'styled-components'
import convertnumber from '../../helpers/convertnumber'
import generateSku from '../../helpers/generateSku'
import Skeleton from "react-loading-skeleton";
import {
    useHistory,
} from "react-router-dom";

const Card = styled.div`
    ${({active}) => active ? `
        box-shadow: 0px 0px 10px 0px #ff9c00;
        top: -3px;
        background: #fafbfd;
        ` : `
        box-shadow: 0px 0px 3px 2px #384f59;
        background: #fafbfd;
        top: -3px;`
    }
`
const BundleButton = styled.span`
${({active}) => active && 
    `
        background: #999999;
        color: white;
    ` 
}
`
const WrapperItems = styled.div`
    width: ${({resize}) => resize ? '70%' : '100%' }
`
const ButtonFilter = styled.button`
    ${({selected}) => selected && `
        border-radius: 5px;
        background: #1F5CC4;
        color: white;
        font-weight: bold;
        padding: 0 10px;
        border: 1px solid #051E29;
        &:hover {
            background: #2789f8;
        }
    `}
`
function Sales() {

    const history = useHistory()
    const [cart, setCart] = useState(null)
    const [bundle, setBundle] = useState(null)
    const [mainItems, setMainItems] = useState([])
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingFetchProduct, setIsLoadingFetchProduct] = useState(false)
    const [optionsMarketplace, setOptionsMarketplace] = useState([])
    const [openPopupFilter, setOpenPopupFilter] = useState(false)
    const [categoryFilterOptions, setCategoryFilterOptions] = useState([])
    const [filterSelected, setFilterSelected] = useState(null)
    const refPopupFilter = useRef(null)
    const role = useSelector(state => state.role);


    useEffect(()=> {
        document.addEventListener("mousedown", handleClick, false)
        fetchProductList()
        fetchMarketplace()
        fetchCategory()
        if(role !== 'admin' && role !== 'super admin') {
            history.push('/product')
            return;
        }
    },[])
    
    function handleClick(e) {
        if(refPopupFilter.current && !refPopupFilter.current.contains(e.target)) {
            setOpenPopupFilter(false)
        }
    }

    function fetchMarketplace() {
        axios.get('/marketplace')
            .then(({data}) => {
                let options = []
                data.map(el => {
                    options.push({
                        label: el.name,
                        value: el._id
                    })
                })
                setOptionsMarketplace(options)
            })
            .catch(err => toast.error('Get data marketplace & reseller error'))
    }

    function getProductDetails(item, isBundle = false) {
        if(isBundle) {
            if(bundle && bundle.find(product => product._id === item._id)){
                return;
            }
            setIsLoading(true)
            if(item.stock <= 0) {
                toast.error('product is out of stock')
                setIsLoading(false)
                return;
            }else if (item.stock <= 15) {
                toast.warn(`stock product is only ${item.stock}`)
            }
            
            if(!bundle) {
                setBundle([
                    {...item,
                        quantity: 1,
                        // price: 0
                    }
                ])
            }else {
                setBundle([...bundle ,
                    {...item,
                        quantity: 1,
                        // price: 0
                    }
                ])
            }
            setIsLoading(false)
            // axios(`/product/${itemId}`)
            // .then(({data}) => {
            // })
            // .catch(err => toast.error('Load a product error'))
        }else {
            if(cart && cart.find(product => product._id === item._id)){
                return;
            }
            setIsLoading(true)
            if(item.stock <= 0) {
                toast.error('product is out of stock')
                setIsLoading(false)
                return;
            }else if (item.stock <= 15) {
                toast.warn(`stock product is only ${item.stock}`)
            }
            
            if(!cart) {
                setCart([
                    {...item,
                        subTotalPrice: item.price * 1,
                        quantity: 1,
                        discount: 0,
                    }
                ])
            }else {
                setCart([...cart ,
                    {...item,
                        subTotalPrice: item.price * 1,
                        quantity: 1,
                        discount: 0,
                    }
                ])
            }
            setIsLoading(false)
            // axios(`/product/${item._id)}`)
            // .then(({data}) => {
            // })
            // .catch(err => toast.error('Load a product error'))
        }
    }

    function fetchCategory() {
        axios.get('/categoryBasic')
        .then(({data}) => {
            setCategoryFilterOptions(data)
        })
        .catch(err => toast.error('Fetch data catergory for filter error'))
    }

    function refreshData() {
        fetchProductList()
    }

    function fetchProductList() {
        setIsLoadingFetchProduct(true)
        axios.get('/product')
        .then(({data}) => {
            setIsLoadingFetchProduct(false)
            setItems(data)
            setMainItems(data)
        })
        .catch(err => {
            setIsLoadingFetchProduct(false)
            toast.error('Load product list error')
        })
    }
    function handleClickProduct(item) {
        getProductDetails(item)
    }

    function handleAddBundle(e, item){
        e.stopPropagation()
        getProductDetails(item, true)
    }

    function handleChangeFilterCategory(categoryId) {
        if(categoryId === filterSelected) {
            setFilterSelected(null)
            setItems(mainItems)
        }else {
            setFilterSelected(categoryId)
            handleFilter(categoryId)
        }
    }

    function handleFilter(categoryId) {
        setItems(items.map(item => {
                if(item.category._id != categoryId) {
                    return {
                        ...item,
                        show: false
                    }
                }else {
                    return item
                }
        }))
        setOpenPopupFilter(false)
    }


    return (
        <div>
            <div className="header header-product">
                <Search list={items} setList={setItems}/>
                <div className="header-wrapper-button">
                    <button className="button button-primary button-add-product button-refresh-product" onClick={refreshData}>
                        Refresh data
                        {
                            isLoadingFetchProduct && <div className="loadingio-spinner-spinner-nrmdiyh6l"><div className="ldio-pjthvymcn1n">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div></div>
                        }
                    </button>
                    <div className="pos-relative">
                        <ButtonFilter selected={filterSelected} className={`button button-regular button-filter-product button-add-product`} onClick={() => setOpenPopupFilter(!openPopupFilter)}>
                            Filter
                            <i class="fa fa-filter fs-12"></i>
                        </ButtonFilter>
                        {
                            openPopupFilter && 
                                <div ref={refPopupFilter} className="popup-filter">
                                    <p>Category</p>
                                    {
                                        categoryFilterOptions.map(category => {
                                            return (
                                                <div className="mt-5 cursor-pointer wrapper-filter-category" key={category._id}>
                                                    <input 
                                                        type="checkbox" 
                                                        id={`filter-category-${category._id}`}
                                                        className="cursor-pointer"
                                                        name="filter-category"
                                                        onChange={() => handleChangeFilterCategory(category._id)}
                                                        checked={filterSelected == category._id}
                                                    />
                                                    <label className="cursor-pointer" htmlFor={`filter-category-${category._id}`}>{category.label}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="body-sales">
                <WrapperItems resize={cart} className="wrapper-items">
                    {
                        isLoadingFetchProduct ?
                            <>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                                <div style={{marginRight: '10px', marginBottom: '10px' }}>
                                    <Skeleton  height={320} width={250} />
                                </div>
                            </> 
                        :
                            items.map((item) => {
                                return (
                                    <React.Fragment key={item._id}>
                                        {
                                            item.show && (
                                                <Card 
                                                    className={`card-item`}
                                                    active={cart && cart.find(product => product._id === item._id)}
                                                    onClick={()=>handleClickProduct(item)} 
                                                >
                                                    <img className="image-product-n" alt={item.name} src={`https://api-heylook.nabiladapse.com/images/${item && item.image}`}/>
                                                    <b className="item-product-name">{item.name}</b>
                                                    <span className="text-grey">{generateSku(item)}</span>
                                                    <span className="item-category"><i className="fa fa-tag"></i>{item.category && item.category.label}</span>
                                                    <span className="item-category"><i className="fa fa-tint"></i>{item.color.label}</span>
                                                    <span className="item-category item-size">{item.size.label}</span>
                                                    <span className="item-category item-stock">stock: {convertnumber(item.stock)}</span>
                                                    <span className="item-price">Rp. {convertnumber(item.price)}</span>
                                                    {
                                                        cart &&  <BundleButton active={bundle && bundle.find(product => product._id === item._id)} onClick={(e) => handleAddBundle(e, item)} className="item-bundle">+ Bundle</BundleButton>
                                                    }
                                                </Card>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                    }
                </WrapperItems>
                {
                    cart &&  <SideBar fetchProductList={fetchProductList} optionsMarketplace={optionsMarketplace} isLoading={isLoading} cart={cart} setCart={setCart} bundle={bundle} setBundle={setBundle} items={items} setItems={setItems}/>
                }
            </div>
        </div>
    )
}

export default Sales;