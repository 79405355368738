import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Switch,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import './index.css'
import axios from "axios"
import {toast} from 'react-toastify'
import Summary from './summary'
import SalesTransaction from './salesTransaction'
import SalesByProduct from './salesByProduct'
import DailyProfit from './dailyProfit'
import ProductProfit from './productProfit'
import DailySales from './dailySales'
import CategorySales from './categorySales'
import MarketplaceSales from './marketplaceSales'
import CardList from './cardList'
import PrivateRoute from '../../PrivateRoute'
import ReactExport from "react-export-excel";
import moment from 'moment'
import Moment from 'moment-timezone'
import Modal  from 'react-modal';
import Popup from '../../components/Notification/Popup';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const today = new Date()
const getIndoDate = Moment.tz(today, "Asia/Jakarta");
const dateIndo = getIndoDate.format()

const customStylesModalUserDetail = {
    content : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        minWidth: '400px',
        marginRight : '-50%',
        transform : 'translate(-50%, -50%)',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(246 246 246)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        zIndex: 2,
    }
};

function Report() {

    const role = useSelector(state => state.role);
    const userId = useSelector(state => state.userId);
    const lastClearDatabase = useSelector(state => state.lastClearDatabase);
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true);
    let { path, url } = useRouteMatch();
    const [dataSetExcel, setDataSetExcel] = useState(null)
    const [openModalClearDatabase, setOpenModalClearDatabase] = useState(false)
    const [lastClearDatabaseHistory, setLastClearDatabaseHistory] = useState(lastClearDatabase)
    const [loadingClearDatabase, setLoadingClearDatabase] = useState(false)

    useEffect(()=> {
        if(role !== 'admin' && role !== 'super admin') {
            history.push('/product')
            return;
        }
        
    },[])

    function handleClearDatabase() {
        setLoadingClearDatabase(true)
        let dateNow =  dateIndo
        let threeMonthPasted = moment(dateIndo).subtract(3, 'months').toDate()
        let firstDayOfThreeMonthPasted = moment(threeMonthPasted).startOf('month')
        let twoMonthAfter = moment(firstDayOfThreeMonthPasted).add(2, 'months').toDate()
        let lastDayOfTwoMonthAfter = moment(twoMonthAfter).endOf('month')
        axios.delete(`/transaction/clearDatabase?userId=${userId}&dateNow=${moment(dateNow).format()}&startDate=${moment(firstDayOfThreeMonthPasted).format()}&endDate=${moment(lastDayOfTwoMonthAfter).format()}`)
        .then(({data}) => {
            toast.success('Clear Database Success')
            setOpenModalClearDatabase(false)
            setLastClearDatabaseHistory(data.lastClearDatabase)
        })
        .catch(err => {
            setLoadingClearDatabase(false)
            toast.error('Clear Database Failed')})
    }
    
    return (
        <div>
            <div className="header d-flex header--report">
                <div className="breadcump--report">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-check-alt" className="fa" role="img" viewBox="0 0 640 512"><title>Report</title><path fill="currentColor" d="M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zM176 327.88V344c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V152c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07zM416 312c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16zm160 0c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h272c4.42 0 8 3.58 8 8v16z"></path></svg> &nbsp; <span className="cursor-pointer" onClick={()=> history.push('/report')}> Report </span> &nbsp; <i style={{fontSize: '14px', height: '14px', width: '14px' }} className="fa fa-chevron-right"></i>
                    <div>{history.location.pathname.split('/')[2]}</div>
                    {
                        history.location.pathname.split('/')[2] == undefined && (
                            <div style={{marginLeft: 'auto'}}>
                                <button 
                                    style={{cursor: 'pointer', backgroundColor: '#fafbfd', borderRadius: '3px', color: '#ee2525'}}
                                    onClick={()=> setOpenModalClearDatabase(true)}
                                >
                                        <i class="fas fa-database" style={{fontSize: '14px', width: '14px', height: '14px'}}></i> CLEAR 
                                </button>
                            </div>
                        )
                    }
                    {
                        history.location.pathname.split('/')[2] == 'transaksi-penjualan' && (
                            <div style={{marginLeft: 'auto'}}>
                                {
                                    dataSetExcel ? (
                                        <ExcelFile element={<button style={{ backgroundColor: '#fafbfd', borderRadius: '3px'}}><i class="far fa-file-excel" style={{color: '#57df5a'}}></i> excel</button>}>
                                            <ExcelSheet data={dataSetExcel} name="data transaksi penjualan">
                                                <ExcelColumn label="Tanggal" value="date"/>
                                                <ExcelColumn label="Nama Barang" value="productName"/>
                                                <ExcelColumn label="Category" value="productCategory"/>
                                                <ExcelColumn label="Color" value="productColor"/>
                                                <ExcelColumn label="Size" value="productSize"/>
                                                <ExcelColumn label="Harga (Rp)" value="productPrice"/>
                                                <ExcelColumn label="Terjual" value="soldQuantity"/>
                                                <ExcelColumn label="Subtotal (Rp)" value="subTotalPrice"/>
                                                <ExcelColumn label="Discount %" value="discount"/>
                                                <ExcelColumn label="After Discount (Rp)" value="afterDiscount"/>
                                                <ExcelColumn label="Sold From" value="soldFrom"/>
                                            </ExcelSheet>
                                        </ExcelFile>
                                    )  : <button disabled style={{ backgroundColor: '#fafbfd', cursor: 'not-allowed', borderRadius: '3px', color: 'rgb(154 154 154)'}}><i class="far fa-file-excel"></i> excel</button>
                                }
                            </div>
                        ) 
                    }
                </div>
            </div>
            <div className="body">
                <Switch>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} exact path="/report">
                        <CardList />
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/ringkasan`}>
                        <Summary />
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/transaksi-penjualan`}>
                        <SalesTransaction dataSetExcel={dataSetExcel} setDataSetExcel={setDataSetExcel} />
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/penjualan-produk`}>
                        <SalesByProduct />
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/omset-harian`}>
                        <DailyProfit/>
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/omset-produk`}>
                        <ProductProfit />
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/penjualan-harian`}>
                        <DailySales />
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/penjualan-perkategori`}>
                        <CategorySales/>
                    </PrivateRoute>
                    <PrivateRoute isLoading={isLoading} setIsLoading={setIsLoading} path={`${path}/penjualan-permarketplace`}>
                        <MarketplaceSales />
                    </PrivateRoute>
                </Switch>
            </div>
            <Modal
                isOpen={openModalClearDatabase}
                onRequestClose={()=> setOpenModalClearDatabase(false)}
                style={customStylesModalUserDetail}
            >
                {
                    lastClearDatabaseHistory ? 
                        <span style={{display: 'block', marginBottom: '20px', fontWeight: '500'}}>Last Clear Database : {moment(lastClearDatabaseHistory.split('T')[0] + 'T00:00:00+07:00').format('MMMM Do YYYY')}</span>
                    :
                        <span style={{display: 'block', marginBottom: '20px', fontWeight: '500'}}>Last Clear Database: NEVER</span>

                }
                <button className="button button-primary w-100pc" onClick={handleClearDatabase}>Clear Database Now</button>
            </Modal>
            {loadingClearDatabase && <Popup text={'Clear Database'}/>}
        </div>
    )
}
export default Report