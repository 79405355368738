const initialState = {
    isAuth: false,
    name: '',
    role: '',
    userId: '',
    lastClearDatabase: null,
    userAccess: [],
    pageActive: '/',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_AUTH":
            return {
                ...state,
                isAuth: action.data,
            };
        case "SET_DATA_USER":
            return {
                ...state,
                name: action.data.name,
                role: action.data.role,
                userId: action.data.userId,
                lastClearDatabase: action.data.lastClearDatabase,
                userAccess: action.data.userAccess,
            };
        case "SET_PAGE_ACTIVE":
            return {
                ...state,
                pageActive: action.data,
            };
        default:
            return state;
    }
};
