import React, {useState, useEffect}from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import {
    setAuth, 
    setDataUser
} from './store/actions'
import {
    Route,
    Redirect,
} from "react-router-dom";
import styled from 'styled-components'

const WrapperLoader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`

function PrivateRoute ({ children, isLoading, setIsLoading, ...rest }) {
    const isAuth = useSelector(state => state.isAuth);
    const dispatch = useDispatch()

    useEffect(() => {
        if(!isAuth) fetchAuth();
    }, []);

    const fetchAuth = async () => {
        await axios.post('/authenticate',{}, { token: localStorage.getItem('token') })
        .then(({data}) => {
            axios.defaults.headers.common['username'] = data.name
            dispatch(setDataUser(data))
            dispatch(setAuth(true))
            setIsLoading(false)
        })
        .catch(err => {
            setIsLoading(false)
            dispatch(setAuth(false))
        })
    }

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuth ? (
                    children
                ) : isLoading ? 
                <WrapperLoader>
                    <div className="loadingio-spinner-wedges-w3791ts7qvh"><div className="ldio-up3jaymtcc">
                    <div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>
                    </div></div> 
                </WrapperLoader>
                    : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );
};
export default PrivateRoute;