import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    useLocation,
} from "react-router-dom";

function Notification() {
    
    const role = useSelector(state => state.role);
    const history = useHistory()

    useEffect(()=> {
        if(role !== 'admin' && role !== 'super admin') {
            history.push('/product')
            return;
        }
    },[])
    return (
        <div>Notification</div>
    )
}
export default Notification