import React, {useEffect, useState, useRef} from 'react';
import './index.css'
import Chart from 'chart.js';
import moment from 'moment'
import convertnumber from '../../helpers/convertnumber'

function GraphMonth({type, data, marketplace}) {

    const chartRef = useRef()
    const [instance, setInstance] = useState(null)

    useEffect(()=> {
        if(data == null) {
            return;
        }
        if(instance !== null) {
            instance.destroy()
        }
        var ctx = chartRef.current.getContext('2d');
        let tempLabels = []
        let tempData = []
        Object.keys(data).map((key) => {
            tempLabels.push(key)
            tempData.push(data[key].sales)
        })
        setInstance(new Chart(ctx, {
            type: 'line',
            data: {
                labels: tempLabels,
                datasets: [{
                    label: 'Rp',
                    data: tempData,
                    backgroundColor: [
                        '#fdc80b',
                    ],
                    borderColor: [
                        '#ff9c00',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#fafbfd',
                            color: '#fafbfd',
                        },
                        stacked: true,
                        gridLines: {
                            color: '#fafbfd',
                            zeroLineColor: "#fafbfd"
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            callback: function(label) {
                                if (/\s/.test(label)) {
                                    return label.split(" ");
                                }else{
                                    return label;
                                }              
                            },
                            color: '#fafbfd',
                            fontColor: '#fafbfd'
                        },
                    }]
                },
                legend: {
                    display: false
                },
                tooltips:{
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = convertnumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            return 'Rp' + label
                        }
                    }
                },
                maintainAspectRatio: false,
            }
        }))
    },[data])

    return (
        <div className={`card--graph-bar d-flex flex-col`}>
        <h4>Grafik {type} {moment().format('MMMM')} ({`${marketplace.name}`})</h4>
        <div style={{height: '100%'}}>
            <canvas ref={chartRef}></canvas>
        </div>
    </div>
    )
}

export default GraphMonth