import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Skeleton from "react-loading-skeleton";

const TRCustom = styled.tr`
    background-color: ${({tag}) => tag == 'color' ? 'rgb(206 206 206)' : tag == 'size' ? 'rgb(171 171 171)' : 'rgb(237 237 237)'}
`
const ButtonFilter = styled.button`
    ${({selected}) => selected && `
        border-radius: 5px;
        background: #384f59;
        color: white;
        font-weight: bold;
        padding: 0 10px;
        &:hover {
            background: #5d66a1;
        }
    `}
`

function InfoVariant({ newVariantData, setNewVariantData, setIsOpenModal, setEditVariantData, updateVariantData, setUpdateVariantData}) {

    const [categoryList, setCategoryList] = useState([])
    const [colorList, setColorList] = useState([])
    const [sizeList, setSizeList] = useState([])
    const [openPopupFilter, setOpenPopupFilter] = useState(false)
    const [filterSelected, setFilterSelected] = useState(null)
    const refPopupFilter = useRef(null)
    const [loadingFetchDataCategory, setLoadingFetchDataCategory] = useState(false)
    const [loadingFetchDataColor, setLoadingFetchDataColor] = useState(false)
    const [loadingFetchDataSize, setLoadingFetchDataSize] = useState(false)
    
    useEffect(()=>{
        document.addEventListener("mousedown", handleClick, false)
        fetchCategory()
        fetchColor()
        fetchSize()
    },[])

    useEffect(()=>{
        if(newVariantData) {
            if(newVariantData.type === 'category'){
                setCategoryList([...categoryList, newVariantData.data])
            }else if(newVariantData.type === 'color'){
                setColorList([...colorList, newVariantData.data])
            }else if(newVariantData.type === 'size'){
                setSizeList([...sizeList, newVariantData.data])
            }
            setNewVariantData(null)
        }else if(updateVariantData) {
            if(updateVariantData.type === 'category') {
                setCategoryList(categoryList.map(category => {
                    if(category._id == updateVariantData.data._id) {
                        return updateVariantData.data
                    }else {
                        return category
                    }
                }))
            }else if(updateVariantData.type === 'size') {
                setSizeList(sizeList.map(size => {
                    if(size._id == updateVariantData.data._id) {
                        return updateVariantData.data
                    }else {
                        return size
                    }
                }))
            }else if(updateVariantData.type === 'color') {
                setColorList(colorList.map(color => {
                    if(color._id == updateVariantData.data._id) {
                        return updateVariantData.data
                    }else {
                        return color
                    }
                }))
            }
            setUpdateVariantData(null)
        }
    },[newVariantData, updateVariantData])

    function handleClick(e) {
        if(refPopupFilter.current && !refPopupFilter.current.contains(e.target)) {
            setOpenPopupFilter(false)
        }
    }

    function fetchCategory() {
        setLoadingFetchDataCategory(true)
        axios.get('/categoryBasic')
        .then(({data}) => {
            setLoadingFetchDataCategory(false)
            setCategoryList(data)
        })
        .catch(err => {
            setLoadingFetchDataCategory(false)
            toast.error('fetch category variant failed')
        })
    }
    function fetchColor() {
        setLoadingFetchDataColor(true)
        axios.get('/colorBasic')
        .then(({data}) => {
            setLoadingFetchDataColor(false)
            setColorList(data)
        })
        .catch(err => {
            setLoadingFetchDataColor(false)
            toast.error('fetch category variant failed')
        })
    }
    function fetchSize() {
        setLoadingFetchDataSize(true)
        axios.get('/sizeBasic')
        .then(({data}) => {
            setLoadingFetchDataSize(false)
            setSizeList(data)
        })
        .catch(err => {
            setLoadingFetchDataSize(false)
            toast.error('fetch category variant failed')
        })
    }

    function handleDeleteVariant(variant, variantId) {
        axios.delete(`/${variant}/${variantId}`)
        .then(data => {
            if(variant == 'categoryBasic') {
                setCategoryList(categoryList.filter(el => el._id != variantId))
            }else if(variant == 'colorBasic'){
                setColorList(colorList.filter(el => el._id != variantId))
            }else if(variant == 'sizeBasic'){
                setSizeList(sizeList.filter(el => el._id != variantId))
            }
            toast.success('Delete success')
        })
        .catch(err => toast.error('Delete failed'))
    }

    function handleChangeFilterCategory(value) {
        if(value === filterSelected) {
            setFilterSelected(null)
        }else {
            setFilterSelected(value)
            setOpenPopupFilter(false)
        }
    }

    return (
        <div className="wrapper-variant">
            <div className="wrapper-marketplace-content-header">
                    <h3><i className="fa fa-tint"></i> Variant</h3>
                    <div className="d-flex header-marketplace-wrapper-button">
                        <button className="button button-primary button-add-product button-refresh-product" onClick={() => setIsOpenModal('variant')}>+ADD</button>
                        <div className="pos-relative">
                            <ButtonFilter 
                                selected={filterSelected}
                                className={`button button-regular button-filter-product button-add-product`}
                                onClick={() => setOpenPopupFilter(!openPopupFilter)}>
                                    FILTER
                                    <i className="fa fa-filter fs-12"></i> 
                            </ButtonFilter>
                            {
                                openPopupFilter && 
                                    <div ref={refPopupFilter} className="popup-filter">
                                        <div className="d-block">
                                            <input 
                                                type="checkbox" 
                                                id={`filter-category`}
                                                className="cursor-pointer"
                                                name="filter-category"
                                                onChange={() => handleChangeFilterCategory('category')}
                                                checked={filterSelected == 'category'}
                                            />
                                            <label className="cursor-pointer" htmlFor={`filter-category`}>Category</label>
                                        </div>
                                        <div className="d-block">
                                            <input 
                                                type="checkbox" 
                                                id={`filter-color`}
                                                className="cursor-pointer"
                                                name="filter-category"
                                                onChange={() => handleChangeFilterCategory('color')}
                                                checked={filterSelected == 'color'}
                                            />
                                            <label className="cursor-pointer" htmlFor={`filter-color`}>Color</label>
                                        </div>
                                        <div className="d-block">
                                            <input 
                                                type="checkbox" 
                                                id={`filter-size`}
                                                className="cursor-pointer"
                                                name="filter-category"
                                                onChange={() => handleChangeFilterCategory('size')}
                                                checked={filterSelected == 'size'}
                                            />
                                            <label className="cursor-pointer" htmlFor={`filter-size`}>Size</label>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            <div className="table-marketplace">
                    <div className="thead-marketplace">
                        <div className="tr-marketplace">
                            <span className="td-marketplace">Name</span>
                            <span className="td-marketplace">SKU-variant</span>
                            <span className="td-marketplace">Tag</span>
                            <span className="td-marketplace">Action</span>
                        </div>
                    </div>
                    <div className="tbody-variant">
                        {
                            loadingFetchDataCategory ? 
                                <Skeleton count={5} height={35}/>
                            :
                                (filterSelected == null || filterSelected == 'category') && categoryList.map(category => {
                                    return (
                                        <TRCustom tag={category.tag} className="tr-marketplace" key={category._id}>
                                            <span className="td-marketplace">{category.label}</span>
                                            <span className="td-marketplace">{category.skuVariant}</span>
                                            <span className="td-marketplace">{category.tag}</span>
                                            <span className="td-marketplace">
                                                <span className="hover-green text-blue" 
                                                    onClick={() => setEditVariantData({data: category, type: 'category'})}
                                                >
                                                    Edit</span> 
                                                    {/* | <span className="hover-red text-blue" onClick={() => handleDeleteVariant('categoryBasic', category._id)}>Delete</span>  */}
                                            </span>
                                        </TRCustom>
                                    )
                                })
                        }
                        {
                            loadingFetchDataColor ? 
                                <Skeleton count={5} height={35}/>
                            :
                            (filterSelected == null || filterSelected == 'color') && colorList.map(color => {
                                return (
                                    <TRCustom tag={color.tag} className="tr-marketplace" key={color._id}>
                                            <span className="td-marketplace">{color.label}</span>
                                            <span className="td-marketplace">{color.skuVariant}</span>
                                            <span className="td-marketplace">{color.tag}</span>
                                            <span className="td-marketplace">
                                                <span className="hover-green text-blue" 
                                                    onClick={() => setEditVariantData({data: color, type: 'color'})}
                                                >
                                                    Edit</span> 
                                                    {/* | <span className="hover-red text-blue" onClick={() => handleDeleteVariant('colorBasic', color._id)}>Delete</span>  */}
                                            </span>
                                        </TRCustom>
                                    )
                                })
                            }
                        {
                            loadingFetchDataSize ? 
                                <Skeleton count={5} height={35}/>
                            :
                                (filterSelected == null || filterSelected == 'size') && sizeList.map(size => {
                                    return (
                                        <TRCustom tag={size.tag} className="tr-marketplace" key={size._id}>
                                            <span className="td-marketplace">{size.label}</span>
                                            <span className="td-marketplace">{size.skuVariant}</span>
                                            <span className="td-marketplace">{size.tag}</span>
                                            <span className="td-marketplace">
                                                <span className="hover-green text-blue" 
                                                    onClick={() => setEditVariantData({data: size, type: 'size'})}
                                                >
                                                    Edit</span> 
                                                    {/* | <span className="hover-red text-blue" onClick={() => handleDeleteVariant('sizeBasic', size._id)}>Delete</span>  */}
                                            </span>
                                        </TRCustom>
                                    )
                                })
                        }
                    </div>
                </div>
        </div>
    )
}
export default InfoVariant;