import React, {useEffect} from 'react';
import './index.css'
import logo from '../../assets/logo.png'
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import {
    setAuth, 
    setDataUser} from '../../store/actions'
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {

    const history = useHistory()
    const location = useLocation()
    const role = useSelector(state => state.role);
    const dispatch = useDispatch()
    const { from } = location.state || { from: { pathname: "/" } }

    function handleSubmit(e) {
        e.preventDefault()
        axios.post('/user/login', {
            name: e.target.elements.username.value.toLowerCase(),
            password: e.target.elements.password.value.toLowerCase()
        })
        .then(({data})=> {
            axios.defaults.headers.common['username'] = data.name
            dispatch(setAuth(true))
            dispatch(setDataUser(data))
            localStorage.setItem('token', data.token)
            if(data.role == 'admin' || data.role == 'super admin'){
                dispatch({
                    type: 'SET_PAGE_ACTIVE',
                    data: '/'
                })
                history.push('/');
            }else {
                dispatch({
                    type: 'SET_PAGE_ACTIVE',
                    data: '/product'
                })
                history.push('/product');
            }
        })
        .catch(err => {
            toast.error('Username/Password incorrect')
        })
    }

    return (
        <div className="wrapper-login-form">
            <form className="form-login" onSubmit={handleSubmit}>
                <img src={logo}/>
                <label htmlFor="input-login-username"> USERNAME </label>
                <input autoFocus autoComplete="off" type="text" name="username" id="input-login-username"/>
                <label htmlFor="input-login-password"> PASSWORD </label>
                <input autoComplete="off" type="password" id="input-login-password" name="password"/>
                <button type="submit">LOGIN</button>
            </form>
        </div>
    )
}
export default Login