import React, {useEffect, useState} from 'react';
import './index.css'
import { ReactComponent as Report } from '../../assets/money-check-alt-solid.svg'
import Cashier from '../../assets/machine-icon.png'
import {
    useHistory,
} from "react-router-dom";
import logo from '../../assets/logo.png'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";

const WrapperIcon = styled.div`
    border-right: ${({isactive}) => isactive == 'active' && '3px solid var(--main-color);' };
`

function Navbar() {

    const history = useHistory()
    const role = useSelector(state => state.role);
    const isAuth = useSelector(state => state.isAuth);
    const pageActive = useSelector(state => state.pageActive);
    const dispatch = useDispatch()
    const [isShowSideNav, setIsShowSideNav] = useState(false)
    // const [pageActive, setPageActive] = useState('/')

    useEffect(()=> {
        if(history.location.pathname == '/login') {
            return;
        }
        dispatch({
            type: 'SET_PAGE_ACTIVE',
            data: history.location.pathname
        })
        // setPageActive(history.location.pathname)
    },[])
    
    function handleChangeRoute(route) {
        history.push(route)
        dispatch({
            type: 'SET_PAGE_ACTIVE',
            data: route
        })
        setIsShowSideNav(false)
        // setPageActive(route)
    }

    return (
        <>
            {
                !isShowSideNav ? 
                    <i className="fa fa-bars icon-side-nav" style={{color: '#051E29'}} onClick={()=> setIsShowSideNav(true)}></i>
                :
                <div className="side-nav">
                    <i title="close" className="fa fa-times text-black" onClick={()=> setIsShowSideNav(false)}></i>
                    <h1 className="logo">
                        <img style={{height: '100%'}} src={logo}/>
                    </h1>
                    {
                        (role == 'super admin' || role == 'admin') && (
                            <WrapperIcon 
                                isactive={pageActive == '/' ? 'active' : undefined} 
                                className="wrapper__icon"
                                onClick={()=> handleChangeRoute('/')}
                            >
                                    <i className="fa fa-home"  title="Home"></i>
                                    &nbsp;
                                    <b>Home</b>
                            </WrapperIcon>
                        )
                    }
                    {
                        role == 'super admin' && (
                            <WrapperIcon 
                                isactive={pageActive == '/user' ? 'active' : undefined}
                                className="wrapper__icon"
                                onClick={()=> handleChangeRoute('/user')}
                            >
                                    <i className="fa fa-user" title="User"></i>
                                    &nbsp;
                                    <b>User</b>
                            </WrapperIcon>
                        )
                    }
                    <WrapperIcon
                        isactive={pageActive == '/product' ? 'active' : undefined} 
                        className="wrapper__icon"
                        onClick={()=> handleChangeRoute('/product')}
                    >
                            <i className="fa fa-briefcase" title="Product"></i>
                            &nbsp;
                            <b>Product</b>
                    </WrapperIcon>
                    {
                        (role == 'super admin' || role == 'admin') && (
                            <WrapperIcon
                                isactive={pageActive == '/sales' ? 'active' : undefined} 
                                className="wrapper__icon"
                                onClick={()=> handleChangeRoute('/sales')}
                            >
                                <i className="fa fa-tags"  title="Sales"></i>
                                &nbsp;
                                <b>Sale</b>
                            </WrapperIcon>
                        )
                    }
                    {
                        (role == 'super admin'|| role == 'admin') && (
                            <WrapperIcon 
                                isactive={pageActive.includes('report') ? 'active' : undefined}
                                className="wrapper__icon"
                                onClick={()=> handleChangeRoute('/report')}
                            >
                                    <i className="fas fa-file-invoice-dollar" title="Report"></i>
                                    &nbsp;
                                    <b>Report</b>
                            </WrapperIcon>
                        )
                    }
                    {
                        (role == 'super admin' || role == 'admin') && (
                            <WrapperIcon
                                isactive={pageActive == '/information' ? 'active' : undefined} 
                                className="wrapper__icon"
                                onClick={()=> handleChangeRoute('/information')}
                            >
                                <i className="fa fa-info-circle"  title="Information"></i>
                                &nbsp;
                                <b>Information</b>
                            </WrapperIcon>
                        )
                    }
                    <WrapperIcon
                        isactive={pageActive == '/updateStockScanner' ? 'active' : undefined} 
                        className="wrapper__icon"
                        onClick={()=> handleChangeRoute('/updateStockScanner')}
                    >
                        <i className="fa fas fa-barcode"  title="Update Stock"></i>
                        &nbsp;
                        <b>Update Stock</b>
                    </WrapperIcon>
                    
                </div>
            }
            
            <div className="navbar">
                <h1 className="logo">
                    <img style={{height: '100%', position: 'absolute', right: 0}} src={logo}/>
                </h1>
                <div className="navbar__curve" />
                <div className="navbar__body">
                        {
                            (role == 'super admin' || role == 'admin') && (
                                <WrapperIcon 
                                    isactive={pageActive == '/' ? 'active' : undefined} 
                                    className="wrapper__icon">
                                        <i className="fa fa-home" onClick={()=> handleChangeRoute('/')} title="Home"></i>
                                </WrapperIcon>
                            )
                        }
                        {
                            (role == 'super admin') && (
                                <WrapperIcon 
                                    isactive={pageActive == '/user' ? 'active' : undefined}
                                    className="wrapper__icon">
                                        <i className="fa fa-user" onClick={()=> handleChangeRoute('/user')} title="User"></i>
                                </WrapperIcon>
                            )
                        }
                        <WrapperIcon
                            isactive={pageActive == '/product' ? 'active' : undefined} 
                            className="wrapper__icon">
                                <i className="fa fa-briefcase" onClick={()=> handleChangeRoute('/product')} title="Product"></i>
                        </WrapperIcon>
                        {
                            (role == 'super admin' || role == 'admin') && (
                                <WrapperIcon
                                    isactive={pageActive == '/sales' ? 'active' : undefined} 
                                    className="wrapper__icon">
                                    <i className="fa fa-tags" onClick={()=> handleChangeRoute('/sales')} title="Sales"></i>
                                </WrapperIcon>
                            )
                        }
                        {
                            (role == 'super admin' || role == 'admin') && (
                                <WrapperIcon 
                                    isactive={pageActive.includes('report') ? 'active' : undefined}
                                    className="wrapper__icon">
                                        <i className="fas fa-file-invoice-dollar" title="Sales" onClick={()=> handleChangeRoute('/report')}></i>
                                </WrapperIcon>
                            )
                        }
                        {
                            (role == 'super admin' || role == 'admin') && (
                                <WrapperIcon
                                    isactive={pageActive == '/information' ? 'active' : undefined} 
                                    className="wrapper__icon"
                                    onClick={()=> handleChangeRoute('/information')}
                                >
                                    <i className="fa fa-info-circle"  title="Information"></i>
                                </WrapperIcon>
                        )}
                        <WrapperIcon
                            isactive={pageActive == '/updateStockScanner' ? 'active' : undefined} 
                            className="wrapper__icon"
                            onClick={()=> handleChangeRoute('/updateStockScanner')}
                        >
                            <i className="fa fas fa-barcode"  title="Update Stock"></i>
                        </WrapperIcon>
                        {
                            (role == 'super admin' || role == 'admin') && (
                                <WrapperIcon
                                    isactive={pageActive == '/ecommerce' ? 'active' : undefined} 
                                    className="wrapper__icon"
                                    onClick={()=> handleChangeRoute('/ecommerce')}
                                >
                                    <i class="fas fa-shopping-cart" title="e-commerce"></i>
                                </WrapperIcon>
                        )}
                        
                </div>
            </div>
        </>
    )
}

export default Navbar