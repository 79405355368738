import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components'
import { useSelector } from "react-redux";
import moment from 'moment'
import convertnumber from '../../helpers/convertnumber'
import generateSku from '../../helpers/generateSku'

const Card = styled.div`
    ${({active}) => active ? `
        box-shadow: 0px 0px 10px 0px #ff9c00;
        top: -3px;
        background: #fafbfd;
        ` : `
        box-shadow: 0px 0px 3px 2px #384f59;
        background: #fafbfd;
        top: -3px;`
    }
`
function ActivityStreamList({ activity, setActivityStreamList }) {

    {/**
        ----------STATE----------
    */}
    const [activityFilter, setActivityFilter] = useState(null)

    {/**
        ----------USEEFFECT----------
    */}

    useEffect(()=> {
        setActivityFilter(activity)
    },[activity])

    {/**
        ----------FUNCTIONALITY----------
    */}
    {/**
        ----------RENDER COMPONENT----------
    */}
    return (
        <>
        {
            activityFilter !== null && (
                <div className="tr-product">
                    <span className="td-l">
                        {moment(activityFilter.created_at).format('MMMM Do YYYY, h:mm a')}
                    </span>
                    <span className="td-m">
                        {activityFilter.action}
                    </span>
                    <span className="td-m">
                        {activityFilter.user}
                    </span>
                    <span className="td-m">
                        {activityFilter.target.product.name} 
                        <br/>
                        <span className="text-grey">{generateSku(activityFilter.target.product)}</span>
                    </span>
                    <span className="td-s">
                        <span className="td-sm p-0-5 fw-500">
                            {activityFilter.target.product.color.label}
                            <span className="d-block bt-grey size">
                                {activityFilter.target.product.size.label}
                            </span>
                        </span>
                    </span>
                    <span className="td-s">
                        {activityFilter.target.product.category.label}
                    </span>
                    <span className="td-s">
                        {activityFilter.target.stock}
                    </span>
                </div>
            )
        }

        {/* ----mobile -version---- */}
        {
            activityFilter !== null && (
                <Card 
                    className={`card-item card-item-product`}
                >
                    <div className="d-flex">
                        <span className="item-category item-stock">{moment(activityFilter.target.created_at).format('MMMM Do YYYY, h:mm a')}</span>
                    </div>
                    <b className="item-product-name">STOCK UPDATED: {activityFilter.target.stock}</b>
                    <div className="d-flex mt-15">
                        <img className="image-product-s" src={`https://apiv1.heylook-dashboard.xyz/images/${activityFilter.target.product.image}`} alt={activityFilter.target.product.name.slice(0,8)}/>
                        <b className="item-product-name">{activityFilter.target.product.name}</b>
                    </div>
                    <span className="text-grey">{generateSku(activityFilter.target.product)}</span>
                    <div className="d-flex">
                        <span className="item-category"><i className="fa fa-tag"></i>{activityFilter.target.product.category.label}</span>
                        <span className="item-category ml-10"><i className="fa fa-tint"></i>{activityFilter.target.product.color.label}</span>
                    </div>
                </Card>
            )
        }
        </>
    )
}
export default ActivityStreamList