import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    setAuth, 
    setDataUser} from '../../store/actions'
import iconLight from '../../assets/user-light.png'

function Logout() {

    const name = useSelector(state => state.name);
    const role = useSelector(state => state.role);
    const dispatch = useDispatch()

    function handleLogout() {
        dispatch(setDataUser({
            name: '',
            role: ''
        }))
        localStorage.clear()
        dispatch(setAuth(false))
    }
    return (
        <div className="wrapper-logout">
            <span className={`display-name`}>
                <i class="fas fa-user" style={{fontSize: '16px'}}></i>
                {name}
            </span>
            <span 
                className={`button-logout`}
                onClick={handleLogout}
            >Logout</span>
        </div>
    )
}

export default Logout;