import React, {useEffect, useState} from 'react';
import './index.css'
import {toast} from 'react-toastify'
import { useSelector } from "react-redux";
import axios from 'axios'
import moment from 'moment-timezone'
import Popup from '../../components/Notification/Popup';

function UpdateStockScanner(){

    const role = useSelector(state => state.role);
    const userAccess = useSelector(state => state.userAccess);
    const [isLoadingFetchProduct, setIsLoadingFetchProduct] = useState(false)
    const [productList, setProductList] = useState([])
    const [resultScanningList, setResultScanningList] = useState([])
    const [input, setInput] = useState('')
    const [errorFetchProduct, setErrorFetchProduct] = useState(false)
    const [focused, setFocused] = useState(false)
    const [loadingSubmitStock, setLoadingSubmitStock] = useState(false)
    // const [query, setQuery] = useState('')
    const [groupingResult, setGroupingResult] = useState({})

    useEffect(()=> {
        fetchProductList()
    },[])

    useEffect(()=> {
        let tempObj = {}
        resultScanningList.map(product => {
            if(!tempObj[product._id]) {
                tempObj[product._id] = {
                    product,
                    total: 1
                }
            }else {
                tempObj[product._id].total += 1
            }
        })
        setGroupingResult(tempObj)
    },[resultScanningList])

    useEffect(() => {
        if(input.length >= 8){
            const timeOutId = setTimeout(() => handleChangeInput(input), 2200);
            return () => clearTimeout(timeOutId);
        }
      }, [input]);
    

    function fetchProductList() {
        setIsLoadingFetchProduct(true)
        setErrorFetchProduct(false)
        if(role !== 'admin' && role !== 'super admin') {
            axios.post('/product/findbyUserAccess', {userAccess})
            .then(({data}) =>{
                setIsLoadingFetchProduct(false)
                setProductList(data)
            })
            .catch(err => {
                setIsLoadingFetchProduct(false)
                toast.success('Load product list failed, check your connection')
            })
        }else {
            axios.get('/product')
            .then(({data}) =>{
                setIsLoadingFetchProduct(false)
                setProductList(data)
            })
            .catch(err =>{ 
                setErrorFetchProduct(true)
                setIsLoadingFetchProduct(false)
                toast.success('Load product list failed, check your connection')
            })
        }
    }

    function handleChangeInput(input) {
        // let input = e.target.value
        // setInput(input)
        // let result = [...resultScanningList]
        let result = []
        if(input.includes('-') && input.length >= 8){
            // Search by sku
            productList.map(product => {
                let splitName = product.name.split(' ')
                let skuName = ''
                if(splitName[1] != undefined && splitName[1] != '') {
                    skuName = product.name.split(' ')[1].slice(0,3)
                }else {
                    skuName = product.name.slice(0,3)
                }
                let sku = `${product.category.skuVariant}-${skuName}${product.size.skuVariant}${product.color.skuVariant}`
                if(sku.toUpperCase().includes(input.toUpperCase())) {
                    result.push(product)
                }
            })
        }
        if(result.length === 1){
            setInput('')
            setResultScanningList(resultScanningList.concat(result))
        }

    }

    async function handleSubmit(){
        if(loadingSubmitStock){
            return;
        }
        setLoadingSubmitStock(true)
        let today = new Date()
        let getIndoDate = moment.tz(today, "Asia/Jakarta");
        let dateIndo = getIndoDate.format()
        for (const product in resultScanningList) {
            let promise = await new Promise(function (res, rej){
                axios.put(`/product/bulkStock/${resultScanningList[product]._id}`, {
                    stock: 1,
                    created_at: dateIndo,
                    product
                })
                .then(data => {
                    res('success')
                })
                .catch(err => {
                    getLastindex(product-1)
                    rej(product)
                })
            })
        }
        setResultScanningList([])
        setLoadingSubmitStock(false)
    }

    function getLastindex(lastIndexSuccess) {
        toast.error('error detected, Please check your connection')
        setResultScanningList(resultScanningList.filter((product, indexProduct) => indexProduct > lastIndexSuccess))
        setLoadingSubmitStock(false)

    }

    function handleDelete(indexItem){
        let result = [...resultScanningList]
        result.splice(indexItem, 1)
        setResultScanningList(result)
    }

    return(
        <div>
            {loadingSubmitStock && <Popup />}
            <div className="header header-information" />
            <div className="body-updateStockScanner">
                <button onClick={handleSubmit} className="button button-primary">SUBMIT STOCK</button>
                <h4 style={{color: `${isLoadingFetchProduct || errorFetchProduct  ? 'red' : productList.length > 0 ? 'green' : 'red'}`}}>Status 1 (Load Product) : {isLoadingFetchProduct ? 'Load data ...' : errorFetchProduct ? 'Load data failed, check connection' : productList.length > 0 ? 'ready' : 'No data ...'}</h4>
                <h4 style={{color: `${focused ? 'green' : 'red'}`}}>Status 2 (Scanner) : {focused ? 'ready' : 'Click on input'}</h4>
                <label>
                    Scanner Input:
                    &nbsp;
                    <input 
                        autoFocus
                        placeholder="Click here if status 2 is not active" 
                        // onChange={handleChangeInput} 
                        onChange={(e) => setInput(e.target.value)} 
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        value={input}/>
                </label>
                <div className="wrapper-output-list d-flex justify-between">
                    <div>
                        <h3>Output List:</h3>
                        {
                            resultScanningList.map((item, indexItem) => {
                                return (
                                    <p key={indexItem}>{indexItem+1}. {item.name} &nbsp; <span style={{color: 'red', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleDelete(indexItem)}>delete</span></p>
                                )
                            })
                        }
                    </div>
                    {
                        Object.keys(groupingResult)[0] && (
                            <>
                                <div className="wrapper-resume">
                                    {
                                        Object.keys(groupingResult).map(item => {
                                            return (
                                                <p>{groupingResult[item].product.name}: <b> {groupingResult[item].total} </b></p>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateStockScanner;