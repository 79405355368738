import React, {useEffect, useState, useRef} from 'react';
import './index.css'
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import Sales from './sales'
import GraphMonth from './graphMonth'
import HighestSale from './highestSale'
import HighestProductSale from './highestSale'
import RememberStock from './rememberStock'
import axios from 'axios'
import {toast} from 'react-toastify'
import styled from 'styled-components'
import moment from 'moment' 
import Skeleton from "react-loading-skeleton";

const ButtonFilter = styled.button`
    ${({selected}) => selected && `
        border-radius: 5px;
        background: #50588b;
        color: white;
        font-weight: bold;
        padding: 0 10px;
        border: 1px solid var(--main-color);
        &:hover {
            background: #5d66a1;
        }
    `}
`

function Home () {

    const role = useSelector(state => state.role);
    const history = useHistory()
    const today = new Date()
    const getDate = today.toLocaleDateString();
    const getTime = today.toLocaleTimeString();
    const dateIndo = moment(`${getDate} ${getTime}`, 'MM-DD-YYYY HH:mm:ss').format();
    const [dateSelected, setDateSelected] = useState(dateIndo)
    const [dataGraphMonthProfit, setDataGraphMonthProfit] = useState(null)
    const [dataGraphMonthSales, setDataGraphMonthSales] = useState(null)
    const [dataGraphProfit, setDataGraphProfit] = useState(null)
    const [dataGraphSales, setDataGraphSales] = useState(null)
    const [dataHighestProduct, setDataHighestProduct] = useState(null)
    const [dataHighestCategory, setDataHighestCategory] = useState(null)
    const [dataHighestMarketplace, setDataHighestMarketplace] = useState(null)
    const [marketplaceFilterSelected, setMarketplaceFilterSelected] = useState({
        _id: 'all',
        name: 'ALL',
        status: 'ALL'
    })
    const [timeFilterSelected, setTimeFilterSelected] = useState('DAILY')
    const [timeFilterOption, setTimeFilterSelectedOption] = useState(['DAILY', 'MONTHLY'])
    const [openPopupFilterMarketplace, setOpenPopupFilterMarketplace] = useState(false)
    const [openPopupFilterTime, setOpenPopupFilterTime] = useState(false)
    const [marketplaceOption, setMarketplaceOption] = useState([])
    const [loadingDataGraphProfit, setLoadingDataGraphProfit] = useState(false)//
    const [loadingDataGraphSales, setLoadingDataGraphSales] = useState(false)//
    const [loadingDataGraphMonthProfit, setLoadingDataGraphMonthProfit] = useState(false)//
    const [loadingDataGraphMonthSales, setLoadingDataGraphMonthSales] = useState(false)//
    const [loadingDataHighestProduct, setLoadingDataHighestProduct] = useState(false)//
    const [loadingDataHighestCategory, setLoadingDataHighestCategory] = useState(false)//
    const [loadingDataHighestMarketplace, setLoadingDataHighestMarketplace] = useState(false)
    const refPopupFilterMarketplace = useRef(null)
    const refPopupFilterTime = useRef(null)

    useEffect(()=> {
        document.addEventListener("mousedown", handleClick, false)
        fetchMarketplace()
        
    },[])

    useEffect(()=> {
        if(role !== 'admin' && role !== 'super admin') {
            history.push('/product')
            return;
        }
        fetchGraphData()
    },[])

    function handleClick(e) {
        if(refPopupFilterMarketplace.current && !refPopupFilterMarketplace.current.contains(e.target)) {
            setOpenPopupFilterMarketplace(false)
        }else if(refPopupFilterTime.current && !refPopupFilterTime.current.contains(e.target)) {
            setOpenPopupFilterTime(false)
        }
    }

    function fetchGraphData() {
        fetchGraphMonthDataProfit(marketplaceFilterSelected._id)
        fetchGraphMonthDataSales(marketplaceFilterSelected._id)
        fetchProfitData(marketplaceFilterSelected._id, timeFilterSelected)
        fetchSalesData(marketplaceFilterSelected._id, timeFilterSelected)
        fetchHighestProductData(marketplaceFilterSelected._id, timeFilterSelected)
        fetchHighestCategoryData(marketplaceFilterSelected._id, timeFilterSelected)
        fetchHighestMarketplaceData(marketplaceFilterSelected._id, timeFilterSelected)
    }

    function fetchMarketplace() {
        axios.get('/marketplace')
        .then(({data}) => {
            let temp = data
            temp.unshift({
                _id: 'all',
                name: 'ALL',
                status: 'ALL'
            })
            setMarketplaceOption(temp)
        })
        .catch(err => toast.error('Fetch Marketplace Failed'))
    }

    function fetchGraphMonthDataProfit(marketplaceFilterSelectedId) {
        setLoadingDataGraphMonthProfit(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss') ;
        axios.get('/transaction/graphMonthProfit', { params: {
            startDate: firstDayOfMonth,
            endDate: lastDayOfMonth,
            marketplaceId: marketplaceFilterSelectedId
        }})
        .then(({data}) => {
            setLoadingDataGraphMonthProfit(false)
            setDataGraphMonthProfit(data)
        })
        .catch((err) => {
            setLoadingDataGraphMonthProfit(false)
            toast.error('get data profit failed')})
    }
    function fetchGraphMonthDataSales(marketplaceFilterSelectedId) {
        setLoadingDataGraphMonthSales(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss') ;
        axios.get('/transaction/graphMonthSales', { params: {
            startDate: firstDayOfMonth,
            endDate: lastDayOfMonth,
            marketplaceId: marketplaceFilterSelectedId
        }})
        .then(({data}) => {
            setLoadingDataGraphMonthSales(false)
            setDataGraphMonthSales(data)
        })
        .catch((err) => {
            setLoadingDataGraphMonthSales(false)
            toast.error('get data profit failed')})
    }

    function fetchProfitData(marketplaceFilterSelectedId, timeSelected) {
        setLoadingDataGraphProfit(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss') ;
        axios.get('/transaction/graphProfit', { params: {
                startDate: timeSelected === 'MONTHLY' ? firstDayOfMonth : dateSelected,
                endDate: timeSelected === 'MONTHLY' ? lastDayOfMonth : dateSelected,
                marketplaceId: marketplaceFilterSelectedId
            }
        })
        .then(({data}) => {
            setLoadingDataGraphProfit(false)
            setDataGraphProfit(data)
        })
        .catch((err) => {
            setLoadingDataGraphProfit(false)
            toast.error('get data profit failed')})
    }

    function fetchSalesData(marketplaceFilterSelectedId, timeSelected) {
        setLoadingDataGraphSales(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss');
        axios.get('/transaction/graphSales', { params: {
            // date: dateSelected,
            startDate: timeSelected === 'MONTHLY' ? firstDayOfMonth : dateSelected,
            endDate: timeSelected === 'MONTHLY' ? lastDayOfMonth : dateSelected,
            marketplaceId: marketplaceFilterSelectedId
        }})
        .then(({data}) => {
            setLoadingDataGraphSales(false)
            setDataGraphSales(data)
        })
        .catch((err) => {
            setLoadingDataGraphSales(false)
            toast.error('get data sales failed')})
    }
    
    function fetchHighestProductData(marketplaceFilterSelectedId, timeSelected) {
        setLoadingDataHighestProduct(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss');
        axios.get('/transaction/highestProductSold', { params: {
            // date: dateSelected,
            startDate: timeSelected === 'MONTHLY' ? firstDayOfMonth : dateSelected,
            endDate: timeSelected === 'MONTHLY' ? lastDayOfMonth : dateSelected,
            marketplaceId: marketplaceFilterSelectedId
        }})
        .then(({data}) => {
            setLoadingDataHighestProduct(false)
            setDataHighestProduct(data)
        })
        .catch((err) => {
            setLoadingDataHighestProduct(false)
            toast.error('get data highest product failed')})
    }
    
    function fetchHighestCategoryData(marketplaceFilterSelectedId, timeSelected) {
        setLoadingDataHighestCategory(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss');
        axios.get('/transaction/highestCategorySold', { params: {
            // date: dateSelected,
            startDate: timeSelected === 'MONTHLY' ? firstDayOfMonth : dateSelected,
            endDate: timeSelected === 'MONTHLY' ? lastDayOfMonth : dateSelected,
            marketplaceId: marketplaceFilterSelectedId
        }})
        .then(({data}) => {
            setLoadingDataHighestCategory(false)
            setDataHighestCategory(data)
        })
        .catch((err) => {
            setLoadingDataHighestCategory(false)
            toast.error('get data highest category failed')})
    }
    
    function fetchHighestMarketplaceData(marketplaceFilterSelectedId, timeSelected) {
        setLoadingDataHighestMarketplace(true)
        const firstDayOfMonth =  moment(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DDTHH:mm:ss');
        const lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DDTHH:mm:ss');
        axios.get('/transaction/highestMarketplaceSold', { params: {
            // date: dateSelected,
            startDate: timeSelected === 'MONTHLY' ? firstDayOfMonth : dateSelected,
            endDate: timeSelected === 'MONTHLY' ? lastDayOfMonth : dateSelected,
            marketplaceId: marketplaceFilterSelectedId
        }})
        .then(({data}) => {
            setLoadingDataHighestMarketplace(false)
            setDataHighestMarketplace(data)
        })
        .catch((err) => {
            setLoadingDataHighestMarketplace(false)
            toast.error('get data highest marketplace failed')})
    }

    function handleChangeFilterMarketplace(marketplace) {
        setMarketplaceFilterSelected(marketplace)
        fetchProfitData(marketplace._id, timeFilterSelected)
        fetchSalesData(marketplace._id, timeFilterSelected)
        fetchGraphMonthDataProfit(marketplace._id)
        fetchGraphMonthDataSales(marketplace._id)
        fetchHighestProductData(marketplace._id, timeFilterSelected)
        fetchHighestCategoryData(marketplace._id, timeFilterSelected)
        fetchHighestMarketplaceData(marketplace._id, timeFilterSelected)
    }
    
    function handleChangeFilterTime(time) {
        setTimeFilterSelected(time)
        fetchProfitData(marketplaceFilterSelected._id, time)
        fetchSalesData(marketplaceFilterSelected._id, time)
        fetchGraphMonthDataProfit(marketplaceFilterSelected._id)
        fetchGraphMonthDataSales(marketplaceFilterSelected._id)
        fetchHighestProductData(marketplaceFilterSelected._id, time)
        fetchHighestCategoryData(marketplaceFilterSelected._id, time)
        fetchHighestMarketplaceData(marketplaceFilterSelected._id, time)
    }

    return (
        <div>
            <div className="header header-home" > 
                <div className="header-wrapper-button">
                    <div className="pos-relative">
                        <ButtonFilter className={`button button-regular button-filter-product button-add-product m-0`} onClick={()=> setOpenPopupFilterMarketplace(true)}>
                            {marketplaceFilterSelected.name} &nbsp;
                            <i className="fa fa-shopping-cart" style={{fontSize: '14px', width: '14px', height: '14px', color: '#ff9c00'}}></i>
                        </ButtonFilter>
                        {
                            openPopupFilterMarketplace && 
                                <div ref={refPopupFilterMarketplace} className="popup-filter-marketplace m-0">
                                    {
                                        marketplaceOption.map(marketplace => {
                                            return (
                                                <div className="mt-5 cursor-pointer wrapper-filter-category" key={marketplace._id}>
                                                    <input 
                                                        type="checkbox" 
                                                        id={`filter-category-${marketplace._id}`}
                                                        className="cursor-pointer"
                                                        name="filter-category"
                                                        onChange={() => handleChangeFilterMarketplace(marketplace)}
                                                        checked={marketplaceFilterSelected._id == marketplace._id}
                                                    />
                                                    <label className="cursor-pointer" htmlFor={`filter-category-${marketplace._id}`}>{marketplace.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                    <div className="pos-relative">
                        <ButtonFilter className={`button button-regular button-filter-product button-add-product`} onClick={()=> setOpenPopupFilterTime(true)}>
                            {timeFilterSelected} &nbsp;
                            <i className="fas fa-calendar-check" style={{fontSize: '14px', width: '14px', height: '14px', color: '#ff9c00'}}></i>
                        </ButtonFilter>
                        {
                            openPopupFilterTime && 
                                <div ref={refPopupFilterTime} className="popup-filter">
                                    {
                                        timeFilterOption.map(time => {
                                            return (
                                                <div className="mt-5 cursor-pointer wrapper-filter-category" key={time}>
                                                    <input 
                                                        type="checkbox"
                                                        id={`filter-category-${time}`}
                                                        className="cursor-pointer"
                                                        name="filter-category"
                                                        onChange={() => handleChangeFilterTime(time)}
                                                        checked={timeFilterSelected == time}
                                                    />
                                                    <label className="cursor-pointer" htmlFor={`filter-category-${time}`}>{time}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="body-home">
                <div className="wrapper--graph-sales">
                    {
                        loadingDataGraphProfit  ?
                            <div className="wrapper-loading-sales__home wrapper-loading-sales__home__right">
                                <Skeleton  height={200} width={1000} />
                            </div>
                        :
                            dataGraphProfit !== null && 
                            <Sales 
                                marketplace={marketplaceFilterSelected}
                                title={'Omset'}
                                data={dataGraphProfit}
                                iconClass={'fa fa-shopping-cart'}
                                />
                            }
                    {
                        loadingDataGraphSales ? 
                            <div className="wrapper-loading-sales__home">
                                <Skeleton  height={200} width={1000} />
                            </div>
                        :
                            dataGraphSales !== null && 
                            <Sales 
                                marketplace={marketplaceFilterSelected}
                                title={'Penjualan'}
                                customClass={'ml-20'}
                                iconClass={'fa fa-briefcase'}
                                data={dataGraphSales}
                            />
                    }
                </div>
                {
                    loadingDataGraphMonthProfit ? 
                        <div className="wrapper-loading-graph-month__home">
                            <Skeleton  height={450} width={1331} />
                        </div>
                    :
                        <GraphMonth
                            type={'Profit'}
                            marketplace={marketplaceFilterSelected}
                            data={dataGraphMonthProfit}/>
                }
                {
                    loadingDataGraphMonthSales ?
                        <div className="wrapper-loading-graph-month__home">
                            <Skeleton  height={450} width={1331} />
                        </div>
                    :
                        <GraphMonth 
                            type={'Penjualan'}
                            marketplace={marketplaceFilterSelected}
                            data={dataGraphMonthSales}/>
                }
                <div className="d-flex mt-20 flex-wrap">
                    {
                        loadingDataHighestProduct ?
                            <div className="wrapper-loading-graph-month__home">
                                <Skeleton  height={450} width={1331} />
                            </div>
                        :
                            <HighestSale 
                                time={timeFilterSelected}
                                marketplace={marketplaceFilterSelected}
                                type={'Produk'}
                                data={dataHighestProduct}
                                barColor={'#fdc80b'}/>
                    }
                    {
                        loadingDataHighestCategory ? 
                            <div className="wrapper-loading-graph-month__home">
                                <Skeleton  height={450} width={1331} />
                            </div>
                        :
                            <HighestSale 
                                time={timeFilterSelected}
                                marketplace={marketplaceFilterSelected}
                                type={'Kategori'}
                                data={dataHighestCategory}
                                barColor={'#fdc80b'}/>
                    }
                    {
                        loadingDataHighestMarketplace?
                            <div className="wrapper-loading-graph-month__home">
                                <Skeleton  height={450} width={1331} />
                            </div>
                        :
                            <HighestSale
                                time={timeFilterSelected}
                                marketplace={marketplaceFilterSelected}
                                type={'Marketplace'}
                                data={dataHighestMarketplace}
                                barColor={'#fdc80b'}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Home;