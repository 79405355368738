import React, {useEffect, useState} from 'react';
import './index.css'
import convertnumber from '../../helpers/convertnumber'

function Sales({title, data, customClass, iconClass, marketplace}) {

    const [isIncreasing, setIsIncreasing] = useState(false)
    const [difference, setDifference] = useState(0)
    const [percentage, setPercentage] = useState(0)
    
    useEffect(()=> {
        countData()
    },[data])
    
    function countData() {
        if(title === 'Omset') {
            if(data.totalProfitToday - data.totalProfitYesterday > 0) {
                setIsIncreasing(true)
            }
            let countDifference = data.totalProfitToday - data.totalProfitYesterday
            if(countDifference < 0) {
                countDifference = countDifference * -1
            }
            setDifference(countDifference)
            if(data.totalProfitYesterday === 0) {
                setPercentage(100)
            }else {
                setPercentage(countDifference/data.totalProfitYesterday*100 || 0)
            }
        }else {
            if(data.totalSalesToday - data.totalSalesYesterday > 0) {
                setIsIncreasing(true)
            }
            let countDifference = data.totalSalesToday - data.totalSalesYesterday
            if(data.totalSalesYesterday === 0) {
                setPercentage(100)
            }else {
                setPercentage(countDifference/data.totalSalesYesterday*100 || 0)
            }
            setDifference(countDifference)
        }
    }
    
    return (
        <div className={`card--graph ${customClass}`}>
            <div className="card--graph-header">
                <h4>{`${title} (${marketplace && marketplace.name})`}
                    <div className="mt-10">
                        <i className={`${isIncreasing ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'} `}></i> 
                            <span className={`${isIncreasing ? 'text-green' : 'text-red'}`}>
                                {
                                    title === 'Omset' ?
                                    <>
                                        {percentage.toFixed()}% ({isIncreasing ? '+' : '-'}Rp{convertnumber(difference.toFixed())}) 
                                    </> 
                                    :
                                    <>
                                        {percentage.toFixed()}% ({isIncreasing ? '+' : ''}{convertnumber(difference.toFixed())} Produk) 
                                    </> 
                                }
                            </span> 
                            <span className="custom-span__mobile"> Dibanding kemarin</span>
                    </div>
                </h4>
                <h4><i className={`${iconClass}`} style={{fontSize: '42px', color: '#ff9c00'}}></i></h4>
            </div>
            <div className="d-flex justify-between">
                <div className="d-flex flex-col mt-20">
                    {
                        title === 'Omset' &&
                            <>
                                <h3 className="m-0 text-light-grey">RATA-RATA</h3>
                                <h3 className="m-0" style={{color: '#fdc80b'}}>Rp{convertnumber(data.averageToday.toFixed())}</h3>
                            </>
                    }
                </div> 
                <h2 style={{color: '#fdc80b'}}>{title === 'Omset' ? 'Rp':''}{convertnumber(title === 'Omset' ? data.totalProfitToday : data.totalSalesToday)}{title === 'Omset' ? '':' Produk'}</h2>
            </div>
        </div>
    )
}

export default Sales