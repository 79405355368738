function generateSku(product) {
    if(!product) return;
    let splitName = product.name.split(' ')
        let skuName = ''
        if(splitName[1] != undefined && splitName[1] != '') {
            skuName = product.name.split(' ')[1].slice(0,3)
        }else {
            skuName = product.name.slice(0,3)
        }

    let result = (`${product.category.skuVariant}-${skuName}${product.size.skuVariant}${product.color.skuVariant}`).toLocaleUpperCase()
    return(result)
}
export default generateSku;