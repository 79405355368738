import React, {useState, useEffect} from 'react';
import './index.css'
import Search from '../../components/Input/search.jsx'
import Empty from '../../components/Notification/empty.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal  from 'react-modal';
import ReactModal  from 'react-modal';
import axios from 'axios'
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import moment from 'moment'
import Skeleton from 'react-loading-skeleton';
import Popup from '../../components/Notification/Popup';

ReactModal.setAppElement('*');

const customStylesModalUserDetail = {
    content : {
        position: 'absolute',
        top: '40px',
        left: '300px',
        right: '300px',
        bottom: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: '#fafbfd',
        overflow: 'auto',
        borderRadius: '3px',
        outline: 'none',
        padding: '20px',
    }
};

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '500px',
        marginTop: '10px'
    }),
    menu: (provided, state) => ({
        ...provided,
        bottom: '100%',
        top: 'auto'
    }),
}

function User() {
    
    {/**
        ----------STATE----------
    */}
    const history = useHistory()
    const role = useSelector(state => state.role);
    const [userList, setUserList] = useState([])
    const [activityStream, setActivityStream] = useState([])
    const [groupRole, setGroupRole] = useState([])
    const [isShowFormAddUser, setIsShowFormAddUser] = useState(false)
    const [isShowModalUserDetail, setIsShowModalUserDetail] = useState(false)
    const [userDetail, setUserDetail] = useState(null)
    const [indexUserDetail, setIndexUserDetail] = useState(null)
    const [isEditUserDetail, setIsEditUserDetail] = useState(false)
    const [dataEditUserDetail, setDataEditUserDetail] = useState({})
    const [isShowVerifDeleteUser, setIsShowVerifDeleteUser] = useState(false)
    const [categoryOptions, setCategoryOptions] = useState([])
    const [loadingFetchUser, setLoadingFetchUser] = useState(false)
    const [loadingSubmitUser, setLoadingSubmitUser] = useState(false)
    const [loadingUpdateUser, setLoadingUpdateUser] = useState(false)
    const [loadingDeleteUser, setLoadingDeleteUser] = useState(false)
    const [inputFormAddUser, setInputFormAddUser] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        address: '',
        role: '',
    })
    
    {/**
        ----------USEEFFECT----------
    */}

    useEffect(()=> {
        if(role !== 'admin' && role !== 'super admin') {
            history.push('/product')
            return;
        }
        setLoadingFetchUser(true)
        axios.get('/user')
        .then(({data}) => {
            setLoadingFetchUser(false)
            setUserList(data)
        })
        .catch(err => {
            setLoadingFetchUser(false)
            toast.error('load user list failed')
        })

        // axios.get('/user/groupRole')
        // .then(({data}) => {
        //     setGroupRole(data)
        // })
        // .catch(err => toast.error('load role failed'))
        
        axios.get('/categoryBasic')
        .then(({data}) => {
            setCategoryOptions(data)
        })
        .catch(err => toast.error('load role failed'))
        // fetchActivityStream()
    },[])

    {/**
        ----------FUNCTIONALITY----------
    */}
    // function fetchActivityStream() {
    //     const today = new Date()
    //     const date = today.toLocaleDateString();
    //     const time = today.toLocaleTimeString();
    //     const dateIndo = moment(`${date} ${time}`, 'MM-DD-YYYY HH:mm:ss').format();
    //     axios.get('/activityStream', {params: {
    //         startDate: dateIndo,
    //         endDate: dateIndo
    //     }})
    //     .then(({data}) => {
    //         setActivityStream(data)
    //     })
    //     .catch(err => toast.error('load activity stream failed'))
    // }
    const handleOpenFormAddUser = () => {
        setIsShowFormAddUser(true)
    }
    const handleSubmitAddNewUser = (e) => {
        e.preventDefault()
        setLoadingSubmitUser(true)
        if(e.target.elements.name.value === '' || e.target.elements.password.value === '' || e.target.elements.role.value === '') {
            toast.error("Please fill all required inputs")
            setLoadingSubmitUser(false)
            return;
        }
        axios.post('/user', inputFormAddUser)
        .then(({data}) => {
            setUserList(
                [...userList,
                    data
                ]
            )
            setInputFormAddUser({
                name: '',
                email: '',
                password: '',
                phone: '',
                address: '',
                role: '',
            })
            // fetchActivityStream()
            setLoadingSubmitUser(false)
            toast.success("New user created");
        })
        .catch(err => {
            setLoadingSubmitUser(false)
            toast.error("Failed")})
        // setIsShowFormAddUser(false)
    }
    
    const closeModal = () => {
        setIsShowModalUserDetail(false)
        setIsEditUserDetail(false)
        setDataEditUserDetail({})
    }
    
    const handleDeleteUser = () => {
        setLoadingDeleteUser(true)
        let spliceUser = userList
        spliceUser.splice(indexUserDetail, 1 )
        axios.delete(`/user/${userDetail._id}`)
        .then(()=>{
            setLoadingDeleteUser(false)
            toast.success("Delete user success")
            setUserList(spliceUser)
            setIsShowModalUserDetail(false)
            setIsShowVerifDeleteUser(false)
            // fetchActivityStream()
        })
        .catch((err)=>{
            setLoadingDeleteUser(false)
            toast.error("Delete user failed")})
    }

    const handleChangeInputEditUserDetail = (e) => {
        setDataEditUserDetail(
            {
                ...dataEditUserDetail,
                [e.target.name]: e.target.value,
            }
        )
    }

    const handleEditUserDetail = () => {
        setIsEditUserDetail(true)
        setDataEditUserDetail(userDetail)
    }

    const handleSaveEditUserDetail = () => {
        setLoadingUpdateUser(true)
        axios.put(`/user/${userDetail._id}`, {...dataEditUserDetail})
        .then(() => {
            setUserList(userList.map(user => {
                if(user._id === dataEditUserDetail._id) {
                    return user = dataEditUserDetail
                }else {
                    return user
                }
            }))
            setIsEditUserDetail(false)
            setUserDetail(dataEditUserDetail)
            toast.success('update user success')
            setLoadingUpdateUser(false)
        })
        .catch(err => {
            setLoadingUpdateUser(false)
            toast.error('update user error')})
    }

    function handleChangeUserAccess(value) {
        setDataEditUserDetail(
            {
                ...dataEditUserDetail,
                access: value,
            }
        )
    }

    {/**
        ----------RENDER COMPONENT----------
    */}
    return (
        <div>
            {/* 
                Show Notification
            */}
            <div className="header">
                <Search list={userList} setList={setUserList}/>
            </div>
            <div className="body_user">
                <div className="content">
                    {
                        loadingFetchUser ?
                            <>
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                                <p>
                                    <Skeleton count={1} height={35}/>
                                </p> 
                            </>
                        :
                            !userList[0] ? (
                                <Empty />
                            ):(
                                <table className="table-user">
                                    <thead>
                                        <tr>
                                            <td className="text-white">Username</td>
                                            <td className="text-white">Email</td>
                                            <td className="text-white">Phone</td>
                                            <td className="text-white">Role</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((user, indexUser) => {
                                                return (
                                                    user.show && (
                                                        <tr onClick={() => {setUserDetail(user); setIndexUserDetail(indexUser); setIsShowModalUserDetail(true)}} key={user._id} className="spacer">
                                                            <td>{user.name}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.phone}</td>
                                                            <td>{user.role}</td>    
                                                        </tr>
                                                    )
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            )
                    }
                </div>
                <div className="fitur-user">
                    <>
                        <h3 className={`title`} >Add new user</h3>
                        <form className="form form-add-user" onSubmit={handleSubmitAddNewUser}>
                            <label htmlFor="input-name">USERNAME</label>
                            <input value={inputFormAddUser.name} onChange={(e) => setInputFormAddUser({...inputFormAddUser, name: e.target.value})} autoComplete="new" placeholder="&#xF007; username *" style={{fontFamily:"Arial, FontAwesome"}} id="input-name" type="text" name="name"/>
                            <label htmlFor="input-email">EMAIL</label>
                            <input value={inputFormAddUser.email} onChange={(e) => setInputFormAddUser({...inputFormAddUser, email: e.target.value})} autoComplete="new" placeholder="&#xf0e0; email" style={{fontFamily:"Arial, FontAwesome"}} id="input-email" type="email" name="email"/>
                            <label htmlFor="input-password">PASSWORD</label>
                            <input value={inputFormAddUser.password} onChange={(e) => setInputFormAddUser({...inputFormAddUser, password: e.target.value})} autoComplete="new" placeholder="&#xF084; password *" style={{fontFamily:"Arial, FontAwesome"}} id="input-password" type="password" name="password"/>
                            <label htmlFor="input-address">ADDRESS</label>
                            <input value={inputFormAddUser.address} onChange={(e) => setInputFormAddUser({...inputFormAddUser, address: e.target.value})} autoComplete="new" placeholder="&#xf015; address" style={{fontFamily:"Arial, FontAwesome"}} id="input-address" type="text" name="address"/>
                            <label htmlFor="input-phone">PHONE NUMBER</label>
                            <input value={inputFormAddUser.phone} onChange={(e) => setInputFormAddUser({...inputFormAddUser, phone: e.target.value})} autoComplete="new" placeholder="&#xf095; phone" style={{fontFamily:"Arial, FontAwesome"}} id="input-phone" type="text" name="phone"/>
                            <label htmlFor="input-role">ROLE</label>
                            <input value={inputFormAddUser.role} onChange={(e) => setInputFormAddUser({...inputFormAddUser, role: e.target.value})} autoComplete="new" placeholder="&#xf02c; role *" style={{fontFamily:"Arial, FontAwesome", textTransform: 'lowercase'}} id="input-role" type="text" name="role"/>
                            <button type="submit" className={loadingSubmitUser && 'button-primary-disabled'} disabled={loadingSubmitUser}>SUBMIT</button>
                        </form>
                    </>
                    {/* {
                        ========close the activity diagram============
                        isShowFormAddUser ? 
                            <>
                                <i title="close" onClick={() => setIsShowFormAddUser(false)} className={`fa fa-times-circle ${lightMode && 'text-black'}`}></i>
                                <h3 className={`title ${lightMode && 'text-black'}`} >Add new user</h3>
                                <form className="form form-add-user" onSubmit={handleSubmitAddNewUser}>
                                    <label className={`${lightMode && 'text-black'}`} htmlFor="input-name">USERNAME</label>
                                    <input className={`${lightMode && 'input-light text-black'}`} autoComplete="new" placeholder="&#xF007; username" style={{fontFamily:"Arial, FontAwesome"}} id="input-name" type="text" name="name"/>
                                    <label className={`${lightMode && 'text-black'}`} htmlFor="input-email">EMAIL</label>
                                    <input className={`${lightMode && 'input-light text-black'}`} autoComplete="new" placeholder="&#xf0e0; email" style={{fontFamily:"Arial, FontAwesome"}} id="input-email" type="email" name="email"/>
                                    <label className={`${lightMode && 'text-black'}`} htmlFor="input-password">PASSWORD</label>
                                    <input className={`${lightMode && 'input-light text-black'}`} autoComplete="new" placeholder="&#xF084; password" style={{fontFamily:"Arial, FontAwesome"}} id="input-password" type="password" name="password"/>
                                    <label className={`${lightMode && 'text-black'}`} htmlFor="input-address">ADDRESS</label>
                                    <input className={`${lightMode && 'input-light text-black'}`} autoComplete="new" placeholder="&#xf015; address" style={{fontFamily:"Arial, FontAwesome"}} id="input-address" type="text" name="address"/>
                                    <label className={`${lightMode && 'text-black'}`} htmlFor="input-phone">PHONE NUMBER</label>
                                    <input className={`${lightMode && 'input-light text-black'}`} autoComplete="new" placeholder="&#xf095; phone" style={{fontFamily:"Arial, FontAwesome"}} id="input-phone" type="text" name="phone"/>
                                    <label className={`${lightMode && 'text-black'}`} htmlFor="input-role">ROLE</label>
                                    <input className={`${lightMode && 'input-light text-black'}`} autoComplete="new" placeholder="&#xf02c; role" style={{fontFamily:"Arial, FontAwesome"}} id="input-role" type="text" name="role"/>
                                    <button type="submit">Submit</button>
                                </form>
                            </>
                        :
                            <>
                                <div onClick={handleOpenFormAddUser} className="user-button-create">
                                    + Add user
                                </div>
                                <div className="wrapper-user-total">
                                    <div className="user-total">
                                        <div className="user-total-circle rotate-center">
                                            {userList.length}
                                            <br/>
                                            {userList.length > 1 ? 'Users' : 'User'}
                                        </div>
                                    </div>
                                    <div className="user-table-role">
                                        {
                                            groupRole.map(role => {
                                                return (
                                                    <span className="user-role-name">{role._id}<span className="user-role-count">{role.total}</span></span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="user-activity">
                                    <p className="user-activity__header">ACTIVITY STREAM</p>
                                    <div style={{padding: '0 10px', maxHeight: '190px', overflowY: 'auto'}}>
                                        {
                                            !activityStream[0] ? (
                                                <Empty activityStream={true}/>
                                            ):(
                                                activityStream.map(activity => {
                                                    return (
                                                        <div key={activity._id} className="activity">
                                                            <span>{`${activity.user} ${activity.action} ${activity.target}`}</span>
                                                            <span style={{fontSize: '12px', color: '#00923a'}}>{moment(activity.created_at).format('h:mm')}</span>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                    } */}
                </div>
            </div>
            {
                loadingSubmitUser && <Popup text={'Add User'}/>
            }
            {/* 
                Show Modal User Detail
            */}
            {
                isShowModalUserDetail && userDetail && (

                    <Modal
                        isOpen={isShowModalUserDetail}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStylesModalUserDetail}
                        contentLabel="Modal User Detail"
                        id="user-detail-modal"
                    >
                        {
                            loadingUpdateUser && <Popup text={'Update User'}/>
                        }
                        {
                            loadingDeleteUser && <Popup text={'Delete User'}/>
                        }
                        <i title="close" onClick={closeModal} className="fa fa-times-circle"></i>
                        <h3 style={{textAlign: 'center', margin: 0}}>User Detail</h3>
                        <hr/>
                        <div>
                            <div className="section-user-detail">
                                <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xF007;</div>
                                <div className="wrapper-info-user-detail">
                                    <label> Username </label>
                                    {
                                        isEditUserDetail ? 
                                            <input className="input-name" onChange={handleChangeInputEditUserDetail} name="name" type="text" style={{fontFamily:"Arial, FontAwesome"}} placeholder="&#xF007; Edit name" value={dataEditUserDetail.name}/>
                                        :
                                            <p>{userDetail.name}</p>
                                    }
                                </div>
                            </div>
                            <div className="section-user-detail">
                                <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xf0e0;</div>
                                <div className="wrapper-info-user-detail">
                                    <label> Email </label>
                                    {
                                        isEditUserDetail ? 
                                            <input className="input-email" onChange={handleChangeInputEditUserDetail} name="email" type="email" style={{fontFamily:"Arial, FontAwesome"}} placeholder="&#xf0e0; Edit email" value={dataEditUserDetail.email}/>
                                        :
                                        <p>{userDetail.email}</p>
                                    }
                                </div>
                            </div>
                            <div className="section-user-detail">
                                <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xF084;</div>
                                <div className="wrapper-info-user-detail">
                                    <label> Password </label>
                                    {
                                        isEditUserDetail ? 
                                            <input 
                                                className="input-password" 
                                                onChange={handleChangeInputEditUserDetail} 
                                                name="password" 
                                                type="text" 
                                                disabled
                                                style={{fontFamily:"Arial, FontAwesome"}} placeholder="Can not edit password" 
                                                value={'delete user and create new one if you forgot password'}/>
                                        :
                                        <p>{userDetail.password}</p>
                                    }
                                </div>
                            </div>
                            <div className="section-user-detail">
                                <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xf095;</div>
                                <div className="wrapper-info-user-detail">
                                    <label> Phone Number</label>
                                    {
                                        isEditUserDetail ? 
                                            <input className="input-phone" onChange={handleChangeInputEditUserDetail} name="phone" type="text" style={{fontFamily:"Arial, FontAwesome"}} placeholder="&#xf095; Edit phone number" value={dataEditUserDetail.phone}/>
                                        :
                                        <p>{userDetail.phone}</p>
                                    }
                                </div>
                            </div>
                            <div className="section-user-detail">
                                <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xf015;</div>
                                <div className="wrapper-info-user-detail">
                                    <label> Address </label>
                                    {
                                        isEditUserDetail ? 
                                            <textarea className="input-address" onChange={handleChangeInputEditUserDetail} name="address" type="text" style={{fontFamily:"Arial, FontAwesome"}} placeholder="&#xf015; Edit address" value={dataEditUserDetail.address}/>
                                        :
                                        <p>{userDetail.address}</p>
                                    }
                                </div>
                            </div>
                            {
                                (userDetail.role !== 'admin' && userDetail.role !== 'super admin' && userDetail.role !== 'offline store') && (
                                    <div className="section-user-detail">
                                        <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xf02c;</div>
                                        <div className="wrapper-info-user-detail">
                                            <label> Role </label>
                                            {
                                                isEditUserDetail ? 
                                                    <input className="input-role" onChange={handleChangeInputEditUserDetail} name="role" type="text" style={{fontFamily:"Arial, FontAwesome"}} placeholder="&#xf02c; Edit role" value={dataEditUserDetail.role}/>
                                                :
                                                <p>{userDetail.role}</p>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (userDetail.role !== 'admin' && userDetail.role !== 'super admin' && userDetail.role !== 'offline store') && (
                                    <div className="section-user-detail">
                                        <div className="wrapper-icon-user-detail" style={{fontFamily:"Arial, FontAwesome"}}>&#xf06e;</div>
                                        <div className="wrapper-info-user-detail">
                                            <label> User Access </label>
                                            {
                                                isEditUserDetail ? 
                                                    <Select
                                                        className={'user-access-edit'}
                                                        options={categoryOptions}
                                                        isMulti
                                                        value={dataEditUserDetail.access} 
                                                        styles={customStyles}
                                                        onChange={handleChangeUserAccess}
                                                        />
                                                        :
                                                    <Select
                                                        className={'user-access-edit'}
                                                        options={categoryOptions}
                                                        isMulti
                                                        isDisabled
                                                        value={userDetail.access} 
                                                        styles={customStyles}
                                                        onChange={handleChangeUserAccess}
                                                    />
                                            }
                                        </div>
                                    </div>
                                ) 
                            }
                            <div className="wrapper-button">
                                {
                                    isEditUserDetail ? (
                                        <button className={`button button-update-user ${loadingUpdateUser && 'button-primary-disabled'}`} disabled={loadingUpdateUser} onClick={handleSaveEditUserDetail}>Save</button>
                                        ): isShowVerifDeleteUser ? (
                                            <button className={`button button-update-user ${loadingDeleteUser && 'button-primary-disabled'}`} disabled={loadingDeleteUser} onClick={handleDeleteUser}>Yes</button>
                                            ) : (
                                                    <button className="button button-update-user" onClick={handleEditUserDetail}>Edit User</button>
                                                )
                                }
                                {
                                    isEditUserDetail ? (
                                        <button className="button button-delete-user" onClick={() => setIsEditUserDetail(false)}>Cancel</button>
                                    ): isShowVerifDeleteUser ? (
                                            <button className="button button-delete-user" onClick={() => setIsShowVerifDeleteUser(false)}>No</button>
                                        ) : (
                                                <>
                                                    {
                                                        userDetail.role !== 'super admin' && userDetail.role !== 'offline store' && <button className="button button-delete-user" onClick={() => setIsShowVerifDeleteUser(true)}>Delete User</button>
                                                    }
                                                </>
                                            )
                                }
                            </div>
                        </div>
                    </Modal>
                )
            }
        </div>
    )
}
export default User