import React, {useEffect, useState, useRef} from 'react';
import './index.css'
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    useLocation,
} from "react-router-dom";
import axios from 'axios'
import {toast} from 'react-toastify'
import styled from 'styled-components'
import moment from 'moment' 
import Skeleton from "react-loading-skeleton";
import TransactionDetail from './transactionDetail'
import convertnumber from '../../helpers/convertnumber';

function Ecommerce() {

    const [previewImage, setPreviewImage] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [transactionList, setTransactionList] = useState([])
    const [openTransactionDetail, setOpenTransactionDetail] = useState(null)

    useEffect(() => {
        getJumbotron()
        getTransaction()
    },[])

    const getTransaction = () => {
        axios.get(`/ecommerceTransaction`)
        .then(({data}) => {
            setTransactionList(data)
        })
        .catch(err => {
            toast.error('Server error')
        })
    }

    const getJumbotron = () => {
        axios.get('/jumbotron')
        .then(({data}) => {
            setPreviewImage(data)
        })
        .catch(err => {
            toast.error('get image jumbotron error')
        })
    }

    const handleUploadAttachment = async(value)=>{
        if(!value) {
            return;
        }

        var reader = new FileReader();
        var url = reader.readAsDataURL(value) //should use this to show preview image

        let data = new FormData()
        data.append('file', value)
        let dataUpload = await axios.post('/upload', data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(({data}) => {
            return data
        })
        .catch(err => {
            toast.error('failed upload image')
        })

        axios.post('/jumbotron', {
            url: dataUpload.filename
        })
        .then(({data}) => {
            setPreviewImage([...previewImage, data])
            toast.success('image uploaded')
        })
        .catch(err => {
            toast.error('upload image error')
        })
    }

    const handleClickUpload = ()=> {
        document.getElementById('input-file').click()
    }

    const handleDeleteImage = (data)=> {
        if(isLoading) return;
        setIsLoading(true)
        axios.delete(`/jumbotron/${data._id}`)
        .then(resp => {
            return axios.post('/deleteImage', {
                image: data.url
            })        
        })
        .then(resp => {
            setPreviewImage(previewImage.filter(image => image._id !== data._id))
            toast.success('Delete success')
            setIsLoading(false)
        })
        .catch(err => {
            setIsLoading(false)
            toast.error('Delete failed')
        })
    }

    return (
        <div>
            <div className="header header-information"/>
            <div className="body-ecommerce">
                <div className="wrapper-jumbotron">
                    <h3>Jumbotron Display</h3>
                    <div>
                        <input onChange={(e)=> {
                            e.persist()
                            handleUploadAttachment(e.target.files[0])
                        }} id="input-file" type="file" style={{display: 'none'}}/>
                        <label onClick={handleClickUpload} className="btn-add-image">+ Add image</label>
                        <div className="d-flex">
                            {
                                previewImage[0] ? 
                                    previewImage.map(image => {
                                        return (
                                            <div className="box-upload-image pos-relative mt-10 mr-5">
                                                <img style={{height: '100%', width: '100%'}} src={`https://apiv1.heylook-dashboard.xyz/images/${image.url}`}/>
                                                <i onClick={() => handleDeleteImage(image)} className="fa fa-trash"/>
                                            </div>

                                        )
                                    })
                                :
                                    <div className="box-upload-image mt-10">
                                        <i class="fa fa-image" style={{fontSize: '50px', color: '#a2a2a2', height: '50px', width:'50px'}}></i>
                                    </div>
                                    
                            }
                        </div>
                    </div>
                </div>
                <div className="wrapper-transaction">
                    <h4>Transaction Lists</h4>
                    <div className="transaction-list-head">
                        <span className="flex-3">Order ID</span>
                        <span className="flex-2">Date</span>
                        <span className="flex-3">Receiver Name</span>
                        <span className="flex-1">Shipping</span>
                        <span className="flex-2">Payment</span>
                        <span className="flex-1">Price</span>
                        <span className="flex-1">Status</span>
                    </div>
                    
                    {
                        transactionList.map(transaction => {
                            return (
                                <div onClick={() => setOpenTransactionDetail(transaction._id)} key={transaction._id} className="transaction-list">
                                    <span className="flex-3">{transaction._id}</span>
                                    <span className="flex-2">{moment(transaction.created_at).format("MMM Do YY")}</span>
                                    <span className="flex-3">{transaction.address.firstName} {transaction.address.lastName}</span>
                                    <span className="flex-1">{transaction.shippingName}-{transaction.shippingService}</span>
                                    <span className="flex-2">{transaction.paymentMethod}</span>
                                    <span className="flex-1">{convertnumber(transaction.totalPrice)}</span>
                                    <span
                                        className={
                                            `
                                                ${transaction.orderStatus === 'pending' ? 'text-orange' 
                                                    : transaction.orderStatus === 'confirmed' ? 'text-green' 
                                                        : transaction.orderStatus === 'shipping' ? 'text-blue' 
                                                            : transaction.orderStatus === 'complete' ? 'text-green' 
                                                                : 'text-red'} text-ellipsis flex-1 fs-10
                                            `
                                        } 
                                    >
                                    {
                                        transaction.orderStatus === 'pending' ? 'Menunggu Konfirmasi Pembayaran' 
                                            : transaction.orderStatus === 'confirmed' ? 'Pesanan Di konfirmasi' 
                                                : transaction.orderStatus === 'shipping' ? 'Dalam Pengiriman' 
                                                    : transaction.orderStatus === 'complete' ? 'Selesai' 
                                                        : 'Menunggu Update Status'
                                    }
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                openTransactionDetail && <TransactionDetail transactionList={transactionList} setTransactionList={setTransactionList} transactionId={openTransactionDetail} setOpenTransactionDetail={setOpenTransactionDetail}/>
            }
        </div>
    )
}

export default Ecommerce;