import React, {useState, useEffect} from 'react';

function InfoSKU(){
    return(
        <div className="wrapper-info-sku">
            <h3 style={{borderBottom: '1px solid black'}}>
                <i className="fa fa-sitemap"></i>
                &nbsp;
                <span className="bold mb-10">WB-FEL0BW</span>
                &nbsp;
                (SKU Product)
            </h3>
            <span className="mb-10"><b>WB</b> = Category (Waist Bag)</span>
            <span className="mb-10"><b>FEL</b> = 3 letter from the second product name (WAISTBAG FELIX BLACK WHITE)</span>
            <span className="mb-10"><b>0</b> = Size (All size)</span>
            <span className="mb-10"><b>BW</b> = Color (Black White)</span>
        </div>
    )
}
export default InfoSKU