import React from 'react';
import './index.css'

function Empty({activityStream}) {
    return (
        <div className="notification-empty-data">
            {
                activityStream ? 
                    <>
                        No activity today
                    </>
                :
                    <>
                        Ooops You Don't Have Any Data.
                        <br/>
                        Let's create one!
                    </>
            }
        </div>
    )
}
export default Empty