import React, {useEffect, useState, useRef} from 'react';
import './index.css'
import Chart from 'chart.js';

function HighestSale({type, data, barColor, time, marketplace}) {
    
    const chartRef = useRef()
    const [instance, setInstance] = useState(null)

    useEffect(()=> {
        if(data == null) {
            return;
        }
        if(instance !== null) {
            instance.destroy()
        }
        var ctx = chartRef.current.getContext('2d');
        let tempLabels = []
        let tempData = []
        let maksSlice = 4
        if(type == 'Marketplace'){
            maksSlice = 6
        }
        Object.keys(data).slice(0,maksSlice).map((key) => {
            if(type == 'Produk'){
                tempLabels.push(data[key].label)
                tempData.push(data[key].sold)
            }else if(type == 'Kategori') {
                tempLabels.push(data[key].categoryName)
                tempData.push(data[key].sold)
            }else if(type == 'Marketplace') {
                tempLabels.push(data[key].marketplaceName)
                tempData.push(data[key].sold)
            }
        })
        setInstance(new Chart(ctx, {
            type: 'bar',
            data: {
                labels: tempLabels,
                datasets: [{
                    label: 'Produk terjual',
                    data: tempData,
                    backgroundColor: [
                        barColor,
                        barColor,
                        barColor,
                        barColor,
                        barColor,
                    ],
                    borderColor: [
                        barColor,
                        barColor,
                        barColor,
                        barColor,
                        barColor,
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    datalabels: {
                        formatter: function(value) {
                            return value;
                        },
                        color: function(value) {
                            return '#ffff';
                        },
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            color: '#fafbfd',
                            fontColor: '#fafbfd'
                        },
                        gridLines: {
                            color: '#fafbfd',
                            zeroLineColor: "#fafbfd"
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            callback: function(label) {
                                if (/\s/.test(label)) {
                                    return label.split(" ");
                                }else{
                                    return label;
                                }              
                            },
                            color: '#fafbfd',
                            fontColor: '#fafbfd'
                        },
                        gridLines: {
                            display: false,
                            color: '#fafbfd'
                        },
                    }]
                },
                legend: {
                    display: false
                },
                tooltips:{
                    callbacks: {
                        title: function(t, d) {
                            return d.labels[t[0].index];
                        }
                    }
                },
                maintainAspectRatio: false,
            }
        }));
    },[data])

    return (
        <div className={`card--graph-bar d-flex flex-col`}>
            <h4>Penjualan {type} Tertinggi {`${time === 'DAILY' ? 'Hari ini' : 'Bulan ini' }`} ({`${marketplace.name}`})</h4>
            <div style={{height: '100%'}}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>
    )
}

export default HighestSale