import React from 'react';
import './index.css'

function Popup({text}) {
    return (
        <div className="popup-notif">
            ⏰ Loading {text}
        </div>
    )
}
export default Popup;