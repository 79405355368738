import React from 'react';
import './index.css'
function RememberStock() {

    return (
        <div className="card--graph-bar">
            Pengingat Stock Yang Akan Habis
        </div>
    )
}

export default RememberStock