import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Skeleton from "react-loading-skeleton";

const ButtonFilter = styled.button`
    ${({selected}) => selected && `
        border-radius: 5px;
        background: #1F5CC4;
        color: white;
        font-weight: bold;
        padding: 0 10px;
        border: 1px solid #051E29;
        &:hover {
            background: #2789f8;
        }
    `}
`

function Marketplace({ updateMarketplaceData, setUpdateMarketplaceData, setEditMarketplaceData, newMarketplaceData, setNewMarketplaceData, setIsOpenModal }) {

    const [marketplaceList, setMarketplaceList] = useState([])
    const [mainMarketplaceList, setMainMarketplaceList] = useState([])
    const [openPopupFilter, setOpenPopupFilter] = useState(false)
    const [filterSelected, setFilterSelected] = useState(null)
    const refPopupFilter = useRef(null)
    const [loadingFetchData, setLoadingFetchData] = useState(false)

    useEffect(()=> {
        document.addEventListener("mousedown", handleClick, false)
        setLoadingFetchData(true)
        axios.get('/marketplace')
        .then(({data}) => {
            setMarketplaceList(data)
            setMainMarketplaceList(data)
            setLoadingFetchData(false)
        })
        .catch(err => {
            setLoadingFetchData(false)
            toast.error('Fetch marketplace list failed')
        })
    },[])

    useEffect(()=> {
        if(newMarketplaceData) {
            setMarketplaceList([...marketplaceList, newMarketplaceData])
            setNewMarketplaceData(null)
        }else if(updateMarketplaceData) {
            setMarketplaceList(marketplaceList.map(marketplace => {
                if(marketplace._id == updateMarketplaceData._id) {
                    return updateMarketplaceData
                }else {
                    return marketplace
                }
            }))
            setUpdateMarketplaceData(null)
        }
    },[newMarketplaceData, updateMarketplaceData])

    function handleClick(e) {
        if(refPopupFilter.current && !refPopupFilter.current.contains(e.target)) {
            setOpenPopupFilter(false)
        }
    }

    function handleDeleteMarketplace(variantId) {
        axios.delete(`/marketplace/${variantId}`)
        .then(()=> {
            setMarketplaceList(marketplaceList.filter(el => el._id != variantId))
            toast.success('Delete success')
        })
        .catch(err => toast.success('Delete failed'))
    }

    function handleClickButtonAdd() {
        setIsOpenModal('marketplace')
    }
    function handleChangeFilterMarketplace(value) {
        if(value === filterSelected) {
            setFilterSelected(null)
            setMarketplaceList(mainMarketplaceList)
        }else {
            setFilterSelected(value)
            handleFilterMarketplace(value)
        }
    }
    function handleFilterMarketplace(value) {
        setMarketplaceList(mainMarketplaceList.filter(marketplace => marketplace.status == value))
        setOpenPopupFilter(false)
    }

    return(
            <div className="wrapper-marketplace">
                <div className="wrapper-marketplace-content-header">
                    <h3><i className="fa fa-shopping-cart"></i> Marketplace | Dropshipper | Reseller</h3>
                    <div className="d-flex header-marketplace-wrapper-button">
                        <button className="button button-primary button-add-product button-refresh-product" onClick={handleClickButtonAdd}>+ADD</button>
                        <div className="pos-relative">
                            <ButtonFilter 
                                selected={filterSelected}
                                className={`button button-regular button-filter-product button-add-product`}
                                onClick={() => setOpenPopupFilter(!openPopupFilter)}>
                                FILTER
                                <i className="fa fa-filter fs-12"></i> 
                            </ButtonFilter>
                            {
                                openPopupFilter && 
                                    <div ref={refPopupFilter} className="popup-filter">
                                        <div className="d-block">
                                            <input 
                                                type="checkbox" 
                                                id={`filter-marketplace`}
                                                className="cursor-pointer"
                                                name="filter-category"
                                                onChange={() => handleChangeFilterMarketplace('marketplace')}
                                                checked={filterSelected == 'marketplace'}
                                            />
                                            <label className="cursor-pointer" htmlFor={`filter-marketplace`}>Marketplace</label>
                                        </div>
                                        <div className="d-block">
                                            <input 
                                                type="checkbox" 
                                                id={`filter-reseller`}
                                                className="cursor-pointer"
                                                name="filter-category"
                                                onChange={() => handleChangeFilterMarketplace('reseller')}
                                                checked={filterSelected == 'reseller'}
                                            />
                                            <label className="cursor-pointer" htmlFor={`filter-reseller`}>Reseller</label>
                                        </div>
                                        <div className="d-block">
                                            <input 
                                                type="checkbox" 
                                                id={`filter-dropshipper`}
                                                className="cursor-pointer"
                                                name="filter-category"
                                                onChange={() => handleChangeFilterMarketplace('dropshipper')}
                                                checked={filterSelected == 'dropshipper'}
                                            />
                                            <label className="cursor-pointer" htmlFor={`filter-dropshipper`}>Dropshipper</label>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="table-marketplace">
                    <div className="thead-marketplace">
                        <div className="tr-marketplace">
                            <span className="td-marketplace">Name</span>
                            <span className="td-marketplace">Status</span>
                            <span className="td-marketplace">Information</span>
                            <span className="td-marketplace">Action</span>
                        </div>
                    </div>
                    <div className="tbody-marketplace">
                        {
                            loadingFetchData ? 
                                <Skeleton height={35} count={5}/>
                            :
                                marketplaceList.map(marketplace => {
                                    return (
                                        <div className="tr-marketplace">
                                            <span className="td-marketplace">{marketplace.name}</span>
                                            <span className="td-marketplace">{marketplace.status}</span>
                                            <span className="td-marketplace">{marketplace.information}</span>
                                            <span className="td-marketplace">
                                                {
                                                    marketplace.name !== 'offline store' && (
                                                        <><span className="hover-green text-blue" onClick={() => setEditMarketplaceData(marketplace)}>Edit</span> | <span className="hover-red text-blue" onClick={() => handleDeleteMarketplace(marketplace._id)}>Delete</span> </>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
    )
}
export default Marketplace