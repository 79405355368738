import React, {useState, useEffect} from 'react';
import './index.css'

const Search = ({list, setList, activityStream = false}) => {

    const [inputValue, setInputValue] = useState('')
    
    const handleSearch = function() {
        if(activityStream){
            setList(list.map(item => {
                if(inputValue.includes('-')){
                    // Search by sku
                    let splitName = item.target.product.name.split(' ')
                    let skuName = ''
                    if(splitName[1] != undefined && splitName[1] != '') {
                        skuName = item.target.product.name.split(' ')[1].slice(0,3)
                    }else {
                        skuName = item.target.product.name.slice(0,3)
                    }
                    let sku = `${item.target.product.category.skuVariant}-${skuName}${item.target.product.size.skuVariant}${item.target.product.color.skuVariant}`
                    if(sku.includes(inputValue.toUpperCase())) {
                        return {
                            ...item,
                            show : true
                        }
                    }else {
                        return {
                            ...item,
                            show : false
                        }
                    }
                }else {
                    // Search by name
                    if(item.target.product.name.toLowerCase().includes(inputValue.toLowerCase())) {
                        return {
                            ...item,
                            show : true
                        }
                    }else {
                        return {
                            ...item,
                            show : false
                        }
                    }
                }
            }))
            return;
        }
        setList(list.map(item => {
            if(inputValue.includes('-')){
                // Search by sku
                let splitName = item.name.split(' ')
                let skuName = ''
                if(splitName[1] != undefined && splitName[1] != '') {
                    skuName = item.name.split(' ')[1].slice(0,3)
                }else {
                    skuName = item.name.slice(0,3)
                }
                let sku = `${item.category.skuVariant}-${skuName}${item.size.skuVariant}${item.color.skuVariant}`
                if(sku.includes(inputValue.toUpperCase())) {
                    return {
                        ...item,
                        show : true
                    }
                }else {
                    return {
                        ...item,
                        show : false
                    }
                }
            }else {
                // Search by name
                if(item.name.toLowerCase().includes(inputValue.toLowerCase())) {
                    return {
                        ...item,
                        show : true
                    }
                }else {
                    return {
                        ...item,
                        show : false
                    }
                }
            }
        }))
        
    }

    return (
        <div className="wrapper-input-search">
            <input
                type="text"
                className='input-search'
                placeholder="Search..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        handleSearch()
                    }
                }}
            />
        </div>
    )

};


export default Search