import React, {useState, useEffect, useRef} from 'react';
import Search from '../../components/Input/search.jsx'
import './index.css'
import ProductList from './ProductList'
import AddProduct from './AddProduct'
import axios from 'axios'
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components'
import convertnumber from '../../helpers/convertnumber'
import generateSku from '../../helpers/generateSku'
import Skeleton from "react-loading-skeleton";
import ActivityStream from './ActivityStream'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ButtonFilter = styled.button`
    ${({selected}) => selected && `
        border-radius: 5px;
        background: #1F5CC4;
        color: white;
        font-weight: bold;
        padding: 0 10px;
        border: 1px solid #051E29;
        &:hover {
            background: #2789f8;
        }
    `}
`


function Product() {

    {/**
        ----------STATE----------
    */}
    const [productList, setProductList] = useState([])
    const [mainProductList, setMainProductList] = useState([])
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false)
    const [isEditProduct, setIsEditProduct] = useState({check: false, data: null})
    const [openPopupFilter, setOpenPopupFilter] = useState(false)
    const [categoryFilterOptions, setCategoryFilterOptions] = useState([])
    const [colorFilterOptions, setColorFilterOptions] = useState([])
    const [filterSelected, setFilterSelected] = useState(null)
    const [sortData, setSortData] = useState(false)
    const refPopupFilter = useRef(null)
    const role = useSelector(state => state.role);
    const userAccess = useSelector(state => state.userAccess);
    const [isLoadingFetchProduct, setIsLoadingFetchProduct] = useState(false)
    const [isOpenActivityStream, setIsOpenActivityStream] = useState(false)
    const [dataSetExcel, setDataSetExcel] = useState(null)

    {/**
        ----------USEEFFECT----------
    */}
    useEffect(()=> {
        document.addEventListener("mousedown", handleClick, false)
        fetchProductList()
        fetchCategory()
        fetchColor()
    },[])
    
    {/**
        ----------FUNCTIONALITY----------
    */}

    function handleClick(e) {
        if(refPopupFilter.current && !refPopupFilter.current.contains(e.target)) {
            setOpenPopupFilter(false)
        }
    }

    function fetchProductList() {
        setIsLoadingFetchProduct(true)
        if(role !== 'admin' && role !== 'super admin') {
            axios.post('/product/findbyUserAccess', {userAccess})
            .then(({data}) =>{
                setIsLoadingFetchProduct(false)
                setProductList(data)
                setMainProductList(data)
                generateExcelData(data)
            })
            .catch(err => {
                setIsLoadingFetchProduct(false)
                toast.success('Load produc list failed')
            })
        }else {
            axios.get('/product')
            .then(({data}) =>{
                setIsLoadingFetchProduct(false)
                setMainProductList(data)
                setProductList(data)
                generateExcelData(data)
            })
            .catch(err =>{ 
                setIsLoadingFetchProduct(false)
                toast.success('Load produc list failed')
            })
        }
    }
    const handleEditProduct = function(product) {
        setIsEditProduct({check: true, data: product})
        setIsShowModalAddProduct(true)
    }
    
    function fetchCategory() {
        axios.get('/categoryBasic')
        .then(({data}) => {
            setCategoryFilterOptions(data)
        })
        .catch(err => toast.error('Fetch data catergory for filter error'))
    }
    
    function fetchColor() {
        axios.get('/colorBasic')
        .then(({data}) => {
            setColorFilterOptions(data)
        })
        .catch(err => toast.error('Fetch data color for filter error'))
    }

    function handleChangeFilterCategory(categoryId) {
        if(categoryId === filterSelected) {
            setFilterSelected(null)
            setProductList(mainProductList)
        }else {
            setFilterSelected(categoryId)
            handleFilterCategory(categoryId)
        }
    }

    function handleChangeFilterColor(colorName) {
        if(colorName === filterSelected) {
            setFilterSelected(null)
            setProductList(mainProductList)
        }else {
            setFilterSelected(colorName)
            handleFilterColor(colorName)
        }
    }

    function handleFilterCategory(categoryId) {
        setProductList(mainProductList.map(product => {
                if(product.category._id != categoryId) {
                    return {
                        ...product,
                        show: false
                    }
                }else {
                    return product
                }
        }))
        setOpenPopupFilter(false)
    }
    function handleFilterColor(colorName) {
        setProductList(mainProductList.map(product => {
                if(product.color.label.toLowerCase() != colorName.toLowerCase()) {
                    return {
                        ...product,
                        show: false
                    }
                }else {
                    return product
                }
        }))
        setOpenPopupFilter(false)
    }

    function handleClickSort(key, value) {
        setSortData({key, value})
        handleSortData(key, value)
    }

    function handleSortData(key, value) {
        if(key == 'color' || key == 'category'){
            setProductList(mainProductList.sort((a,b) => {
                if(value == 'asc'){
                    return (a[key].label > b[key].label) ? 1 : ((b[key].label > a[key].label) ? -1 : 0)
                }else {
                    return (a[key].label < b[key].label) ? 1 : ((b[key].label < a[key].label) ? -1 : 0)
                }
            })); 
        }else {
            setProductList(mainProductList.sort((a,b) => {
                if(value == 'asc'){
                    return (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)
                }else {
                    return (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0)
                }
            })); 
        }
    }

    function handleClickActivityStream() {
        setIsOpenActivityStream(true)   
    }

    function generateExcelData(data) {
        let listData = []
        data.map(product => {
            listData.push({
                name: product.name,
                size: product.size.label,
                color: product.color.label,
                category: product.category.label,
                reject: product.reject,
                stock: product.stock,
                sold: product.sold
            })
        })
        setDataSetExcel(listData)
    }

    {/**
        ----------RENDER COMPONENT----------
    */}
    return (
        <>
            {
                isOpenActivityStream ?
                    <ActivityStream setIsOpenActivityStream={setIsOpenActivityStream}/> 
                :
                    <div>
                        <div className="header header-product">
                            <Search list={productList} setList={setProductList} />
                            <div className="header-wrapper-button header-wrapper-button-product">
                                {
                                    (role == 'admin' || role == 'super admin') && (
                                        <button className="button button-primary button-add-product" onClick={() => setIsShowModalAddProduct(true)}>+ ADD PRODUCT</button>
                                    )
                                }
                                <div className="pos-relative">
                                    <ButtonFilter selected={filterSelected} className={`button button-regular button-filter-product button-add-product`} onClick={() => setOpenPopupFilter(!openPopupFilter)}>
                                            FILTER &nbsp;
                                            <i class="fa fa-filter fs-12"></i>
                                    </ButtonFilter>
                                    {
                                            openPopupFilter && 
                                                <div ref={refPopupFilter} className="popup-filter">
                                                    <p>Category</p>
                                                    {
                                                        categoryFilterOptions.map(category => {
                                                            return (
                                                                <div className="mt-5 cursor-pointer wrapper-filter-category" key={category._id}>
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id={`filter-category-${category._id}`}
                                                                        className="cursor-pointer"
                                                                        name="filter-category"
                                                                        onChange={() => handleChangeFilterCategory(category._id)}
                                                                        checked={filterSelected == category._id}
                                                                    />
                                                                    <label className="cursor-pointer" htmlFor={`filter-category-${category._id}`}>{category.label}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <p style={{marginTop: '5px'}}>Color</p>
                                                    {
                                                        colorFilterOptions.map(color => {
                                                            return (
                                                                <div className="mt-5 cursor-pointer wrapper-filter-category" key={color._id}>
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id={`filter-category-${color._id}`}
                                                                        className="cursor-pointer"
                                                                        name="filter-category"
                                                                        onChange={() => handleChangeFilterColor(color.label)}
                                                                        checked={filterSelected == color.label}
                                                                    />
                                                                    <label className="cursor-pointer" htmlFor={`filter-category-${color._id}`}>{color.label}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                        }
                                </div>
                                <button className="button button-primary button-add-product" onClick={handleClickActivityStream}>ACTIVITY STREAM</button>
                            </div>
                            <div className="header-wrapper-button header-wrapper-button-product btn-export-excel" style={{display: 'flex', marginLeft: '15px', alignItems: 'center'}}>
                                {
                                    dataSetExcel ? (
                                        <ExcelFile element={<button style={{ backgroundColor: '#fafbfd', borderRadius: '3px', cursor: 'pointer'}}><i class="far fa-file-excel" style={{color: '#57df5a'}}></i> excel</button>}>
                                            <ExcelSheet data={dataSetExcel} name="data activity stream">
                                                <ExcelColumn label="Name" value="name"/>
                                                <ExcelColumn label="Size" value="size"/>
                                                <ExcelColumn label="Color" value="color"/>
                                                <ExcelColumn label="Category" value="category"/>
                                                <ExcelColumn label="Reject" value="reject"/>
                                                <ExcelColumn label="Stock" value="stock"/>
                                                <ExcelColumn label="Sold" value="sold"/>
                                            </ExcelSheet>
                                        </ExcelFile>
                                    )  : <button disabled style={{ backgroundColor: '#fafbfd', cursor: 'not-allowed', borderRadius: '3px', color: 'rgb(154 154 154)'}}><i class="far fa-file-excel"></i> excel</button>
                                }
                            </div>
                        </div>
                        <div className="body">
                            <div className="table-product">
                                <div className="thead-product">
                                    <div className="tr-product">
                                        <span className="td-l">
                                            Name
                                            &nbsp;
                                            {
                                                sortData.key == 'name' && sortData.value === 'desc' ? 
                                                    <i class="fas fa-sort-alpha-down" style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} onClick={() => handleClickSort('name', 'asc')}></i>
                                                :
                                                    <i class="fas fa-sort-alpha-down-alt" style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} onClick={() => handleClickSort('name', 'desc')}></i>
                                            }
                                        </span>
                                        <span className="td-s">
                                            Variant
                                            &nbsp;
                                            {
                                                sortData.key == 'color' && sortData.value === 'desc' ? 
                                                    <i 
                                                        class="fas fa-sort-alpha-down" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('color', 'asc')}></i>
                                                :
                                                    <i 
                                                        class="fas fa-sort-alpha-down-alt" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('color', 'desc')}></i>
                                            }
                                        </span>
                                        <span className="td-s">
                                            Price
                                            &nbsp;
                                            {
                                                sortData.key == 'price' && sortData.value === 'desc' ? 
                                                    <i 
                                                        class="fas fa-sort-numeric-down" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('price', 'asc')}></i>
                                                        :
                                                        <i 
                                                        class="fas fa-sort-numeric-down-alt" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('price', 'desc')}></i>
                                                    }
                                        </span>
                                        <span className="td-s">
                                            Stock
                                            &nbsp;
                                            {
                                                sortData.key == 'stock' && sortData.value === 'desc' ? 
                                                    <i 
                                                        class="fas fa-sort-numeric-down" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('stock', 'asc')}></i>
                                                :
                                                    <i 
                                                        class="fas fa-sort-numeric-down-alt" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('stock', 'desc')}></i>
                                            }
                                        </span>
                                        <span className="td-s">
                                            Reject
                                            &nbsp;
                                            {
                                                sortData.key == 'reject' && sortData.value === 'desc' ? 
                                                    <i 
                                                        class="fas fa-sort-numeric-down" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('reject', 'asc')}></i>
                                                :
                                                    <i 
                                                        class="fas fa-sort-numeric-down-alt" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('reject', 'desc')}></i>
                                            }
                                        </span>
                                        <span className="td-s">
                                            Sold
                                            &nbsp;
                                            {
                                                sortData.key == 'sold' && sortData.value === 'desc' ? 
                                                    <i 
                                                        class="fas fa-sort-numeric-down" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('sold', 'asc')}></i>
                                                :
                                                    <i 
                                                        class="fas fa-sort-numeric-down-alt" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('sold', 'desc')}></i>
                                            }
                                        </span>
                                        <span className="td-m">
                                            Category
                                            &nbsp;
                                            {
                                                sortData.key == 'category' && sortData.value === 'desc' ? 
                                                    <i 
                                                        class="fas fa-sort-alpha-down" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('category', 'asc')}></i>
                                                :
                                                    <i 
                                                        class="fas fa-sort-alpha-down-alt" 
                                                        style={{fontSize: '16px', width: '16px', height: '16px', color: '#ff9c00'}} 
                                                        onClick={() => handleClickSort('category', 'desc')}></i>
                                            }
                                        </span>
                                        <span className="td-s">Status</span>
                                    </div>
                                </div>
                                <div className="tbody-product">
                                    {
                                        isLoadingFetchProduct ? 
                                            <Skeleton count={10} height={118} />
                                        :
                                            productList.map((product, indexProduct) =>{
                                                return (
                                                    <>
                                                        {
                                                            product.show && (
                                                                <ProductList 
                                                                    key={product._id}
                                                                    product={product}
                                                                    productList={productList}
                                                                    setProductList={setProductList}
                                                                    handleEditProduct={handleEditProduct}
                                                                    role={role}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            isShowModalAddProduct && (
                                <AddProduct 
                                    isShowModalAddProduct={isShowModalAddProduct} 
                                    setIsShowModalAddProduct={setIsShowModalAddProduct} 
                                    productList={productList} 
                                    mainProductList={mainProductList} 
                                    setMainProductList={setMainProductList} 
                                    setProductList={setProductList}
                                    isEditProduct={isEditProduct}
                                    setIsEditProduct={setIsEditProduct}/>
                            )
                        }
                    </div>
                
            }
        </>
    )
}

export default Product