import React, {useState, useEffect} from 'react';
import './index.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import Marketplace from './marketplace'
import Variant from './variant'
import Modal  from 'react-modal';
import Select from 'react-select';
import {
    useHistory,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Popup from '../../components/Notification/Popup';

const customStylesModalUserDetail = {
    content : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        minWidth: '400px',
        marginRight : '-50%',
        transform : 'translate(-50%, -50%)',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(246 246 246)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        zIndex: 2,
    }
};

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        marginTop: '5px'
    }),
    menu: (provided, state) => ({
        ...provided,
        bottom: '100%',
        top: 'auto'
    })
}

function Information() {

    const history = useHistory()
    const role = useSelector(state => state.role);
    const [inputFormData, setInputFormData] = useState({})
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [newMarketplaceData, setNewMarketplaceData] = useState(null)
    const [newVariantData, setNewVariantData] = useState(null)
    const [updateMarketplaceData, setUpdateMarketplaceData] = useState(null)
    const [updateVariantData, setUpdateVariantData] = useState(null)
    const [editMarketplaceData, setEditMarketplaceData] = useState(null)
    const [editVariantData, setEditVariantData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [optionsMarketplace, setOptionsMarketplace] = useState([
        {
            label: 'marketplace',
            value: 'marketplace'
        },
        {
            label: 'reseller',
            value: 'reseller'
        },
        {
            label: 'dropshipper',
            value: 'dropshipper'
        }
    ])
    const [optionsVariant, setOptionsVariant] = useState([
        {
            label: 'category',
            value: 'categoryBasic'
        },
        {
            label: 'color',
            value: 'colorBasic'
        },
        {
            label: 'size',
            value: 'sizeBasic'
        }
    ])

    useEffect(()=> {
        if(role !== 'admin' && role !== 'super admin') {
            history.push('/product')
            return;
        }
        if(editMarketplaceData){
            setIsOpenModal('marketplace')
            setInputFormData(editMarketplaceData)
        }else if(editVariantData){
            setIsOpenModal('variant')
            setInputFormData({
                name: editVariantData.data.label,
                tag: {label: editVariantData.data.tag, value: `${editVariantData.data.tag}Basic`},
                skuVariant: editVariantData.data.skuVariant
            })
        }
    },[editMarketplaceData, editVariantData])

    function handleCloseModal() {
        setInputFormData({})
        setIsOpenModal(false)
        setEditMarketplaceData(null)
        setEditVariantData(null)
    }

    function handleSubmit() {
        setLoading(true)
        if(isOpenModal == 'marketplace'){
            if(inputFormData.name && inputFormData.name[0] && inputFormData.status && inputFormData.status[0]) {
                    editMarketplaceData ? 
                        axios.put(`/marketplace/${inputFormData._id}`, {
                            name: inputFormData.name,
                            status: inputFormData.status,
                            information: inputFormData.information
                        })
                        .then(({data}) => {
                            setLoading(false)
                            setUpdateMarketplaceData(data)
                            handleCloseModal()
                        })
                        .catch((err) => {
                            setLoading(false)
                            toast.error('Failed')})
                    :
                        axios.post('/marketplace', {
                            name: inputFormData.name,
                            status: inputFormData.status,
                            information: inputFormData.information
                        })
                        .then(({data}) => {
                            setLoading(false)
                            setNewMarketplaceData(data)
                            handleCloseModal()
                        })
                        .catch((err) => {
                            setLoading(false)
                            toast.error('Failed')})
            }else {
                setLoading(false)
                toast.error('Fill all required fields')
            }
        }else if (isOpenModal == 'variant'){
            if(inputFormData.name && inputFormData.name[0] && inputFormData.tag && inputFormData.tag.label) {
                editVariantData ? 
                    axios.put(`/${editVariantData.data.tag}Basic/${editVariantData.data._id}`, {
                        value: inputFormData.name,
                        label: inputFormData.name,
                        skuVariant: inputFormData.skuVariant && inputFormData.skuVariant
                    })
                    .then(({data}) => {
                        setLoading(false)
                        setUpdateVariantData({data, type: editVariantData.data.tag})
                        handleCloseModal()
                    })
                    .catch((err) => {
                        setLoading(false)
                        toast.error('Failed')})
                :
                    axios.post(`/${inputFormData.tag.value}`, {
                        value: inputFormData.name,
                        label: inputFormData.name,
                        skuVariant: inputFormData.skuVariant && inputFormData.skuVariant
                    })
                    .then(({data}) => {
                        if(inputFormData.tag.value === 'categoryBasic'){
                            setNewVariantData({
                                data,
                                type: 'category'
                            })
                        }else if(inputFormData.tag.value === 'colorBasic'){
                            setNewVariantData({
                                data,
                                type: 'color'
                            })
                        }else if(inputFormData.tag.value === 'sizeBasic'){
                            setNewVariantData({
                                data,
                                type: 'size'
                            })
                        }
                        setLoading(false)
                        handleCloseModal()
                    })
                    .catch((err) => {
                        setLoading(false)
                        toast.error('Failed')})
            }else {
                setLoading(false)
                toast.error('Fill all required fields')
            }
        }
    }

    return(
        <div>
            <div className="header header-information" />
            <div className="body-information">
                <Marketplace 
                    updateMarketplaceData={updateMarketplaceData} 
                    setUpdateMarketplaceData={setUpdateMarketplaceData} 
                    setEditMarketplaceData={setEditMarketplaceData} 
                    newMarketplaceData={newMarketplaceData} 
                    setNewMarketplaceData={setNewMarketplaceData} 
                    setIsOpenModal={setIsOpenModal}
                />
                <Variant 
                    newVariantData={newVariantData} 
                    setNewVariantData={setNewVariantData}
                    setIsOpenModal={setIsOpenModal}
                    updateVariantData={updateVariantData}
                    setUpdateVariantData={setUpdateVariantData}
                    setEditVariantData={setEditVariantData}
                />
            </div>
            <Modal
                isOpen={isOpenModal}
                onRequestClose={handleCloseModal}
                style={customStylesModalUserDetail}
                contentLabel="Modal User Detail"
                id="add-information-modal"
            >
                <h3>{isOpenModal == 'marketplace' ? 'Add new marketplace / reseller / dropshipper' : 'Add new category / color / size' }</h3>
                <hr/>
                <div>
                    <label className="d-block mb-5" htmlFor="input-information-name">Name *</label>
                    <input 
                        autoComplete="new" 
                        type="text" 
                        id="input-information-name" 
                        placeholder="" 
                        name="name"
                        className="d-block mb-10 input-name-marketplace"
                        value={inputFormData.name} 
                        onChange={(e) => setInputFormData({...inputFormData, name: e.target.value})} 
                    />
                    {
                        isOpenModal == 'marketplace' ? 
                            <>
                                <label className="d-block">Status *</label>
                                <Select
                                    options={optionsMarketplace}
                                    value={inputFormData.status && {label: inputFormData.status, value: inputFormData.status}} 
                                    styles={customStyles}
                                    onChange={(value) => setInputFormData({...inputFormData, status: value.value})}
                                />
                            </>
                        :
                            <>
                                <label className="d-block" htmlFor="input-information-name">Tag *</label>
                                <Select
                                    options={optionsVariant}
                                    value={inputFormData.tag} 
                                    isDisabled={editVariantData}
                                    styles={customStyles}
                                    onChange={(value) => setInputFormData({...inputFormData, tag: value})}
                                />
                            </>
                    }
                    {
                        isOpenModal == 'marketplace' ?
                            <>
                                <label className="d-block mt-10 mb-5" htmlFor="input-information-name">Information (optional)</label>
                                <input 
                                    value={inputFormData.information} 
                                    className="d-block input-information-marketplace"
                                    onChange={(e) => setInputFormData({...inputFormData, information: e.target.value})} 
                                />
                            </>
                        :
                            <>
                                <label htmlFor="input-information-skuvariant" className="d-block mt-10 mb-5">SKU variant (optional)</label>
                                <input 
                                    autoComplete="new" 
                                    type="text" 
                                    id="input-information-skuvariant" 
                                    placeholder="will generate automatically" 
                                    name="name"
                                    className="d-block mb-10 input-name-marketplace"
                                    value={inputFormData.skuVariant} 
                                    onChange={(e) => setInputFormData({...inputFormData, skuVariant: e.target.value})} 
                                />
                            </>
                    }
                    <div style={{margin: '20px 0', display: 'flex', justifyContent: 'center'}}>
                        <button onClick={handleSubmit} className={`button button-primary button-submit-add-product ${loading && 'button-primary-disabled'}`} disabled={loading}>{editMarketplaceData || editVariantData ? 'Update' : 'Submit'}</button>
                        <button onClick={handleCloseModal} className="button button-cancel">Cancel</button>
                    </div>
                </div>
                {loading && <Popup/>}
            </Modal>
        </div>
    )
}

export default Information;