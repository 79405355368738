export function setAuth (auth) {
    return (dispatch, state) => {
        dispatch({
            type: 'SET_AUTH',
            data: auth
        })
    }
}
export function setDataUser (user) {
    return (dispatch, state) => {
        dispatch({
            type: 'SET_DATA_USER',
            data: user
        })
    }
}
