import React, {useState, useEffect} from 'react'
import Modal  from 'react-modal';
import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import Popup from '../../components/Notification/Popup';

const customStylesModalUserDetail = {
    content : {
        position: 'absolute',
        top: '20px',
        left: '100px',
        right: '100px',
        bottom: '20px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255 255 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        zIndex: 2,
    }
};

const customStylesSelectInput1 = {
    control: (provided) => ({
        ...provided,
        height: '30px',
    }),
    container: (provided) => ({
        ...provided,
        width: '300px'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px'
    }),
    menu: (provided) => ({
        ...provided,
        bottom: '100%',
        top: undefined
    }),
}

const customStylesSelectInput2 = {
    control: (provided) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '50px',
        overflowY: 'auto',
    }),
    container: (provided) => ({
        ...provided,
        width: '200px'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px'
    }),
    menu: (provided) => ({
        ...provided,
        bottom: '100%',
        top: undefined
    })
}

const customStylesSelectInput3 = {
    control: (provided) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '50px',
        overflowY: 'auto',
    }),
    container: (provided) => ({
        ...provided,
        width: '400px'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px'
    }),
    menu: (provided) => ({
        ...provided,
        bottom: '100%',
        top: undefined
    })
}

function AddProduct({mainProductList, setMainProductList, setIsShowModalAddProduct, isShowModalAddProduct, productList, setProductList, isEditProduct, setIsEditProduct}) {

    {/**
        ----------STATE----------
    */}
    const name = useSelector(state => state.name);

    const [inputFormData, setInputFormData] = useState({
        name: '',
        price: 0,
        stock: 0,
        reject: 0,
        sold: 0,
        discount: 0,
        category: '',
        color: '',
        size: '',
        skuCategory: '',
        show: true,
        image: '',
        tag:[]
    })

    const [colorOptions, setColorOptions] = useState([])
    const [sizeOptions, setSizeOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([{label: 'NEW ARIVALS', value: 'newest'}, {label: 'BEST SELLER', value: 'best seller'}, {label: 'SALE', value: 'sale'}])
    const [previewImage, setPreviewImage] = useState([])
    const [dataImage, setdataImage] = useState([])
    const [loadingSubmitProduct, setLoadingSubmitProduct] = useState(false)
    const [loadingUpdateProduct, setLoadingUpdateProduct] = useState(false)
    const [tagValue, setTagValue] = useState([])
    const [imageDeleted, setImageDeleted] = useState([])
    const [isEditImage, setEditImage] = useState(null)
    
    {/**
        ----------USEEFFECT----------
    */}
    useEffect(()=>{
        if(isEditProduct.check){
            setInputFormData({
                name: isEditProduct.data.name,
                description: isEditProduct.data.description,
                price: isEditProduct.data.price,
                stock: isEditProduct.data.stock,
                reject: isEditProduct.data.reject,
                sold: isEditProduct.data.sold,
                discount: isEditProduct.data.discount,
                category: isEditProduct.data.category,
                color: isEditProduct.data.color,
                size: isEditProduct.data.size,
                skuCategory: isEditProduct.data.skuCategory,
                show: true,
                image: isEditProduct.data.image,
                tag: isEditProduct.data.tag,
                weight: isEditProduct.data.weight,
            })
            let previewGallery = isEditProduct.data.gallery ? isEditProduct.data.gallery.map(el => `https://apiv1.heylook-dashboard.xyz/images/${el}`) : []
            setPreviewImage([`https://apiv1.heylook-dashboard.xyz/images/${isEditProduct.data.image}`].concat(previewGallery))
            // let previewGallery = isEditProduct.data.gallery ? isEditProduct.data.gallery.map(el => `http://localhost:3000/images/${el}`) : []
            // setPreviewImage([`http://localhost:3000/images/${isEditProduct.data.image}`].concat(previewGallery))
            setdataImage([isEditProduct.data.image].concat(isEditProduct.data.gallery))
            generateTagValue(isEditProduct.data.tag)
        }
        axios.get('/colorBasic')
            .then(({data}) => setColorOptions(data))
            .catch(err => toast.error('load color failed'))
        axios.get('/categoryBasic')
            .then(({data}) => setCategoryOptions(data))
            .catch(err => toast.error('load category failed'))
        axios.get('/sizeBasic')
            .then(({data}) => setSizeOptions(data))
            .catch(err => toast.error('load size failed'))
    },[])

    {/**
        ----------FUNCTIONALITY----------
    */}

    const handleChangeCategory = (newValue, actionMeta) => {
        if(actionMeta.action == 'create-option') {
            axios.post('/categoryBasic', { 
                value: newValue.value,
                label: newValue.label,
            })
            .then(({data})=>{
                setInputFormData({
                    ...inputFormData,
                    category: data,
                    // skuCategory: data.skuVariant,
                })
                setCategoryOptions([...categoryOptions, data])
            })
            .catch((err)=>toast.error('create new category failed'))
        }else {
            setInputFormData({
                ...inputFormData,
                category: newValue,
                // skuCategory: newValue.skuVariant ? newValue.skuVariant : newValue.value.slice(0,3),
            })
        }
        
    };

    const handleChangeSize = async (newValue, actionMeta) => {
        let newSize;
        if(actionMeta.action == 'create-option') {
            await axios.post('/sizeBasic', { 
                value: isEditProduct.check ? newValue.value : newValue[newValue.length - 1].value,
                label: isEditProduct.check ? newValue.label : newValue[newValue.length - 1].label,
                // skuVariant: sizeOptions[0] ? Number(sizeOptions[sizeOptions.length-1].skuVariant) + 1 : 0
            })
            .then(({data})=>{
                if(!isEditProduct.check) {
                    setInputFormData({
                        ...inputFormData,
                        size: [...inputFormData.size, data],
                    })
                }else {
                    setInputFormData({
                        ...inputFormData,
                        size: data,
                    })
                }
            })
            .catch((err)=>toast.error('create new size failed'))
            if(isEditProduct.check) {
                setSizeOptions([...sizeOptions, newValue])
            }else {
                setSizeOptions([...sizeOptions, newValue[newValue.length - 1]])
            }
        }else {
            if(!isEditProduct.check) {
                {/**
                    conditional for add product
                */}
                newSize = []
                if(newValue) {
                    newValue.map(value => {
                        newSize.push(value)
                    })
                }
                setInputFormData({
                    ...inputFormData,
                    size: newSize,
                })
            }else {
                {/**
                    conditional for update product
                */}
                setInputFormData({
                    ...inputFormData,
                    size: newValue,
                })
            }
        }
        
        // if(actionMeta.action == 'remove-value') {
        //     if(newValue){
        //         setSizeOptions([...sizeOptions, actionMeta.removedValue])
        //     }
        // }
        if(actionMeta.action == 'clear') {
            setSizeOptions([...sizeOptions])
        }
    };

    const handleChangeColor = async(newValue, actionMeta) => {
        let newColor;
        if(actionMeta.action == 'create-option') {
            await axios.post('/colorBasic', { 
                value: isEditProduct.check ? newValue.value : newValue[newValue.length - 1].value,
                label: isEditProduct.check ? newValue.label : newValue[newValue.length - 1].label,
                // skuVariant: newValue.value.slice(0,3)
            })
            .then(({data})=>{
                if(!isEditProduct.check) {
                    setInputFormData({
                        ...inputFormData,
                        color: [...inputFormData.color, data],
                    })
                }else {
                    setInputFormData({
                        ...inputFormData,
                        color: data,
                    })
                }
            })
            .catch((err)=>toast.error('create new color failed'))
            if(isEditProduct.check) {
                setColorOptions([...colorOptions, newValue])
            }else {
                setColorOptions([...colorOptions, newValue[newValue.length - 1]])
            }
        }else {
            if(!isEditProduct.check) {
                {/**
                    conditional for add product
                */}
                newColor = []
                if(newValue) {
                    newValue.map(value => {
                        newColor.push(value)
                    })
                }
                setInputFormData({
                    ...inputFormData,
                    color: newColor,
                })
            }else {
                {/**
                    conditional for update product
                */}
                // let splitValue = newValue.value.split(' ')
                // newColor = {
                //     name:newValue.value.toUpperCase(),
                //     skuVariant: newValue.skuVariant ? newValue.skuVariant : splitValue[1] != undefined ? `${splitValue[0][0]}${splitValue[1][0]}` : newValue.value.slice(0,3)
                // }
                setInputFormData({
                    ...inputFormData,
                    color: newValue,
                })
            }
        }
        if(actionMeta.action == 'clear') {
            setColorOptions([...colorOptions])
        }
    };
    const handleChangeTag = (newValue) => {
        let newTag = []
        if(newValue) {
            newValue.map(value => {
                newTag.push(value.value)
            })
        }
        setInputFormData({
            ...inputFormData,
            tag: newTag,
        })
        if(isEditProduct.check){
            setTagValue(newValue)
        }
    }
    function handleSubmit() {
        if(isEditProduct.check) {
            setLoadingUpdateProduct(true)
            postUpdateProduct()
        }else {
            setLoadingSubmitProduct(true)
            if(inputFormData.color.length > 1 || inputFormData.size.length > 1) {
                if(inputFormData.color.length > 1) {
                    inputFormData.color.map(color => {
                        if(inputFormData.size.length > 1) {
                            inputFormData.size.map(size => {
                                postAddProduct(color, size)
                            })
                        }else {
                            postAddProduct(color, inputFormData.size[0])
                        }
                    })
                }else if(inputFormData.size.length > 1) {
                    inputFormData.size.map(size => {
                        if(inputFormData.color.length > 1) {
                            inputFormData.color.map(color => {
                                postAddProduct(color, size)
                            })
                        }else {
                            postAddProduct(inputFormData.color[0], size)
                        }
                    })
                }
            }else {
                postAddProduct(inputFormData.color[0] ? inputFormData.color[0] : inputFormData.color, inputFormData.size[0] ? inputFormData.size[0]: inputFormData.size)
            }
        }
    }
    async function postAddProduct(color, size) {
        if(inputFormData.name == '' || inputFormData.color == '' ||inputFormData.category == '' || inputFormData.price == 0 || inputFormData.size == '' || inputFormData.stock == 0){
            if(inputFormData.name == '') {
                toast.error('Please fill name field')
            }else if(inputFormData.color == ''){
                toast.error('Please fill color field')
            }else if(inputFormData.category == ''){
                toast.error('Please fill category field')
            }else if(inputFormData.price == ''){
                toast.error('Please fill price field')
            }else if(inputFormData.size == ''){
                toast.error('Please fill size field')
            }else if(inputFormData.stock == ''){
                toast.error('Please fill stock field')
            }
            setLoadingSubmitProduct(false)
            return;
        }
        let dataUpload = []
        if(dataImage.length !== 0) {
            for (const image of dataImage){
                let temp = await axios.post('/upload', image, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(({data}) => {
                    return data
                })
                .catch(err => {
                    toast.error('failed upload image')})
                dataUpload.push(temp)
            }
        }
        let tempGallery = dataUpload.slice(1).map(el => el.filename)
        axios.post('/product', {
            ...inputFormData,
            color,
            size,
            username: name,
            image: dataUpload.length > 0 ? dataUpload[0].filename : '',
            gallery: tempGallery.length > 0 ? tempGallery : []
        })
        .then(({data}) => {
            setProductList(prevState => (
                    [{
                        ...data, 
                        category: inputFormData.category,
                        color,
                        size,
                    }, ...prevState ]
                )
            )
            setMainProductList(prevState => (
                    [{
                        ...data, 
                        category: inputFormData.category,
                        color,
                        size,
                    }, ...prevState ]
                )
            )
            setLoadingSubmitProduct(false)
            handleCloseModal()
        })
        .catch(err => {
            setLoadingSubmitProduct(false)
            handleCloseModal()
            toast.error('create new product failed')})
    }
    
    async function postUpdateProduct(){
        if(inputFormData.name == '' || inputFormData.color == '' ||inputFormData.category == '' || inputFormData.price == 0 || inputFormData.size == '' || inputFormData.stock == 0){
            if(inputFormData.name == '') {
                toast.error('Please fill name field')
            }else if(inputFormData.color == ''){
                toast.error('Please fill color field')
            }else if(inputFormData.category == ''){
                toast.error('Please fill category field')
            }else if(inputFormData.price == ''){
                toast.error('Please fill price field')
            }else if(inputFormData.size == ''){
                toast.error('Please fill size field')
            }else if(inputFormData.stock == ''){
                toast.error('Please fill stock field')
            }
            setLoadingUpdateProduct(false)
            return;
        }

        let dataUpload = []

        for (const image of dataImage){
            if(image instanceof FormData){
                let temp = await axios.post('/upload', image, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(({data}) => {
                    return data
                })
                .catch(err => {
                    toast.error('failed upload image')})
                    dataUpload.push(temp)
            }else{
                dataUpload.push(image)
            }
        }
        if(imageDeleted.length > 0){
            for(const image of imageDeleted){
                await axios.post('/deleteImage', {
                    image: image
                })
                .then(()=>{
    
                })
                .catch((err)=>{
                    
                })
            }
        }
         let tempGallery = dataUpload.map(el => el.filename ? el.filename : el)
        axios.put(`/product/${isEditProduct.data._id}`, {
            ...inputFormData,
            image: tempGallery[0],
            gallery: tempGallery.length > 1 ? tempGallery.slice(1) : [],
        })
        .then(({data}) => {
            let newProductList = productList.map(product => {
                if(product._id == isEditProduct.data._id) {
                    return {
                        ...data,
                        color: inputFormData.color ,
                        size: inputFormData.size ,
                        image: tempGallery[0],
                        gallery: tempGallery.length > 1 ? tempGallery.slice(1) : []
                        }
                }else {
                    return product
                }
            })
            setProductList(newProductList)
            setLoadingUpdateProduct(false)
            handleCloseModal()
        })
        .catch(err => {
            setLoadingUpdateProduct(false)
            toast.error('update product failed')
            handleCloseModal()
        })
        
    }

    function handleCloseModal() {
        setIsShowModalAddProduct(false)
        setIsEditProduct({check: false, data: null})
    }

    const handleClickUpload = ()=> {
        document.getElementById('input-file').click()
    }

    const handleUploadAttachment = (value)=>{
        if(!value) {
            return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(value) //should use it to show preview image
        let data = new FormData()
        data.append('file', value)
        if(isEditImage !== null){
            let newPreview = [...previewImage]
            let newData = [...dataImage]
            newData.splice(isEditImage, 1, data)
            reader.onloadend = function (e) {
                newPreview.splice(isEditImage, 1, [reader.result])
               setPreviewImage(newPreview)
            }
            setdataImage(newData)
        }else {
            reader.onloadend = function (e) {
               setPreviewImage(previewImage.concat([reader.result]))
            }
            setdataImage(dataImage.concat(data))
        }
        // axios.post('/upload', data, {
        //     headers: {
        //         "Content-Type": "multipart/form-data"
        //     }
        // })
        // .then(({data}) => {
        //     return data.message
        // })
        // .catch(err => console.log(err))
        {/**
            #Bilal - don't delete
            
            *call function get base 64 with callback function*

            let finalResult = null
            getBase64(e.target.files[0], (result) =>{
                finalResult = result
                console.log(result,'result base 64')
            })
        */}
    }
    
    {/**
        ----------RENDER COMPONENT----------
    */}

    function generateTagValue(tags) {
        if(tags){
            let newTags = []
            tags.map(tag => {
                let obj = {
                    label: tag === 'newest' ? 'NEW ARRIVALS' : tag === 'best seller' ? 'BEST SELLER' : 'SALE',
                    value: tag,
                }
                newTags.push(obj)
            }) 
            setTagValue(newTags)
        }
    }

    function handleDeleteImage(index) {
        let newPreview = [...previewImage]
        newPreview.splice(index, 1)
        let newData = [...dataImage]
        newData.splice(index, 1)
        setPreviewImage(newPreview)
        setdataImage(newData)
        if(isEditProduct.check){
            if((dataImage[index] instanceof FormData) == false){
                setImageDeleted(imageDeleted.concat(dataImage[index]))
            }
        }
    }

    function handleEditImage(index) {
        setEditImage(index)
        handleClickUpload()
    }

    return (
        <Modal
            isOpen={isShowModalAddProduct}
            onRequestClose={handleCloseModal}
            style={customStylesModalUserDetail}
            contentLabel="Modal User Detail"
            id="add-product-modal"
        >
            {loadingSubmitProduct && <Popup text={'Add Product'}/>}
            {loadingUpdateProduct && <Popup text={'Update Product'}/>}
            <div className="form form-add-product">
                <h2 className="">Add Product</h2>
                <div className="wrapper-product-information">
                    <h4>Product Information</h4>
                    <input 
                        onChange={(e)=> {
                            e.persist()
                            handleUploadAttachment(e.target.files[0])
                        }} 
                        id="input-file" 
                        type="file" 
                        style={{display: 'none'}}
                        onClick={(e) => e.target.value = null}
                    />
                    <label onClick={handleClickUpload} className="cursor-pointer">+Add image</label>
                    {
                        previewImage.length === 0 ? (
                            <div className="box-upload-image">
                                <i class="fa fa-image" style={{fontSize: '50px', color: '#a2a2a2', height: '50px', width:'50px', cursor: 'default'}}></i>
                            </div>
                        ) : (
                            <div className="d-flex flex-wrap">
                                {
                                    previewImage.map((preview, index) => {
                                        return (
                                            <div key={index} className="box-upload-image">
                                                <i class="fa fa-trash" onClick={()=> handleDeleteImage(index)}/>
                                                <i class="fa fa-edit" onClick={()=> handleEditImage(index)}/>
                                                <img style={{height: '100%', width: '100%'}} src={preview}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    <label htmlFor="input-product-name">Name *</label>
                    <input value={inputFormData.name} onChange={(e) => setInputFormData({...inputFormData, name: e.target.value})} autoComplete="new" type="text" id="input-product-name" placeholder="" name="name"/>
                    <label htmlFor="input-product-description">Description</label>
                    <textarea 
                        value={inputFormData.description} 
                        onChange={(e) =>{ 
                            e.target.style.height = 'inherit'
                            e.target.style.height = (e.target.scrollHeight)+'px'
                            setInputFormData({...inputFormData, description: e.target.value})}
                        } 
                        autoComplete="new" type="text" id="input-product-description" placeholder="description" name="description"/>
                    <br/>
                    <label htmlFor="input-product-tag">Tag</label>
                    {
                        isEditProduct.check ? 
                            <CreatableSelect
                                isMulti
                                className={'creatable-input-add-product'}
                                onChange={handleChangeTag}
                                styles={customStylesSelectInput3}
                                options={tagOptions}
                                value={tagValue}
                            />
                        :
                            <CreatableSelect
                                isMulti
                                className={'creatable-input-add-product'}
                                onChange={handleChangeTag}
                                styles={customStylesSelectInput3}
                                options={tagOptions}
                            />
                    }

                </div>
                <div className="wrapper-sales-information">
                    <h4>Sales Information</h4>
                    <div className="d-flex section-input-variant">
                        <div className="wrapper-input-variant">
                            <label htmlFor="input-product-category">Category *</label>
                                {
                                    isEditProduct.check ? 
                                        <CreatableSelect
                                            className={'creatable-input-add-product'}
                                            onChange={handleChangeCategory}
                                            styles={customStylesSelectInput1}
                                            options={categoryOptions}
                                            value={{label:inputFormData.category.label, value:inputFormData.category.value}}
                                        />
                                    :
                                        <CreatableSelect
                                            className={'creatable-input-add-product'}
                                            onChange={handleChangeCategory}
                                            styles={customStylesSelectInput1}
                                            options={categoryOptions}
                                        />
                                }
                        </div>
                        <div className="wrapper-input-variant">
                            <label htmlFor="input-product-color">Color *</label>
                            {
                                isEditProduct.check ? 
                                    <CreatableSelect
                                        isMulti={!isEditProduct.check}
                                        onChange={handleChangeColor}
                                        styles={customStylesSelectInput2}
                                        options={colorOptions}
                                        className={'creatable-input-add-product'}
                                        value={{label: inputFormData.color.label, value: inputFormData.color.value}}
                                        />
                                        :
                                    <CreatableSelect
                                        isMulti={!isEditProduct.check}
                                        onChange={handleChangeColor}
                                        className={'creatable-input-add-product'}
                                        styles={customStylesSelectInput2}
                                        options={colorOptions}
                                        />
                            }
                        </div>
                        <div className="wrapper-input-variant">
                            <label htmlFor="input-product-size">Size *</label>
                            {
                                isEditProduct.check ? 
                                    <CreatableSelect
                                    isMulti={!isEditProduct.check}
                                        onChange={handleChangeSize}
                                        className={'creatable-input-add-product'}
                                        styles={customStylesSelectInput2}
                                        options={sizeOptions}
                                        value={{label: inputFormData.size.label, value: inputFormData.color.value}}
                                    />
                                :
                                    <CreatableSelect
                                        isMulti={!isEditProduct.check}
                                        onChange={handleChangeSize}
                                        className={'creatable-input-add-product'}
                                        styles={customStylesSelectInput2}
                                        options={sizeOptions}
                                    />
                            }
                        </div>
                    </div>
                    <div className="d-flex section-input-variant flex-wrap">
                        <div className="wrapper-input-sale">
                            <label htmlFor="input-product-price">Price *</label>
                            <input value={inputFormData.price === 0 ? '' : inputFormData.price} onChange={(e) => setInputFormData({...inputFormData, price: e.target.value})} autoComplete="new" type="number" id="input-product-price" placeholder="" name="price"/>
                        </div>
                        <div className="wrapper-input-sale">
                            <label htmlFor="input-product-stock">Stock *</label>
                            <input value={inputFormData.stock === 0 ? '' : inputFormData.stock} onChange={(e) => setInputFormData({...inputFormData, stock: e.target.value})} autoComplete="new" type="number" id="input-product-stock" placeholder="" name="stock"/>
                        </div>
                        <div className="wrapper-input-sale">
                            <label htmlFor="input-product-sold">Sold</label>
                            <input disabled={isEditProduct.check} value={inputFormData.sold === 0 ? '' : inputFormData.sold} onChange={(e) => setInputFormData({...inputFormData, sold: e.target.value})} autoComplete="new" type="number" id="input-product-sold" placeholder="sold" name="sold"/>
                        </div>
                        <div className="wrapper-input-sale">
                            <label htmlFor="input-product-reject">Reject</label>
                            <input disabled={isEditProduct.check} value={inputFormData.reject === 0 ? '' : inputFormData.reject} onChange={(e) => setInputFormData({...inputFormData, reject: e.target.value})} autoComplete="new" type="number" id="input-product-reject" placeholder="reject" name="reject"/>
                        </div>
                        <div className="wrapper-input-sale">
                            <label htmlFor="input-product-discount">
                                Price After Discount
                            </label>
                            <input value={inputFormData.discount === 0 ? '' : inputFormData.discount} onChange={(e) => setInputFormData({...inputFormData, discount: e.target.value})} autoComplete="new" type="number" id="input-product-discount" placeholder="10000" name="discount"/>
                        </div>
                        <div className="wrapper-input-sale">
                            <label htmlFor="input-product-input-product-weight">
                                Weight (gr)
                            </label>
                            <input value={inputFormData.weight === 0 ? '' : inputFormData.weight} onChange={(e) => setInputFormData({...inputFormData, weight: e.target.value})} autoComplete="new" type="number" id="input-product-weight" placeholder="100gr" name="weight"/>
                        </div>
                    </div>
                </div>
                <div style={{margin: '20px 0', display: 'flex'}}>
                    <button disabled={loadingSubmitProduct || loadingUpdateProduct} onClick={handleSubmit} className={`button button-primary button-submit-add-product ${(loadingSubmitProduct || loadingUpdateProduct) && 'button-primary-disabled'}`}>{isEditProduct.check ? 'Update' : 'Submit' }</button>
                    <button onClick={handleCloseModal} className="button button-cancel">Cancel</button>
                </div>
            </div>
        </Modal>
    )
}

export default AddProduct;